<fieldset class="fieldset">
    <legend class="fieldset-legend">{{title}}</legend>

    <div class="form-group"
         [ngClass]="{'has-error': (form.submitted || firstName.touched) && !firstName.valid}">
        <label for="firstName{{componentId}}">First Name<span class="text-danger">*</span></label>
        <input id="firstName{{componentId}}" type="text" class="form-control" name="firstName"
               placeholder="Enter a first name" [(ngModel)]="userObj.firstName"
               #firstName="ngModel" required/>
        <div *ngIf="(form.submitted || firstName.touched) && !firstName.valid">
            <small class="text-danger" *ngIf="firstName.errors['required']">
                First name is required.
            </small>
        </div>
    </div>
    <div class="form-group"
         [ngClass]="{'has-error': (form.submitted || lastName.touched) && !lastName.valid}">
        <label for="lastName{{componentId}}">Last Name<span class="text-danger">*</span></label>
        <input id="lastName{{componentId}}" type="text" class="form-control" name="lastName"
               placeholder="Enter a last name" [(ngModel)]="userObj.lastName"
               #lastName="ngModel" required/>
        <div *ngIf="(form.submitted || lastName.touched) && !lastName.valid">
            <small class="text-danger" *ngIf="lastName.errors['required']">
                Last name is required.
            </small>
        </div>
    </div>
    <div class="form-group"
         [ngClass]="{'has-error': (form.submitted || email.touched) && !email.valid}">
        <label for="email{{componentId}}">Email<span class="text-danger">*</span></label>
        <input id="email{{componentId}}" type="email" class="form-control" name="email"
               placeholder="Enter a email" [(ngModel)]="userObj.email"
               [pattern]="emailRegEx" #email="ngModel"
               required/>
        <div *ngIf="(form.submitted || email.touched) && !email.valid">
            <small class="text-danger" *ngIf="email.errors['required']">
                Email is required.
            </small>
            <small class="text-danger" *ngIf="email.errors['pattern']">
                Email is invalid.
            </small>
        </div>
    </div>
    <div class="form-group"
         [ngClass]="{'has-error': (form.submitted || phone.touched) && !phone.valid}">
        <label for="phone{{componentId}}">Phone<span class="text-danger">*</span></label>
        <input id="phone{{componentId}}" type="text" class="form-control" name="phone"
               placeholder="Enter a phone" [(ngModel)]="userObj.phone"
               #phone="ngModel" required/>
        <div *ngIf="(form.submitted || phone.touched) && !phone.valid">
            <small class="text-danger" *ngIf="phone.errors['required']">
                Phone is required.
            </small>
        </div>
    </div>
    <div class="form-group"
         [ngClass]="{'has-error': (form.submitted || city.touched) && !city.valid}">
        <label for="city{{componentId}}">City<span class="text-danger">*</span></label>
        <input id="city{{componentId}}" type="text" class="form-control" name="city"
               placeholder="Enter a city" [(ngModel)]="userObj.city"
               #city="ngModel" required/>
        <div *ngIf="(form.submitted || city.touched) && !city.valid">
            <small class="text-danger" *ngIf="city.errors['required']">
                City is required.
            </small>
        </div>
    </div>
    <div class="form-group"
         [ngClass]="{'has-error': (form.submitted || state.touched) && !state.valid}">
        <label for="state{{componentId}}">State<span class="text-danger">*</span></label>
        <input id="state{{componentId}}" type="text" class="form-control" name="state"
               placeholder="Enter a state" [(ngModel)]="userObj.state"
               #state="ngModel" required/>
        <div *ngIf="(form.submitted || state.touched) && !state.valid">
            <small class="text-danger" *ngIf="state.errors['required']">
                State is required.
            </small>
        </div>
    </div>
    <div class="form-group"
         [ngClass]="{'has-error': (form.submitted || country.touched) && !country.valid}">
        <label for="country{{componentId}}">Country<span class="text-danger">*</span></label>
        <input id="country{{componentId}}" type="text" class="form-control" name="country"
               placeholder="Enter a country" [(ngModel)]="userObj.country"
               #country="ngModel" required/>
        <div *ngIf="(form.submitted || country.touched) && !country.valid">
            <small class="text-danger" *ngIf="country.errors['required']">
                Country is required.
            </small>
        </div>
    </div>
    <div class="form-group"
         [ngClass]="{'has-error': (form.submitted || postalCode.touched) && !postalCode.valid}">
        <label for="postalCode{{componentId}}">Postal Code<span class="text-danger">*</span></label>
        <input id="postalCode{{componentId}}" type="text" class="form-control" name="postalCode"
               placeholder="Enter a postal code" [(ngModel)]="userObj.postalCode"
               #postalCode="ngModel" required/>
        <div *ngIf="(form.submitted || postalCode.touched) && !postalCode.valid">
            <small class="text-danger" *ngIf="postalCode.errors['required']">
                Postal code is required.
            </small>
        </div>
    </div>
</fieldset>
