import {Component, OnInit} from '@angular/core';
import {AnalyticsService} from './analytics.service';
import {ExportService} from "../../common/export.service";
import {FormatMillisecondPipe} from "./format-millisecond.pipe";
import {Observable, mergeMap} from 'rxjs';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead';
import * as _ from 'lodash';
import {UsersService} from "../users/users.service";
import {LockersService} from "../lockers/lockers.service";
import {Locker} from "../lockers/locker";

@Component({
    selector: 'pp-analytics',
    templateUrl: './analytics.component.html',
    styleUrls: ['./analytics.component.css']
})
export class AnalyticsComponent implements OnInit {

    public isDataLoaded;
    public isDataLoadedForDaysAnalytics: boolean;
    public isDataLoadedForUserCounts: boolean;
    public isDataLoadedForNotificationCounts: boolean;
    public isExporting: boolean;
    public currentTab: string;
    public lockersArr: Array<any> = [];
    public packageArr: Array<any> = [];
    public userArr: Array<any> = [];
    public daysArr: Array<any> = [];
    public notificationCountsArr: Array<any> = [];
    public userCountsArr: Array<any> = [];
    public totPackages: number;
    public totUsers: number;
    public totDays: number;
    public totNotificationCounts: number;
    public totUserCounts: number;
    public filterObj: any;
    public userCountFilterObj: any;
    public notificationCountFilterObj: any;
    public userFilterObj: any;
    public dayFilterObj: any;

    public userAnalyticsUserSearch: any;
    public userAnalyticsDateRangeSearch: any;
    public userAnalyticsLockerSearch: string = '';
    public userAnalyticsCarrierSearch: string = '';
    public parcelAnalyticsUserSearch: any;
    public parcelAnalyticsDateRangeSearch: any;
    public parcelAnalyticsLockerSearch: string = '';
    public parcelAnalyticsCarrierSearch: string = '';
    public daysAnalyticsUserSearch: any;
    public daysAnalyticsDateRangeSearch: any;
    public daysAnalyticsLockerSearch: string = '';
    public daysAnalyticsCarrierSearch: string = '';

    typeAheadLoading: boolean;
    dataSource: Observable<any>;

    constructor(private bdService: AnalyticsService, private exportService: ExportService,
                private formatMilliSecond: FormatMillisecondPipe, private usersService: UsersService,
                private lockersService: LockersService) {
        this.isDataLoaded = false;
        this.isDataLoadedForNotificationCounts = false;
        this.isDataLoadedForUserCounts = false;
        this.isDataLoadedForDaysAnalytics = false;
        this.isExporting = false;
        this.totPackages = 0;
        this.totUsers = 0;
        this.totDays = 0;
        this.totNotificationCounts = 0;
        this.totUserCounts = 0;
        this.filterObj = {sortBy: '', orderBy: '', page: 1, limit: 10};
        this.userCountFilterObj = {sortBy: '', orderBy: '', page: 1, limit: 10};
        this.notificationCountFilterObj = {sortBy: '', orderBy: '', page: 1, limit: 10};
        this.userFilterObj = {sortBy: '', orderBy: '', page: 1, limit: 10};
        this.dayFilterObj = {sortBy: '', orderBy: '', page: 1, limit: 10};
    }

    ngOnInit() {
        this.fnSelectTab('Users');
        this.fnGetLockers();
    }

    fnClearDate() {
        switch (this.currentTab) {
            case 'Users':
                delete this.userFilterObj.startDate;
                delete this.userFilterObj.endDate;
                this.userAnalyticsDateRangeSearch = '';
                this.userFilterObj.page = 1;
                this.fnGetUsersAnalytics(this.userFilterObj);
                break;
            case 'Parcels':
                delete this.filterObj.startDate;
                delete this.filterObj.endDate;
                this.parcelAnalyticsDateRangeSearch = '';
                this.filterObj.page = 1;
                this.fnGetPackageAnalytics(this.filterObj);
                break;
            case 'Days':
                delete this.dayFilterObj.startDate;
                delete this.dayFilterObj.endDate;
                this.daysAnalyticsDateRangeSearch = '';
                this.dayFilterObj.page = 1;
                delete this.userCountFilterObj.startDate;
                delete this.userCountFilterObj.endDate;
                delete this.notificationCountFilterObj.startDate;
                delete this.notificationCountFilterObj.endDate;
                this.userCountFilterObj.page = 1;
                this.notificationCountFilterObj.page = 1;

                this.fnGetDaysAnalytics(this.dayFilterObj);
               break;
        }
    }

    fnClearFilters() {
        switch (this.currentTab) {
            case 'Users':
                this.userAnalyticsUserSearch = '';
                this.userAnalyticsDateRangeSearch = '';
                this.userAnalyticsLockerSearch = '';
                this.userAnalyticsCarrierSearch = '';
                this.userFilterObj = {sortBy: '', orderBy: '', page: 1, limit: 10};
                this.fnGetUsersAnalytics(this.userFilterObj);
                break;
            case 'Parcels':
                this.parcelAnalyticsUserSearch = '';
                this.parcelAnalyticsDateRangeSearch = '';
                this.parcelAnalyticsLockerSearch = '';
                this.parcelAnalyticsCarrierSearch = '';
                this.filterObj = {sortBy: '', orderBy: '', page: 1, limit: 10};
                this.fnGetPackageAnalytics(this.filterObj);
                break;
            case 'Days':
                this.daysAnalyticsUserSearch = '';
                this.daysAnalyticsDateRangeSearch = '';
                this.daysAnalyticsLockerSearch = '';
                this.daysAnalyticsCarrierSearch = '';
                this.dayFilterObj = {sortBy: '', orderBy: '', page: 1, limit: 10};
                this.notificationCountFilterObj = {sortBy: '', orderBy: '', page: 1, limit: 10};
                this.userCountFilterObj = {sortBy: '', orderBy: '', page: 1, limit: 10};
                this.fnGetDaysAnalytics(this.dayFilterObj);
                this.fnGetNotificationCounts(this.notificationCountFilterObj);
                this.fnGetUserCounts(this.userCountFilterObj);
                break;
        }
    }

    async fnGetLockers() {
        this.lockersArr = [{_id: '', name: 'Select Locker'}];
        this.lockersArr = <Array<Locker>> await this.lockersService.getLockersNameAndNumber();
        this.lockersArr.unshift({_id: '', name: 'Select Locker'});
    }

    changeTypeAheadLoading(e: boolean): void {
        this.typeAheadLoading = e;
    }

    filterResults(token: string) {
        return this.usersService.fnGetUsers({name: token});
    }

    fnOnSelectUser(event: TypeaheadMatch): void {
        switch (this.currentTab) {
            case 'Users':
                this.userFilterObj.userId = event.item._id;
                this.userAnalyticsUserSearch = event.item.firstName + ' ' + event.item.lastName;
                this.fnChangeAnalytics(this.userFilterObj);
                break;
            case 'Parcels':
                this.filterObj.userId = event.item._id;
                this.parcelAnalyticsUserSearch = event.item.firstName + ' ' + event.item.lastName;
                this.fnChangeAnalytics(this.filterObj);
                break;
            case 'Days':
                this.dayFilterObj.userId = event.item._id;
                this.daysAnalyticsUserSearch = event.item.firstName + ' ' + event.item.lastName;
                this.fnChangeAnalytics(this.dayFilterObj);
                break;
        }
    }

    fnTypeAheadOnBlur(event: any): void {
        switch (this.currentTab) {
            case 'Users':
                if (!this.userAnalyticsUserSearch) {
                    delete this.userFilterObj.userId;
                } else {
                    this.userFilterObj.userId = event.item._id;
                }
                this.fnChangeAnalytics(this.userFilterObj);
                break;
            case 'Parcels':
                if (!this.parcelAnalyticsUserSearch) {
                    delete this.filterObj.userId;
                } else {
                    this.filterObj.userId = event.item._id;
                }
                this.fnChangeAnalytics(this.filterObj);
                break;
            case 'Days':
                if (!this.daysAnalyticsUserSearch) {
                    delete this.dayFilterObj.userId;
                } else {
                    this.dayFilterObj.userId = event.item._id;
                }
                this.fnChangeAnalytics(this.dayFilterObj);
                break;
        }
    }

    fnChangeAnalytics(filterObj: any){
        switch (this.currentTab) {
            case 'Users':
                this.userFilterObj.page = 1;
                this.fnGetUsersAnalytics(filterObj);
                break;
            case 'Parcels':
                this.filterObj.page = 1;
                this.fnGetPackageAnalytics(filterObj);
                break;
            case 'Days':
                this.dayFilterObj.page = 1;
                this.fnGetDaysAnalytics(filterObj);
               break;
        }
    }

    fnSelectedDate(value: any) {
        switch (this.currentTab) {
            case 'Users':
                if (value) {
                    this.userFilterObj.startDate = value.picker.startDate.format('MM/DD/YYYY');
                    this.userFilterObj.endDate = value.picker.endDate.format('MM/DD/YYYY');
                    this.userAnalyticsDateRangeSearch = this.userFilterObj.startDate + ' - ' + this.userFilterObj.endDate;
                } else {
                    delete this.userFilterObj.startDate;
                    delete this.userFilterObj.endDate;
                }
                this.fnChangeAnalytics(this.userFilterObj);
                break;
            case 'Parcels':
                if (value) {
                    this.filterObj.startDate = value.picker.startDate.format('MM/DD/YYYY');
                    this.filterObj.endDate = value.picker.endDate.format('MM/DD/YYYY');
                    this.parcelAnalyticsDateRangeSearch = this.filterObj.startDate + ' - ' + this.filterObj.endDate;
                } else {
                    delete this.filterObj.startDate;
                    delete this.filterObj.endDate;
                }
                this.fnChangeAnalytics(this.filterObj);
                break;
            case 'Days':
                if (value) {
                    this.dayFilterObj.startDate = value.picker.startDate.format('MM/DD/YYYY');
                    this.dayFilterObj.endDate = value.picker.endDate.format('MM/DD/YYYY');
                    this.daysAnalyticsDateRangeSearch = this.dayFilterObj.startDate + ' - ' + this.dayFilterObj.endDate;
                } else {
                    delete this.dayFilterObj.startDate;
                    delete this.dayFilterObj.endDate;
                }
                this.notificationCountFilterObj = this.dayFilterObj;
                this.userCountFilterObj = this.dayFilterObj;
                delete this.notificationCountFilterObj.carrierName;
                delete this.userCountFilterObj.carrierName;
                delete this.userCountFilterObj.userId;
                delete this.notificationCountFilterObj.userId;
                this.fnChangeAnalytics(this.dayFilterObj);
                this.fnGetNotificationCounts(this.notificationCountFilterObj);
                this.fnGetUserCounts(this.userCountFilterObj);
                break;
        }
    }

    fnCarrierChange() {
        switch (this.currentTab) {
            case 'Users':
                this.userFilterObj.carrierName = this.userAnalyticsCarrierSearch;
                this.fnChangeAnalytics(this.userFilterObj);
                break;
            case 'Parcels':
                this.filterObj.carrierName = this.parcelAnalyticsCarrierSearch;
                this.fnChangeAnalytics(this.filterObj);
                break;
            case 'Days':
                this.dayFilterObj.carrierName = this.daysAnalyticsCarrierSearch;
                this.fnChangeAnalytics(this.dayFilterObj);
                  break;
        }
    }

    fnLockerChange() {
        switch (this.currentTab) {
            case 'Users':
                this.userFilterObj.lockerId = this.userAnalyticsLockerSearch;
                this.fnChangeAnalytics(this.userFilterObj);
                break;
            case 'Parcels':
                this.filterObj.lockerId = this.parcelAnalyticsLockerSearch;
                this.fnChangeAnalytics(this.filterObj);
                break;
            case 'Days':
                this.dayFilterObj.lockerId = this.daysAnalyticsLockerSearch;
                delete this.notificationCountFilterObj.lockerId;
                delete this.userCountFilterObj.lockerId;
                this.fnChangeAnalytics(this.dayFilterObj);
                 break;
        }
    }

    /**
     * Get Users Analytics
     * @param {object} params
     * */
    fnGetUsersAnalytics(params?: object) {
        this.isDataLoaded = false;
        this.bdService.fnGetUsersAnalytics(params)
            .then((res: any) => {
                this.totUsers = res.metadata.length ? res.metadata[0].total : 0;
                this.userArr = this.fnGetAvgTimeOfDay(res.data);
                this.isDataLoaded = true;
            })
            .catch((err: any) => {
                this.totUsers = 0;
                this.userArr = [];
                this.isDataLoaded = true;
                console.log('Problem occurs on getting user analytics.', err);
            });

    }

    fnGetAvgTimeOfDay(arr) {
        for (const i of arr){
            if (i.avgTimeOfDay) {
                i.avgTimeOfDay = i.avgTimeOfDay.filter(function (el) {
                    return el != "" && el != null && el != " ";
                });
                const result = _.head(_(i.avgTimeOfDay)
                    .countBy()
                    .entries()
                    .maxBy('[1]'));
                i.avgTimeOfDay = result;
            }
        }
        return arr;
    }

    /**
     * Get Package Analytics
     * @param {object} params
     * */
    fnGetPackageAnalytics(params?: object) {
        this.isDataLoaded = false;
        this.bdService.fnGetPackages(params)
            .then((res: any) => {
                this.totPackages = res.metadata.length ? res.metadata[0].total : 0;
                this.packageArr = res.data;
                this.isDataLoaded = true;
            })
            .catch((err: any) => {
                this.totPackages = 0;
                this.packageArr = [];
                this.isDataLoaded = true;
                console.log('Problem occurs on getting package analytics.', err);
            });

    }

    fnPageChanged(event: any) {
        this.filterObj.page = event.page;
        this.fnGetPackageAnalytics(this.filterObj);
    }
    fnUserCountPageChanged(event: any) {
        this.userCountFilterObj.page = event.page;
        this.fnGetUserCounts(this.userCountFilterObj);
    }
    fnNotificationCountPageChanged(event: any) {
        this.notificationCountFilterObj.page = event.page;
        this.fnGetNotificationCounts(this.notificationCountFilterObj);
    }

    fnUserPageChanged(event: any) {
        this.userFilterObj.page = event.page;
        this.fnGetUsersAnalytics(this.userFilterObj);
    }

    fnDaysPageChanged(event: any) {
        this.dayFilterObj.page = event.page;
        this.fnGetDaysAnalytics(this.dayFilterObj);
        this.fnGetNotificationCounts(this.notificationCountFilterObj);
        this.fnGetUserCounts(this.userCountFilterObj);

    }


    /**
     * Get Days Analytics
     * @param {object} params
     * */
    fnGetDaysAnalytics(params?: object) {
        this.isDataLoadedForDaysAnalytics = false;
        this.bdService.fnGetDaysAnalytics(params)
            .then((res: any) => {
                this.totDays = res.metadata.length ? res.metadata[0].total : 0;
                this.daysArr = res.data;
                this.isDataLoadedForDaysAnalytics = true;
            })
            .catch((err: any) => {
                this.totDays = 0;
                this.daysArr = [];
                this.isDataLoadedForDaysAnalytics = true;
                console.log('Problem occurs on getting days analytics.', err);
            });
    }

    fnGetNotificationCounts(params?: object) {
        this.isDataLoadedForNotificationCounts = false;
        this.bdService.fnGetNotificationCounts(params)
            .then((res: any) => {
                this.totNotificationCounts = res[0].metadata.length ? res[0].metadata[0].total : 0;
                this.notificationCountsArr = res[0].data;
                this.isDataLoadedForNotificationCounts = true;
            })
            .catch((err: any) => {
                this.isDataLoadedForNotificationCounts = true;
                console.log('Problem occurs on getting notification counts.', err);
            });
    }


    fnGetUserCounts(params?: object) {
        this.isDataLoadedForUserCounts = false;
        this.bdService.fnGetUserCounts(params)
            .then((res: any) => {
                this.totUserCounts  = res[0].metadata.length ? res[0].metadata[0].total : 0;
                this.userCountsArr = res[0].data;
                this.isDataLoadedForUserCounts = true;
            })
            .catch((err: any) => {
                this.isDataLoadedForUserCounts = true;
                console.log('Problem occurs on getting user counts.', err);
            });
    }



    fnSelectTab(selectedTab, event?) {
        this.currentTab = selectedTab;
        switch (selectedTab) {
            case 'Users':
                this.fnGetUsersAnalytics(this.userFilterObj);
                this.dataSource = new Observable((observer: any) => {
                    observer.next(this.userAnalyticsUserSearch);
                }).pipe(mergeMap((token: string) => this.filterResults(token)));
                break;
            case 'Parcels':
                this.fnGetPackageAnalytics(this.filterObj);
                this.dataSource = new Observable((observer: any) => {
                    observer.next(this.parcelAnalyticsUserSearch);
                }).pipe(mergeMap((token: string) => this.filterResults(token)));
                break;
            case 'Days':
                this.fnGetDaysAnalytics(this.dayFilterObj);
                this.fnGetNotificationCounts(this.notificationCountFilterObj);
                this.fnGetUserCounts(this.userCountFilterObj);
                this.dataSource = new Observable((observer: any) => {
                    observer.next(this.daysAnalyticsUserSearch);
                }).pipe(mergeMap((token: string) => this.filterResults(token)));
                break;
        }
    }

    fnExportCSV() {
        this.isExporting = true;
        switch (this.currentTab) {
            case 'Users':
                this.bdService.fnGetUsersAnalytics({ page: 1, limit: 10000 })
                    .then((res: any) => {
                        const userAnalytics = this.fnGetAvgTimeOfDay(res.data);
                        const csvArr = [];
                        _.forEach(userAnalytics, element => {
                            if (element.user && element.user.firstName) {
                                element.user = element.user.lastName
                                    ? element.user.firstName + ' ' + element.user.lastName : element.user.firstName;
                            } else {
                                element.user = ' ';
                            }
                            element.avgTotalLifetime = element.avgTotalLifetime ? this.formatMilliSecond.transform(element.avgTotalLifetime) : ' ';
                            element.avgPickupDuration = element.avgPickupDuration ? this.formatMilliSecond.transform(element.avgPickupDuration) : ' ';
                            element.avgExtractionDuration = element.avgExtractionDuration ? this.formatMilliSecond.transform(element.avgExtractionDuration) : ' ';
                            csvArr.push(element);
                        });
                        const headers = ['user', 'avgTotalLifetime', 'avgPickupDuration',
                            'avgExtractionDuration', 'expiredParcelAmt', 'avgTimeOfDay'];
                        this.exportService.fnDownloadCSV('User-Analytics', csvArr, headers);
                        this.isExporting = false;
                    })
                    .catch((err: any) => {
                        this.isExporting = false;
                        console.log('Problem occurs in exporting user analytics.', err);
                    });
                break;

            case 'Parcels':
                this.bdService.fnGetPackages({ page: 1, limit: 10000 })
                    .then((res: any) => {
                        const parcelAnalytics = res.data;
                        const csvArr = [];
                        _.forEach(parcelAnalytics, element => {
                            element.depositeDuration = element.depositeDuration ? this.formatMilliSecond.transform(element.depositeDuration) : ' ';
                            element.totalLifetime = element.totalLifetime ? this.formatMilliSecond.transform(element.totalLifetime) : ' ';
                            element.pickupDuration = element.pickupDuration ? this.formatMilliSecond.transform(element.pickupDuration) : ' ';
                            element.extractionDuration = element.extractionDuration ? this.formatMilliSecond.transform(element.extractionDuration) : ' ';
                            csvArr.push(element);
                        });
                        const headers = ['label', 'depositeDuration', 'totalLifetime',
                            'pickupDuration', 'extractionDuration', 'timeOfDay'];
                        this.exportService.fnDownloadCSV('Parcel-Analytics', csvArr, headers);
                        this.isExporting = false;
                    })
                    .catch((err: any) => {
                        this.isExporting = false;
                        console.log('Problem occurs in exporting package analytics.', err);
                    });
                break;

            case 'Days':
                this.bdService.fnGetDaysAnalytics({ page: 1, limit: 10000 })
                    .then((res: any) => {
                        const daysAnalytics = res.data;
                        const csvArr = [];
                        _.forEach(daysAnalytics, element => {
                            if (element._id && element._id.day && element._id.month && element._id.year){
                                element.date = element._id.day + '/' + element._id.month + '/' + element._id.year;
                            } else {
                                element.date = ' ';
                            }
                            csvArr.push(element);
                        });
                        const headers = ['date', 'newParcelCount', 'pickParcelCount', 'pickupTypeQRScan',
                            'pickupTypeManual'];
                        this.exportService.fnDownloadCSV('Days-Analytics', csvArr, headers);
                        this.isExporting = false;
                    })
                    .catch((err: any) => {
                        this.isExporting = false;
                        console.log('Problem occurs in exporting days analytics.', err);
                    });
                break;
        }
    }

}
