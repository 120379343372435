import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Constant} from '../../common/constant';

@Injectable()
export class DashboardService {

  constructor(private http: HttpClient) {
  }

    getPackageCounts (params?: object) {
        let urlSearchParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key) && params[key]) {
                urlSearchParams = urlSearchParams.append(key, params[key]);
            }
        }
        const qString = urlSearchParams.toString();
        return this.http.get(Constant.API_URL + 'api/packages/dashboard' + (qString ? '?' + qString : '')).toPromise();
    }

    getFeedbackCounts (params?: object) {
        let urlSearchParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key) && params[key]) {
                urlSearchParams = urlSearchParams.append(key, params[key]);
            }
        }
        const qString = urlSearchParams.toString();
        return this.http.get(Constant.API_URL + 'api/feedback/dashboard' + (qString ? '?' + qString : '')).toPromise();
    }

    getErrorCounts (params?: object) {
        let urlSearchParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key) && params[key]) {
                urlSearchParams = urlSearchParams.append(key, params[key]);
            }
        }
        const qString = urlSearchParams.toString();
        return this.http.get(Constant.API_URL + 'api/notifications/dashboard' + (qString ? '?' + qString : '')).toPromise();
    }

    getJammedSlotsCounts (params?: object) {
        let urlSearchParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key) && params[key]) {
                urlSearchParams = urlSearchParams.append(key, params[key]);
            }
        }
        const qString = urlSearchParams.toString();
        return this.http.get(Constant.API_URL + 'api/slots/dashboard' + (qString ? '?' + qString : '')).toPromise();
    }

    async getHolidays(params?: object) {
        let urlSearchParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key) && params[key]) {
                urlSearchParams = urlSearchParams.append(key, params[key]);
            }
        }
        const qString = urlSearchParams.toString();
        return this.http.get(Constant.API_URL + 'api/holidays' + (qString ? '?' + qString : '')).toPromise();
    }
}
