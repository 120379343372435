import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Constant} from '../../../common/constant';
import {User} from '../../users/user';

@Injectable()
export class EnrollmentUserService {

    constructor(private http: HttpClient) {
    }

    /**
     * Create new user
     * @param {object} userObj
     * */
    fnCreateUser(userObj: User) {
        return new Promise((resolve, reject) => {
            this.http
                .post(Constant.API_URL + 'api/enrollment-user', userObj)
                .subscribe((response: User) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }
}
