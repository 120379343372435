import {Component, OnInit, Input, AfterViewInit, ViewChild, ChangeDetectorRef} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';

import {User} from '../../users/user';
import {Company} from '../../companies/company';
import {AuthService} from '../../../common/auth.service';
import {Constant} from '../../../common/constant';
import {ToastrService} from '../../../common/toastr.service';
import {CompaniesService} from '../../companies/companies.service';
import {UsersService} from '../../users/users.service';

declare let google: any;

@Component({
    selector: 'pp-user-modal',
    templateUrl: './user-modal.component.html',
    styleUrls: ['./user-modal.component.css']
})

export class UserModalComponent implements OnInit, AfterViewInit {

    @Input() public userObj: any = {};
    @ViewChild('location', {static: false}) location: string;
    public onClose: Subject<boolean>;
    public componentId: number;
    public isVisible: boolean;
    public isVisibleAnimate: boolean;
    public confirmPassword: string;
    public currentUser: User;
    public companiesArr: Array<Company> = [{_id: '', name: 'Select Company'}];
    public autoComplete: any;
    public addressTypeArr = ['street_number', 'route', 'locality', 'administrative_area_level_1', 'country', 'postal_code'];
    public emailRegEx: any = Constant.EMAIL_REG_EX;
    readonly CONSTANT = Constant;

    constructor(private auth: AuthService, private toastr: ToastrService, private chRef: ChangeDetectorRef,
                private usersService: UsersService, private companiesService: CompaniesService,
                private _bsModalRef: BsModalRef) {
        this.isVisible = false;
        this.isVisibleAnimate = false;
        this.confirmPassword = '';
        this.currentUser = this.auth.fnGetCurrentUser();
        this.userObj = {locationId: {formattedAddress: ''}, companyId: '', role: Constant.ROLE_USER};
    }

    ngOnInit() {
        this.onClose = new Subject();
        this.componentId = Date.now() + Math.floor(Math.random() * 100) + 1;
        if (this.currentUser.role === Constant.ROLE_ADMIN) {
            this.fnGetCompanies();
        }
    }

    ngAfterViewInit() {
        this.fnInitGMAutoComplete();
    }

    fnInitGMAutoComplete() {
        const self = this;
        this.autoComplete = new google.maps.places.Autocomplete(
            (document.getElementById('location')),
            {types: ['geocode']});
        google.maps.event.addListener(this.autoComplete, 'place_changed', () => {
            self.fnFillInAddress(self);
        });
    }

    fnFillInAddress(self) {
        // Get the place details from the auto complete object.
        const place = self.autoComplete.getPlace();
        self.userObj.locationId.formattedAddress = place.formatted_address;
        self.userObj.locationId.geoId = place.id;
        self.userObj.locationId.placeId = place.placeId;
        self.userObj.locationId.latitude = place.geometry.location.lat();
        self.userObj.locationId.longitude = place.geometry.location.lng();

        // Get each component of the address from the place details
        // and fill the corresponding field on the form.
        for (let intIndex = 0; intIndex < place.address_components.length; intIndex++) {
            const addressType = place.address_components[intIndex].types[0];
            if (self.addressTypeArr.indexOf(addressType) > -1) {
                const val = place.address_components[intIndex];
                if (addressType === 'street_number') {
                    self.userObj.locationId.streetNumber = val.short_name;
                } else if (addressType === 'route') {
                    self.userObj.locationId.route = val.long_name;
                } else if (addressType === 'locality') {
                    self.userObj.locationId.city = val.long_name;
                } else if (addressType === 'administrative_area_level_1') {
                    self.userObj.locationId.state = val.long_name;
                    self.userObj.locationId.stateCode = val.short_name;
                } else if (addressType === 'country') {
                    self.userObj.locationId.country = val.long_name;
                    self.userObj.locationId.countryCode = val.short_name;
                } else if (addressType === 'postal_code') {
                    self.userObj.locationId.postalCode = val.short_name;
                }
            }
        }
        self.chRef.detectChanges();
    }

    /**
     * Get companies
     * */
    fnGetCompanies() {
        this.companiesService.fnGetCompanies()
            .then((companiesArr: Array<Company>) => {
                this.companiesArr = companiesArr;
                this.companiesArr.unshift({_id: '', name: 'Select Company'});
            });
    }

    /**
     * Close modal.
     * */
    public fnCancel(userObj?: any) {
        this.onClose.next(userObj);
        this._bsModalRef.hide();
    }

    /**
     * Create User
     * */
    public fnSave(userObj): void {
        if (userObj.password === this.confirmPassword) {
            if (this.currentUser.role === Constant.ROLE_COMPANY_ADMIN || this.currentUser.role === Constant.ROLE_CARRIER_ADMIN) {
                userObj.companyId = this.currentUser.companyId;
            }
            this.usersService.fnCreateUser(userObj)
                .then((response) => {
                    this.toastr.fnSuccess('User created successfully.');
                    this.fnCancel(response);
                })
                .catch((error) => {
                    this.toastr.fnError(error.message);
                });
        }
    }
}
