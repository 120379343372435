import {Component, OnInit} from '@angular/core';
import {Company} from '../../companies/company';
import {CompaniesService} from '../../companies/companies.service';
import {CURRENCY, PLAN_TYPE, SLOT_SIZE} from '../../../common/enums';
import {PaymentPlan} from '../payment-plans';
import {ToastrService} from '../../../common/toastr.service';
import {PaymentPlanService} from '../payment-plan.service';
import {NgForm} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'pp-payment-plan',
    templateUrl: './payment-plan.component.html',
    styleUrls: ['./payment-plan.component.css']
})
export class PaymentPlanComponent implements OnInit  {
    public isDataLoaded;
    public planObj: PaymentPlan;
    public planType: PLAN_TYPE;
    public planId: string;
    public companiesArr: Array<Company> = [{_id: '', name: 'Select Company'}];
    public currencyArr: Array<any>;
    public slotSizeArr: Array<any>;
    public planTypeArr: Array<any>;
    public days: Array<number>;
    public numRegEx: any = '^\\s*(?=.*[1-9])\\d*(?:\\.\\d{1,2})?\\s*$';

    constructor(private companiesService: CompaniesService,
                private activatedRoute: ActivatedRoute,
                private paymentPlanService: PaymentPlanService,
                private router: Router,
                private toastr: ToastrService) {
        this.initPlanObj();
    }

    async ngOnInit() {
        this.activatedRoute.params.subscribe(params => {
            this.planId = params['id'];
        });

        if (this.planId !== 'add') {
            this.isDataLoaded = true;
            await this.getPaymentPlan(this.planId);
        }
    }

    /**
     * Get plan
     * @param {string} id
     * */
    async getPaymentPlan(id: string) {
        const planObj: any = await this.paymentPlanService.getPlan(id);
        if (planObj) {
            this.isDataLoaded = false;
            this.planObj = planObj;
            this.planObj.company = planObj.company._id;
            this.planType = planObj.type;
        }
    }

    initPlanObj() {
        this.getDayOfMonths();
        this.getPlanTypes();
        this.getCurrencies();
        this.getSlotSizes();
        this.getCompanies();

        this.planType = this.planTypeArr[0].name;
        this.planObj = {
            company: '',
            type: '',
            name: '',
            isDefault: false,
            isActive: false,
            currency: CURRENCY.CAD,
            fee: 0,
            billOnDayOfMonth: this.days[0]
        };
    }

    getDayOfMonths() {
        this.days = [];
        for (let i = 1; i <= 31; i++) {
            this.days.push(i);
        }
    }

    getSlotSizes() {
        this.slotSizeArr = [];
        for (const j in SLOT_SIZE) {
            this.slotSizeArr.push({id: j, name: <any>SLOT_SIZE[j]});
        }
    }

    getCurrencies() {
        this.currencyArr = [];
        for (const j in CURRENCY) {
            this.currencyArr.push({id: j, name: <any>CURRENCY[j]});
        }
    }

    getPlanTypes() {
        this.planTypeArr = [];
        for (const j in PLAN_TYPE) {
            this.planTypeArr.push({id: j, name: <any>PLAN_TYPE[j]});
        }
    }

    getCompanies() {
        this.companiesService.fnGetCompanies()
            .then((companiesArr: Array<Company>) => {
                this.companiesArr = companiesArr;
                this.companiesArr.unshift({_id: '', name: 'Select Company'});
            });
    }

    setPlanType(planObject: PaymentPlan) {
        console.log(this.planType);
        switch (this.planType) {
            case PLAN_TYPE.RECURRING_PLAN:
                if (planObject.chargeTime) {
                    delete planObject.chargeTime;
                }

                if (planObject.slotFees) {
                    delete planObject.slotFees;
                }

                planObject.currency = CURRENCY.CAD;
                planObject.fee = 0;
                planObject.billOnDayOfMonth = this.days[0];
                break;
            case PLAN_TYPE.PAY_PER_USE_PLAN:
                if (planObject.currency) {
                    delete planObject.currency;
                }

                if (planObject.fee) {
                    delete planObject.fee;
                }

                if (planObject.billOnDayOfMonth) {
                    delete planObject.billOnDayOfMonth;
                }

                planObject.chargeTime = 'AFTER_DROPOFF';
                planObject.slotFees = [];
                this.slotSizeArr.forEach(ele => {
                    planObject.slotFees.push({size: ele.id, fee: 0});
                });
                break;
        }
        this.planObj = planObject;
    }

    resetForm(planForm: NgForm) {
        planForm.form.clearValidators();
        planForm.resetForm();
        this.initPlanObj();
    }

    async savePlan(planForm: NgForm) {
        const createSuccessMsg = 'Payment plan created successfully.';
        const updateSuccessMsg = 'Payment plan updated successfully.';
        const plan = this.planObj._id ?
            await this.paymentPlanService.updatePlan(this.planObj, this.planObj._id) :
            await this.paymentPlanService.createPlan(this.planObj);
        if (!plan) {
            this.toastr.fnError('Something went wrong. Please try again later.');
        } else {
            this.toastr.fnSuccess(this.planObj._id ? updateSuccessMsg : createSuccessMsg);
            this.router.navigateByUrl('/payment-plans');
        }
    }
}

