<div class="row page">
    <div class="col-lg-12">
        <h1 class="page-header">Pending Shipment
        </h1>
        <ul class="breadcrumb">
            <li><a href="javascript:void(0);" [routerLink]="['/pending-shipments']">Pending Shipments</a></li>
            <li>Add Pending Shipment</li>
        </ul>
    </div>
    <pp-spinner [isSpinnerLoad]="isDataLoaded"></pp-spinner>
    <!-- START: Manual -->
    <div class="col-lg-12" *ngIf="!isDataLoaded">
        <form novalidate #shipmentForm="ngForm"
              (ngSubmit)="shipmentForm.valid && saveShipment(shipmentObj)">
            <fieldset class="col-md-12 fieldset" >
                <legend class="fieldset-legend">Basic</legend>
                <div class="row">
                    <div class="form-group col-md-4"
                         [ngClass]="{'has-error': (shipmentForm.submitted || company.touched) && !company.valid}"
                         *ngIf="currentUser.role === CONSTANT.ROLE_ADMIN">
                        <label for="company">Select Company<span class="text-danger">*</span></label>
                        <select id="company" class="form-control" name="company" [(ngModel)]="shipmentObj.companyId"
                                #company="ngModel" (change)="changeCompany(shipmentObj.companyId)"
                                required>
                            <ng-template ngFor let-item [ngForOf]="companiesArr" let-i="index">
                                <option [ngValue]="item._id">
                                    {{item.name}}
                                </option>
                            </ng-template>
                        </select>
                        <div *ngIf="(shipmentForm.submitted || company.touched) && !company.valid">
                            <small class="text-danger" *ngIf="company.errors?.required">Company is required.</small>
                        </div>
                    </div>

                    <div class="form-group"
                         [ngClass]="{'col-md-4': currentUser.role === CONSTANT.ROLE_ADMIN, 'col-md-6': currentUser.role === CONSTANT.ROLE_COMPANY_ADMIN, 'has-error': ((shipmentForm.submitted || locker.touched) && !locker.valid)}">
                        <label for="locker">Select Locker<span class="text-danger">*</span></label>
                        <select id="locker" class="form-control" name="locker"
                                [(ngModel)]="shipmentObj.locker"
                                #locker="ngModel" (change)="changeLocker()" required>
                            <ng-template ngFor let-item [ngForOf]="lockersArr" let-i="index">
                                <option [ngValue]="item.lockerNumber">
                                    {{item.name}}
                                </option>
                            </ng-template>
                        </select>
                        <div *ngIf="(shipmentForm.submitted || locker.touched) && !locker.valid">
                            <small class="text-danger" *ngIf="locker.errors?.required">Locker is required.</small>
                        </div>
                    </div>

                    <div class="form-group" [ngClass]="{'col-md-4': currentUser.role === CONSTANT.ROLE_ADMIN, 'col-md-6': currentUser.role === CONSTANT.ROLE_COMPANY_ADMIN }">
                        <label for="courier">Courier</label>
                        <select id="courier" class="form-control" name="courier"
                                [(ngModel)]="shipmentObj.courier" placeholder="Select Courier" #courier="ngModel">
                            <ng-template ngFor let-item [ngForOf]="carriersArr" let-i="index">
                                <option class="capitalize" [ngValue]="item.carrierCode">
                                    {{item.name | CarrierName}}
                                </option>
                            </ng-template>
                        </select>
                    </div>
                </div>
            </fieldset>

            <fieldset class="col-md-12 fieldset">
                <legend class="fieldset-legend">Shipment</legend>

                <div class="row">
                    <div class="form-group col-md-6"
                         [ngClass]="{'has-error': (shipmentForm.submitted || trackingNumber.touched) && !trackingNumber.valid}">
                        <label for="trackingNumber">Tracking number<span class="text-danger">*</span></label>
                        <input id="trackingNumber" type="tel" minlength="6" class="form-control" name="trackingNumber"
                               placeholder="Tracking number" #trackingNumber="ngModel"
                               [(ngModel)]="shipmentObj.trackingNumber"
                               required/>
                        <div *ngIf="(shipmentForm.submitted || trackingNumber.touched) && !trackingNumber.valid">
                            <small class="text-danger" *ngIf="trackingNumber.errors?.required">
                                Tracking Number is required.
                            </small>
                            <small class="text-danger" *ngIf="trackingNumber.errors?.minlength">
                                Tracking Number should be atleast 6 digits longer.
                            </small>
                        </div>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="additionalAuthenticationCode">Additional verification code</label>
                        <input id="additionalAuthenticationCode" type="text" maxlength="4" class="form-control" name="additionalAuthenticationCode"
                               placeholder="Additional verification code" #additionalAuthenticationCode="ngModel"
                               [(ngModel)]="shipmentObj.additionalAuthenticationCode"
                               />
                        <div *ngIf="(shipmentForm.submitted || trackingNumber.touched) && !trackingNumber.valid">
                            <small class="text-danger" [ngStyle]="{visibility: 'hidden'}">Error
                            </small>
                        </div>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="size">Slot size</label>
                        <select id="size" class="form-control" name="slotSize"
                                [(ngModel)]="shipmentObj.slotSizeRequirement">
                            <option value="">Select Slot size</option>
                            <ng-template ngFor let-item [ngForOf]="slotSizes" let-i="index">
                                <option [ngValue]="item">
                                    {{item}}
                                </option>
                            </ng-template>
                        </select>
                    </div>
                    <div class="form-group col-md-6"
                         [ngClass]="{'has-error': (shipmentForm.submitted || expiryTime.touched) && !expiryTime.valid}">
                        <label for="expiryTime">Expire after (in hour)</label>
                        <input id="expiryTime" type="tel" class="form-control" name="expiryTime"
                               placeholder="Expire after" #expiryTime="ngModel"
                               [(ngModel)]="shipmentObj.reservationPeriod"/>
                    </div>
                </div>
            </fieldset>

            <fieldset class="col-md-12 fieldset">
                <legend class="fieldset-legend">Consignee</legend>
                <div class="row">
                    <div class="form-group col-md-6" [ngClass]="{'has-error': (shipmentForm.submitted || firstName.touched) && !firstName.valid}">
                        <label for="firstName">First name<span class="text-danger">*</span></label>
                        <input id="firstName" type="text" class="form-control" name="first_name"
                               placeholder="First name" [pattern]="CONSTANT.BLANK_SPACE_REG_EX" #firstName="ngModel"
                               [(ngModel)]="shipmentObj.consignee.firstName" required/>
                        <div *ngIf="(shipmentForm.submitted || firstName.touched) && !firstName.valid">
                            <small class="text-danger" *ngIf="firstName.errors?.required">
                                First name is required.
                            </small>
                            <small class="text-danger" *ngIf="firstName.errors['pattern']">
                                First name should not be blank.
                            </small>
                        </div>
                    </div>

                    <div class="form-group col-md-6" [ngClass]="{'has-error': (shipmentForm.submitted || lastName.touched) && !lastName.valid}">
                        <label for="lastName">Last name<span class="text-danger">*</span></label>
                        <input id="lastName" type="text" class="form-control" name="last_name"
                               placeholder="Last name" [pattern]="CONSTANT.BLANK_SPACE_REG_EX" #lastName="ngModel"
                               [(ngModel)]="shipmentObj.consignee.lastName" required/>
                        <div *ngIf="(shipmentForm.submitted || lastName.touched) && !lastName.valid">
                            <small class="text-danger" *ngIf="lastName.errors?.required">
                                Last name is required.
                            </small>
                            <small class="text-danger" *ngIf="lastName.errors['pattern']">
                                Last name should not be blank.
                            </small>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-6" [ngClass]="{'has-error': (shipmentForm.submitted || email.touched) && !email.valid}">
                        <label for="email" [ngStyle]="{display: 'flex', alignItems: 'center', gap: '0.5rem', marginBottom: '0.9rem'}">Email
                                <button
                                    type="button"
                                    class="btn btn-default btn-xs m-l-4"
                                    tooltip="This field must be completed if you want the recipient to receive an email notification"
                                    containerClass="tooltip"
                                    placement="right"
                                >
                                    <i class="fa fa-info-circle"></i>
                                </button>
                        </label>
                        <input id="email" type="email" class="form-control m-t-5" name="email"
                               placeholder="Email" #email="ngModel" [(ngModel)]="shipmentObj.consignee.email"  [pattern]="emailRegEx"/>
                        <div *ngIf="(shipmentForm.submitted || email.touched) && !email.valid">
                            <small class="text-danger" *ngIf="email.errors['pattern']">
                                Email is invalid.
                            </small>
                        </div>
                    </div>

                    <div class="form-group col-md-6" [ngClass]="{'has-error': (shipmentForm.submitted || phone.touched) && !phone.valid}">
                        <label for="phone" [ngStyle]="{display: 'flex', alignItems: 'center', gap: '0.5rem', marginBottom: '0.9rem'}">Phone
                            <button
                                type="button"
                                class="btn btn-default btn-xs m-l-4"
                                tooltip="This field must be completed if you want the recipient to receive a text notification"
                                containerClass="tooltip"
                                placement="right"
                            >
                                <i class="fa fa-info-circle"></i>
                            </button>
                    </label>

                        <input id="phone" type="tel" class="form-control m-t-5" name="phone"
                               placeholder="Phone" #phone="ngModel" [(ngModel)]="shipmentObj.consignee.phone"
                               [pattern]="phoneRegEx" maxlength="20" />
                        <div *ngIf="(shipmentForm.submitted || phone.touched) && !phone.valid">
                            <small class="text-danger" *ngIf="phone.errors['pattern'] || phone.errors['maxlength']">
                                Phone is invalid.
                            </small>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div *ngIf="shipmentObj.isOutboundEnabled" class="form-group col-md-6">
                        <label class="m-r-7 m-b-0">Is this a return shipment?</label>
                        <input type="checkbox" name="isOutbound" id="isOutbound"
                               [(ngModel)]="shipmentObj.isOutbound">
                    </div>
                </div>
            </fieldset>

            <div class="form-group">
                <button class="btn btn-primary m-r-4" type="submit">
                    {{shipmentObj._id ? 'Update Pending Shipment' : 'Create Pending Shipment'}}
                </button>
                <button class="btn btn-warning" [routerLink]="['/pending-shipments']">Cancel</button>
            </div>
        </form>
    </div>
    <!-- END: Manual -->
</div>
