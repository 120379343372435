<div id="wrapper">
    <!-- Navigation -->
    <nav class="navbar navbar-default navbar-fixed-top" role="navigation" style="margin-bottom: 0">
        <div class="navbar-header pull-left">
            <a class="navbar-brand p-0" href="javascript:void(0);">
                <img class="navbar-logo" src="assets/images/pp_logo.png" alt="Parcel Port"/>
            </a>
        </div>
    </nav>
    <!-- /.navbar-static-side -->
    <div id="page-wrapper">
        <div class="row">
            <div class="col-sm-10 col-sm-offset-1">
                <div class="panel panel-default whiteframe-10dp m-t-50">
                    <div class="panel-heading">
                        <div class="panel-title text-center" *ngIf="isActivated"><strong>Terms and Conditions</strong>
                        </div>
                        <div class="panel-title text-center" *ngIf="!isActivated"><strong>Confirmation Failed</strong>
                        </div>
                    </div>
                    <div class="panel-body">
                        <div *ngIf="isActivated">
                            <h4>1. Introduction</h4>
                            <p>These Website Standard Terms And Conditions (these "Terms" or these "Website Standard
                                Terms And
                                Conditions") contained herein on this webpage, shall govern your use of this website,
                                including all pages
                                within this website (collectively referred to herein below as this "Website"). These
                                Terms apply in full force
                                and effect to your use of this Website and by using this Website, you expressly accept
                                all terms and
                                conditions contained herein in full. You must not use this Website, if you have any
                                objection to any of these
                                Website Standard Terms And Conditions.
                                This Website is not for use by any minors (defined as those who are not at least 18
                                years of age), and
                                you must not use this Website if you a minor.</p>

                            <h4>2. Intellectual Property Rights</h4>
                            <p>Other than content you own, which you may have opted to include on this Website, under
                                these
                                Terms, [Sender.Company] and/or its licensors own all rights to the intellectual property
                                and material
                                contained in this Website, and all such rights are reserved.
                                You are granted a limited license only, subject to the restrictions provided in these
                                Terms, for purposes of
                                viewing the material contained on this Website</p>

                            <h4>3. Restrictions</h4>
                            <span>
                                You are expressly and emphatically restricted from all of the
                            <ul>
                                <li>publishing any Website material in any media;</li>
                                <li>selling, sublicensing and/or otherwise commercializing any Website material;</li>
                                <li>publicly performing and/or showing any Website material;</li>
                                <li>using this Website in any way that is, or may be, damaging to this Website;</li>
                                <li>using this Website in any way that impacts user access to this Website;</li>
                                <li>using this Website contrary to applicable laws and regulations, or in a way that
                                    causes, or may cause,
                                    harm to the Website, or to any person or business entity;
                                </li>
                                <li>engaging in any data mining, data harvesting, data extracting or any other
                                    similar activity in relation to
                                    this Website, or while using this Website;
                                </li>
                                <li>using this Website to engage in any advertising or marketing;
                                    Certain areas of this Website are restricted from access by you and [Sender.Company]
                                    may further restrict
                                    access by you to any areas of this Website, at any time, in its sole and absolute
                                    discretion. Any user ID and
                                    password you may have for this Website are confidential and you must maintain
                                    confidentiality of such
                                    information.
                                </li>
                            </ul>
                            </span>

                            <h4>4. Your Content</h4>
                            <p>In these Website Standard Terms And Conditions, "Your Content" shall mean any audio,
                                video, text, images
                                or other material you choose to display on this Website. With respect to Your Content,
                                by displaying it, you
                                grant [Sender.Company] a non-exclusive, worldwide, irrevocable, royalty-free,
                                sublicensable license to
                                use, reproduce, adapt, publish, translate and distribute it in any and all media.
                                Your Content must be your own and must not be infringing on any third party's rights.
                                [Sender.Company]
                                reserves the right to remove any of Your Content from this Website at any time, and for
                                any reason, without
                            </p>

                            <h4>5. No warranties</h4>
                            <p>This Website is provided "as is," with all faults, and [Sender.Company] makes no express
                                or implied
                                representations or warranties, of any kind related to this Website or the materials
                                contained on this Website.
                                Additionally, nothing contained on this Website shall be construed as providing consult
                                or advice to you.</p>

                            <h4>6. Limitation of liability</h4>
                            <p>In no event shall [Sender.Company] , nor any of its officers, directors and employees, be
                                liable to you for
                                anything arising out of or in any way connected with your use of this Website, whether
                                such liability is under
                                contract, tort or otherwise, and [Sender.Company] , including its officers, directors
                                and employees shall not
                                be liable for any indirect, consequential or special liability arising out of or in any
                                way related to your use of
                                this Website.</p>

                            <h4>7. Indemnification</h4>
                            <p>You hereby indemnify to the fullest extent [Sender.Company] from and against any and all
                                liabilities, costs,
                                demands, causes of action, damages and expenses (including reasonable attorney's fees)
                                arising out of or
                                in any way related to your breach of any of the provisions of these Terms.</p>

                            <h4>8. Severability</h4>
                            <p>If any provision of these Terms is found to be unenforceable or invalid under any
                                applicable law, such
                                unenforceability or invalidity shall not render these Terms unenforceable or invalid as
                                a whole, and such
                                provisions shall be deleted without affecting the remaining provisions herein.</p>


                            <h4>9. Variation of Terms</h4>
                            <p>[Sender.Company] is permitted to revise these Terms at any time as it sees fit, and by
                                using this Website
                                you are expected to review such Terms on a regular basis to ensure you understand all
                                terms and
                                conditions governing use of this Website.</p>

                            <h4>10. Assignment</h4>
                            <p>[Sender.Company] shall be permitted to assign, transfer, and subcontract its rights
                                and/or obligations under
                                these Terms without any notification or consent required. However, .you shall not be
                                permitted to assign,
                                transfer, or subcontract any of your rights and/or obligations under these Terms.</p>

                            <h4>11. Entire Agreement</h4>
                            <p>These Terms, including any legal notices and disclaimers contained on this Website,
                                constitute the entire
                                agreement between [Sender.Company] and you in relation to your use of this Website, and
                                supersede all
                                prior agreements and understandings with respect to the same.</p>
                        </div>
                        <div *ngIf="!isActivated" class="text-center">
                            {{userMessage}}
                        </div>
                    </div>
                    <div class="panel-footer text-center" *ngIf="isActivated">
                        <button class="btn btn-success btn-sm" (click)="fnAcceptTerms()">Accept</button>
                        <button class="btn btn-danger btn-sm" (click)="declineModal.show()">Decline</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div bsModal #declineModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="dialog-sizes-name2">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Warning!</h4>
            </div>
            <div class="modal-body">
                <p>
                    You must accept our Terms of Use before completing registration.
                </p>
            </div>
            <div class="modal-footer">
                <button class="btn-primary btn" (click)="declineModal.hide()">Ok</button>
            </div>
        </div>
    </div>
</div>
