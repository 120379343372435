import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

@Injectable()
export class ExportService {

    constructor() {
    }

    fnConvertToCSV(objArray, headers) {
        const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        let row = '';
        _.forEach(headers, val => {
            row += val + ',';
        });
        row = row.slice(0, -1);
        str += row + '\r\n';

        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let h = 0; h < headers.length; h++) {
                if (line !== '') {
                    line += ',';
                }
                line += array[i][headers[h]] === null || array[i][headers[h]] === undefined ? ' ' : array[i][headers[h]];
            }
            str += line + '\r\n';
        }
        return str;
    }

    fnBuildJSON(jsonToBuild) {
        const arr = [];
        _.forEach(jsonToBuild, (v, k) => {
            const obj = {};
            this.fnIterate(v, '', obj);
            arr.push(obj);
        });
        return arr;
    }

    fnIterate(data, key, obj) {
        if (_.isPlainObject(data) || _.isArray(data)) {
            _.forEach(data, (v, k) => {
                const newKey = _.isArray(data) ? key : (key !== '' ? key + '.' : '') + k;
                this.fnIterate(v, newKey, obj);
            });
        } else {
            data = this.fnIsDate(data) ? moment(data).format('YYYY-MM-DD H:mm:ss') : data;
            obj[key] = data;
        }
    }

    fnIsDate(date) {
        return moment(date).isValid() && date.length === 24;
    }

    fnDownloadCSV(fileName, jsonToExport, headers) {
        const newJson = this.fnBuildJSON(jsonToExport);
        const csvData = this.fnConvertToCSV(newJson, headers);
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        document.body.appendChild(a);
        const blob = new Blob([csvData], {type: 'text/csv'});
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName + '.csv';
        a.click();
    }
}
