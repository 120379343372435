import {Component, OnInit, OnDestroy} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import * as _ from 'lodash';

import { DeleteModalComponent } from '../../delete-modal/delete-modal.component';
import { AuthService } from '../../common/auth.service';
import { StorageService } from '../../common/storage.service';
import { CompaniesService } from '../companies/companies.service';
import { LockersService } from './lockers.service';
import { CabinetsService } from '../cabinets/cabinets.service';
import { SlotsService } from '../slots/slots.service';
import {Locker, LOCKER_CODES_TYPE} from './locker';
import { User } from '../users/user';
import { Company } from '../companies/company';
import { Constant } from '../../common/constant';
import { ToastrService } from '../../common/toastr.service';
import {ExportService} from '../../common/export.service';
import {Router} from '@angular/router';
import {Cabinet} from '../cabinets/cabinet';
import {Slot} from '../slots/slot';
import {SearchResultsWithMetadata} from "../../common/search-results";
import {SocketIOService} from '../../common/socketio.service';

@Component({
    selector: 'pp-lockers',
    templateUrl: './lockers.component.html',
    styleUrls: ['./lockers.component.css']
})

export class LockersComponent implements OnInit, OnDestroy {

    public isDataLoaded;
    public isExporting: boolean;
    public isAllCabinets;
    public filterObj: any;
    public totalLockers: number;
    public currentUser: User;
    public lockersArr: Array<Locker> = [];
    public companiesArr: Array<Company> = [{ _id: '', name: 'Select Company' }];
    readonly CONSTANT = Constant;
    private LOCKERS_FILTER = 'lockersFilter';
    private preservedCode: string;
    public alphaNumRegEx: string = '[a-zA-Z0-9]*';
    selectedAccessCodeIndex: number = -1;
    selectedLocker:any;
    toggleReturnPickup: boolean = false;
    toggleProductReturnPickup: boolean = false;

    public slotStatusModelRef: BsModalRef;
    public slotDetails: { boxId: string, lockerId: string, slotOpenReason?: string};
    public slotStatus: any;
    public slotResponse: any;
    public slotStatusResp: any;

    constructor(private router: Router, private _bsModalService: BsModalService, private companiesService: CompaniesService,
        private lockersService: LockersService, private cabinetsService: CabinetsService,
        private slotsService: SlotsService, private auth: AuthService, private storage: StorageService,
        private toastr: ToastrService, private exportService: ExportService, private socketIOService: SocketIOService) {
        this.currentUser = this.auth.fnGetCurrentUser();
        this.totalLockers = 0;
        this.isDataLoaded = false;
        this.isExporting = false;
        this.isAllCabinets = false;
        this.fnGetLockersFilters();
    }

    ngOnInit() {
        this.selectedAccessCodeIndex = -1;
        this.selectedLocker = null;
        this.socketIOService.connectSocket(window.location.host === Constant.DEV_API_URL ? Constant.DEV_API_URL : Constant.PROD_1_API_URL);
        this.fnGetCompanies();
    }

    /**
     * Disconnect the socket connection
     * */
    ngOnDestroy() {
        this.socketIOService.closeConnection();
    }

    /**
     * Get local storage data
     * */
    fnGetLockersFilters() {
        const LOCKERS_FILTER = this.storage.fnGetData(this.LOCKERS_FILTER);
        this.filterObj = _.isEmpty(LOCKERS_FILTER) ? { companyId: '', sortBy: 'name', orderBy: 'asc', page: 1, limit: 10 } : LOCKERS_FILTER;
    }

    /**
     * Clear filter
     * */
    fnClearFilters() {
        this.storage.fnRemove(this.LOCKERS_FILTER);
        this.fnGetLockersFilters();
        this.filterObj.sortBy = 'name';
        this.filterObj.orderBy = 'asc';
        this.fnChangeFilters(this.filterObj);
    }

    /**
     * Filter change.
     * @param {object} params
     * */
    fnChangeFilters(params?: object) {
        this.filterObj.page = 1;
        this.storage.fnStoreData(this.LOCKERS_FILTER, params);
        this.fnGetLockers(params);
    }

    /**
     * Set column sort icon
     * @param {string} sortBy
     * */
    setSortIcon(sortBy: string) {
        if (this.filterObj.sortBy === sortBy) {
            return this.filterObj.orderBy === 'desc' ? 'fa fa-sort-desc' : 'fa fa-sort-asc';
        }
        return 'fa fa-sort';
    }

    /**
     * Sort by column name
     * @param {string} sortBy
     * */
    sortBy(sortBy: string) {
        if (this.filterObj.sortBy === sortBy) {
            this.filterObj.sortBy = sortBy;
            if (this.filterObj.orderBy === 'asc') {
                this.filterObj.orderBy = 'desc';
            } else if (this.filterObj.orderBy === 'desc') {
                this.filterObj.sortBy = 'name';
                this.filterObj.orderBy = 'asc';
            } else {
                this.filterObj.orderBy = 'asc';
            }
        } else {
            this.filterObj.orderBy = 'asc';
            this.filterObj.sortBy = sortBy;
        }

        this.lockersService.getLockers(this.filterObj, true)
            .then((res: Array<SearchResultsWithMetadata<Locker>>) => {
                // Iterate over first array of objects
                this.lockersArr = _.map(res[0].data, (obj) => {
                    // add the properties from second array matching the _id
                    // to the object from first array and return the updated object
                    return _.assign(obj, _.find(this.lockersArr, { _id: obj._id }));
                });
            });
    }

    /**
     * Get companies
     * */
    fnGetCompanies() {
        this.companiesService.fnGetCompanies({limitData: true})
            .then((companiesArr: Array<Company>) => {
                this.companiesArr = companiesArr;
                this.companiesArr.unshift({ _id: '', name: 'Select Company' });
                if (this.currentUser.role === Constant.ROLE_ADMIN) {
                    this.fnGetLockers(this.filterObj);
                } else {
                    this.filterObj.companyId = this.currentUser.companyId;
                    this.fnGetLockers(this.filterObj);
                }
            });
    }

    /**
     * Get list of lockers
     * @param {object} params
     * */
    fnGetLockers(params?: object) {
        this.isDataLoaded = false;
        this.isAllCabinets = false;
        this.lockersService.getLockers(params, true)
            .then((res: Array<SearchResultsWithMetadata<Locker>>) => {
                this.isDataLoaded = true;
                this.totalLockers = res.length && res[0].metadata.length ? res[0].metadata[0].total : 0;
                this.lockersArr = res[0].data.map((obj: any) => {
                    obj.isAllSlots = false;
                    obj.isCabinets = false;
                    obj.totalSlots = 0;
                    obj.cabinets = _.map(obj.cabinets, (cabinetObj: any) => {
                        cabinetObj.totAvailableSlots = _.filter(cabinetObj.slots, (slotObj) => {
                            return slotObj.status === Constant.SLOT_STATUS_AVAILABLE;
                        }).length;
                        cabinetObj.isSlots = false;
                        obj.totalSlots += cabinetObj.slots.length;
                        return cabinetObj;
                    });
                    if (!obj.accessCodes) {
                        obj.accessCodes = [];
                    }
                    if (obj.accessCodes.length < this.CONSTANT.MAX_ACCESS_CODES_PER_LOCKER) {
                        for (let startIndex = obj.accessCodes.length; startIndex < this.CONSTANT.MAX_ACCESS_CODES_PER_LOCKER; startIndex++) {
                            obj.accessCodes.push('');
                        }
                    }
                    if(!obj.returnPickupCode){
                        obj.returnPickupCode = '';
                    }
                    obj.hasAccessCodes = false;
                    obj.hasReturnPickupCode = false;
                    return obj;
               });
            })
            .catch(() => {
                this.toastr.fnError('Something went wrong. Please try again later.');
            });
    }

    /**
     * Delete locker
     * @param {string} id
     * */
    fnDeleteLocker(id: string) {
        const modal = this._bsModalService.show(DeleteModalComponent, { 'class': 'modal-sm' });
        (<DeleteModalComponent>modal.content).showConfirmationModal(
            'Delete',
            'Are you sure you want to delete this locker?'
        );

        (<DeleteModalComponent>modal.content).onClose.subscribe(result => {
            if (result) {
                this.lockersService.fnDeleteLocker(id)
                    .then(() => {
                        _.remove(this.lockersArr, function (locker) {
                            return locker._id === id;
                        });
                        this.toastr.fnSuccess('Locker deleted successfully.');
                    })
                    .catch((error) => {
                        this.toastr.fnError(error.message);
                    });
            }
        });
    }


    /**
     * Expand/Collapse all cabinets table.
     * */
    fnToggleAllCabinets() {
        this.isAllCabinets = !this.isAllCabinets;
        this.lockersArr = this.lockersArr.map((obj: any) => {
            obj.isCabinets = this.isAllCabinets;
            obj.hasAccessCodes = this.isAllCabinets;
            obj.hasReturnPickupCode = this.isAllCabinets;
            return obj;
        });
    }

    /**
     * Expand/Collapse cabinets table.
     * @param {object} lockerObj
     * */
    fnToggleCabinets(lockerObj: any) {
        lockerObj.isCabinets = !lockerObj.isCabinets;
        lockerObj.hasAccessCodes = !lockerObj.hasAccessCodes;
        lockerObj.hasReturnPickupCode = !lockerObj.hasReturnPickupCode;
        // Check if all true
        this.isAllCabinets = !!this.lockersArr.map((obj: any) => obj.isCabinets).reduce((a: any, b: any) => {
            return (a === b) ? a : NaN;
        });
    }

    /**
     * Delete cabinet
     * @param {string} id
     * @param {object} cabinetsArr
     * */
    deleteCabinet(id: string, cabinetsArr: any) {
        const modal = this._bsModalService.show(DeleteModalComponent, { 'class': 'modal-sm' });
        (<DeleteModalComponent>modal.content).showConfirmationModal(
            'Delete',
            'Are you sure you want to delete this cabinet?'
        );

        (<DeleteModalComponent>modal.content).onClose.subscribe(result => {
            if (result) {
                this.cabinetsService.fnDeleteCabinet(id)
                    .then(() => {
                        _.remove(cabinetsArr, function (cabinet: Cabinet) {
                            return cabinet._id === id;
                        });
                        this.toastr.fnSuccess('Cabinet deleted successfully');
                    }).catch(error => {
                        this.toastr.fnError(error.message);
                });
            }
        });
    }

    /**
     * Expand/Collapse all slots table.
     * @param {object} lockerObj
     * */
    fnToggleAllSlots(lockerObj: any) {
        lockerObj.isAllSlots = !lockerObj.isAllSlots;
        lockerObj.cabinets = lockerObj.cabinets.map((obj: any) => {
            obj.isSlots = lockerObj.isAllSlots;
            return obj;
        });
    }

    /**
     * Expand/Collapse slots table.
     * @param {object} lockerObj
     * @param {object} cabinetObj
     * */
    fnToggleSlots(lockerObj: any, cabinetObj: any) {
        cabinetObj.isSlots = !cabinetObj.isSlots;
        // Check if all true
        lockerObj.isAllSlots = !!lockerObj.cabinets.map((obj: any) => obj.isSlots).reduce((a: any, b: any) => {
            return (a === b) ? a : NaN;
        });
    }

    /**
     * Delete slot
     * @param {string} id
     * @param {object} slotsArr
     * */
    deleteSlot(id: string, slotsArr: Array<Slot>) {
        const modal = this._bsModalService.show(DeleteModalComponent, { 'class': 'modal-sm' });
        (<DeleteModalComponent>modal.content).showConfirmationModal(
            'Delete',
            'Are you sure you want to delete this slot?'
        );

        (<DeleteModalComponent>modal.content).onClose.subscribe(async result => {
            if (result) {
                try {
                    await this.slotsService.fnDeleteSlot(id);
                    this.toastr.fnSuccess('Slot deleted successfully');
                    const deleteIndex = slotsArr.findIndex(slot => slot._id === id);
                    slotsArr.splice(deleteIndex, 1);
                } catch (error) {
                    this.toastr.fnError(error.message);
                }
            }
        });
    }

    fnUpdateAutoUpdate(lockerObj) {
        const updateObj = {
            isAutoUpdate: lockerObj.isAutoUpdate
        };
        this.isDataLoaded = false;
        this.lockersService.fnUpdateLockerAutoUpdate(lockerObj._id, updateObj)
            .then(locker => {
                this.toastr.fnSuccess('Locker updated successfully.');
                this.isDataLoaded = true;
            })
            .catch(error => {
                this.toastr.fnError(error.message);
                this.isDataLoaded = true;
            });
    }

    /**
     * Change pagination
     * @param {any} event
     * */
    fnPageChanged(event: any) {
        this.filterObj.page = event.page;
        this.fnGetLockers(this.filterObj);
    }

    fnExportCSV() {
        this.isExporting = true;
        this.lockersService.getLockers({ page: 1, limit: 10000 }, true)
            .then((res: Array<SearchResultsWithMetadata<Locker>>) => {
                const lockers = res[0].data.map((obj: any) => {
                    obj.isAllSlots = false;
                    obj.isCabinets = false;
                    obj.totalSlots = 0;
                    obj.cabinets = _.map(obj.cabinets, (cabinetObj: any) => {
                        cabinetObj.totAvailableSlots = _.filter(cabinetObj.slots, (slotObj) => {
                            return slotObj.status === Constant.SLOT_STATUS_AVAILABLE;
                        }).length;
                        cabinetObj.isSlots = false;
                        obj.totalSlots += cabinetObj.slots.length;
                        return cabinetObj;
                    });
                    return obj;
                });
                const csvArr = [];
                _.forEach(lockers, element => {
                    element.totalCabinets = element.cabinets.length;
                    csvArr.push(element);
                });
                const headers = ['companyId.name', 'lockerNumber', 'name',
                    'locationId.country', 'locationId.postalCode', 'isOnline',
                    'isMultiBuilding', 'totalCabinets', 'totalSlots', 'updatedAt',
                    'createdAt'];
                this.exportService.fnDownloadCSV('Lockers', csvArr, headers);
                this.isExporting = false;
            });
    }

    toggleAccessCodeSaveBtn(lockerObj: any, index: number) {
        if (this.toggleReturnPickup) {
            this.cancelReturnPickupCode();
        }
        if (this.selectedLocker) {
            this.cancelAccessCode();
        }
        if (this.toggleProductReturnPickup) {
            this.cancelProductReturnPickupCode();
        }
        this.selectedLocker = lockerObj;
        this.preservedCode = lockerObj.accessCodes[index];
        this.selectedAccessCodeIndex = index;
    }

    toggleReturnPickupCodeBtn(lockerObj: Locker) {
        if (this.selectedAccessCodeIndex > -1) {
            this.cancelAccessCode();
        }
        if (this.toggleReturnPickup) {
            this.cancelReturnPickupCode();
        }
        if (this.toggleProductReturnPickup) {
            this.cancelProductReturnPickupCode();
        }
        this.selectedLocker = lockerObj;
        this.preservedCode = lockerObj.returnPickupCode;
        this.toggleReturnPickup = !this.toggleReturnPickup;
    }

    toggleProductReturnPickupCode = (lockerObj: Locker) => {
        if (this.selectedAccessCodeIndex > -1) {
            this.cancelAccessCode();
        }
        if (this.toggleReturnPickup) {
            this.cancelReturnPickupCode();
        }
        if (this.toggleProductReturnPickup) {
            this.cancelProductReturnPickupCode();
        }
        this.selectedLocker = lockerObj;
        this.preservedCode = lockerObj.productReturnPickupCode;
        this.toggleProductReturnPickup = !this.toggleProductReturnPickup;
    }

    cancelAccessCode() {
        this.selectedLocker.accessCodes[this.selectedAccessCodeIndex] = this.preservedCode;
        this.selectedAccessCodeIndex = -1;
    }

    async saveAccessCodes(lockerObj: Locker, index: number) {
        try {
            const accessCodes = lockerObj.accessCodes.filter(code => {
                return code && code.length > 0;
            });
            if (lockerObj.accessCodes[index] !== this.preservedCode) {
                const response = await this.lockersService.setCodes(lockerObj._id, LOCKER_CODES_TYPE.ACCESS_CODES, accessCodes);
                this.toastr.fnSuccess(response['message']);
            }
            this.selectedAccessCodeIndex = -1;
            this.selectedLocker = null;
        } catch (err) {
            console.error('Error saveAccessCodes():', err.error.message);
            this.toastr.fnError(err.error.message);
        }
    }

    trackByFn(index: any, item: any) {
        return index;
    }

    cancelReturnPickupCode() {
        this.selectedLocker.returnPickupCode = this.preservedCode;
        this.toggleReturnPickup = !this.toggleReturnPickup;
    }

    cancelProductReturnPickupCode() {
        this.selectedLocker.productReturnPickupCode = this.preservedCode;
        this.toggleProductReturnPickup = !this.toggleProductReturnPickup;
    }

    async saveReturnPickupCode(lockerObj: Locker) {
        try {
            const returnPickupCode = lockerObj.returnPickupCode;
            if (this.preservedCode !== lockerObj.returnPickupCode) {
                const response = await this.lockersService.setCodes(lockerObj._id, LOCKER_CODES_TYPE.RETURN_PICKUP_CODE, returnPickupCode);
                this.toastr.fnSuccess(response['message']);
            }
            this.toggleReturnPickup = false;
            this.selectedLocker = null;
        } catch (err) {
            this.toastr.fnError(err.error.message);
        }
    }

    async saveProductReturnPickupCode(lockerObj: Locker) {
        try {
            const productReturnPickupCode = lockerObj.productReturnPickupCode;
            if (this.preservedCode !== lockerObj.returnPickupCode) {
                const response = await this.lockersService.setCodes(lockerObj._id, LOCKER_CODES_TYPE.PRODUCT_RETURN_PICKUP_CODE, productReturnPickupCode);
                this.toastr.fnSuccess(response['message']);
            }
            this.toggleProductReturnPickup = false;
            this.selectedLocker = null;
        } catch (err) {
            this.toastr.fnError(err.error.message);
        }
    }

    async showStatus(template, slotNum, cabinetNumber, lockerId) {
        try {
            let isOnline = await this.checkLockerOnline(lockerId);
            if (!isOnline && window.location.host === Constant.PROD_1_API_URL) {
                this.socketIOService.closeConnection();
                this.socketIOService.connectSocket(Constant.PROD_2_API_URL);
                setTimeout(async () => {
                    isOnline = await this.checkLockerOnline(lockerId);
                }, 1000);
                if (!isOnline) {
                    return this.toastr.fnError('Locker is Offline');
                }
            } else if (!isOnline) {
                return this.toastr.fnError('Locker is Offline');
            }
            this.slotStatus = false;
            this.slotDetails = {
                boxId: cabinetNumber + slotNum,
                lockerId: lockerId,
            };

            if (!lockerId) {
                this.toastr.fnError('LockerId is required');
                return;
            }
            if (!(/[A-Z][0-9]{2}$/).test(this.slotDetails.boxId)) {
                this.toastr.fnError('Slot number with cabinet number is required');
                return;
            }
            this.socketIOService.emitEvent('fetch_status', this.slotDetails);

            let isResponseReceived = false;
            const eventResponseTimeout = setTimeout(() => {
                if (!isResponseReceived) {
                    this.toastr.fnError('Querying slot status is not supported.');
                }
            }, 3000);
            this.slotResponse = this.socketIOService.listenEvent('receive_status').subscribe((status) => {
                clearTimeout(eventResponseTimeout);
                isResponseReceived = true;

                if (typeof status.slotStatus === 'string' && status.slotStatus.indexOf('offline') !== -1) {
                    this.toastr.fnError(status.slotStatus);
                } else {
                    this.slotStatusModelRef = this._bsModalService.show(template, {'class': 'modal-sm'});
                    this.slotStatus = true;
                    this.slotStatusResp = {
                        isOpened: status.slotStatus.open,
                        isStorage: status.slotStatus.occupied
                    };
                }
            });
        } catch (error) {
            this.toastr.fnError(error);
        }
    }

    closeStatusModal() {
        this.slotStatusModelRef.hide();
        this.slotResponse.unsubscribe();
        this.slotDetails = { boxId: '', lockerId: ''};
        this.slotStatusResp = {
            isOpened: '',
            isStorage: ''
        };
    }

    checkLockerOnline(lockerId) {
        this.socketIOService.emitEvent('locker_status', {lockerId: lockerId});
        return new Promise((resolve, reject) => {
            this.socketIOService.listenEvent('receive_locker_status').subscribe((data) => {
                resolve(data);
            });
        });
    }
}
