<fieldset class="col-md-12 fieldset">
    <legend class="fieldset-legend">Location</legend>

    <div class="form-group"
         [ngClass]="{'has-error': (form.submitted || location.touched) && !location.valid}">
        <label for="location">Search Location<span class="text-danger">*</span></label>
        <input type="text" id="location" class="form-control" name="location"
               [(ngModel)]="locationId.formattedAddress" [disabled]="isReadOnly"
               [placeholder]="placeholderText ? placeholderText : 'Enter an address'" #location="ngModel" required/>
        <div *ngIf="(form.submitted || location.touched) && !location.valid">
            <small class="text-danger" *ngIf="location.errors?.required">
                Location is required.
            </small>
        </div>
    </div>
    <div class="form-group">
        <label for="floor">Floor</label>
        <input id="floor" type="text" class="form-control" name="floor"
               [(ngModel)]="locationId.floor"
               placeholder="Enter a floor"/>
    </div>
    <div class="form-group">
        <label for="department">Department</label>
        <input id="department" type="text" class="form-control" name="department"
               [(ngModel)]="locationId.department"
               placeholder="Enter a department"/>
    </div>
    <div class="form-group">
        <label for="street">Street</label>
        <div id="street" class="row">
            <div class="col-sm-3">
                <input class="form-control" name="streetNumber"
                       [(ngModel)]="locationId.streetNumber"
                       placeholder="Enter a street no."
                       [disabled]="true" [readonly]="true"/>
            </div>
            <div class="col-sm-9">
                <input class="form-control" name="route"
                       [(ngModel)]="locationId.route"
                       placeholder="Enter a street route"
                       [disabled]="true" [readonly]="true"/>
            </div>
        </div>
    </div>
    <div class="form-group">
        <label for="city">City</label>
        <input id="city" type="text" class="form-control" name="city"
               [(ngModel)]="locationId.city"
               placeholder="Enter a city"
               [disabled]="true" [readonly]="true"/>
    </div>
    <div class="form-group">
        <label for="state">State</label>
        <input id="state" type="text" class="form-control" name="state"
               [(ngModel)]="locationId.state"
               placeholder="Enter a state"
               [disabled]="true" [readonly]="true"/>

    </div>
    <div class="form-group">
        <label for="country">Country</label>
        <input id="country" type="text" class="form-control" name="country"
               [(ngModel)]="locationId.country"
               placeholder="Enter a country"
               [disabled]="true" [readonly]="true"/>
    </div>
    <div class="form-group">
        <label for="postalCode">Postal Code</label>
        <input id="postalCode" type="text" class="form-control" name="postalCode"
               [(ngModel)]="locationId.postalCode" placeholder="Enter a postal code"
               [disabled]="true" [readonly]="true"/>
    </div>
    <div class="form-group">
        <label for="latitudeLongitude">Latitude & Longitude</label>
        <div id="latitudeLongitude" class="row">
            <div class="col-sm-6">
                <input class="form-control" name="latitude"
                       [(ngModel)]="locationId.latitude"
                       placeholder="Enter a latitude"
                       [disabled]="true" [readonly]="true"/>
            </div>
            <div class="col-sm-6">
                <input class="form-control" name="=longitude"
                       [(ngModel)]="locationId.longitude"
                       placeholder="Enter a longitude"
                       [disabled]="true" [readonly]="true"/>
            </div>
        </div>
    </div>
</fieldset>
