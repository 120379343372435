import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../common/auth.service';
import {UsersService} from '../users/users.service';
import {User} from '../users/user';
import {ToastrService} from '../../common/toastr.service';
import {Constant} from '../../common/constant';

@Component({
    selector: 'pp-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
    isReadOnly: boolean;
    isDataLoaded: boolean;
    currentUser: any;
    readonly CONSTANT = Constant;
    user: User;
    constructor(
        private auth: AuthService,
        private userService: UsersService,
        private toastr: ToastrService
    ) {
        this.isDataLoaded = false;
        this.isReadOnly = true;
        this.currentUser = this.auth.fnGetCurrentUser();
    }

    async ngOnInit() {
        await this.currentUser;
        if (this.currentUser && this.currentUser._id) {
            this.user = await this.userService.fnGetUser(this.currentUser._id);
            this.isDataLoaded = true;
        }
    }

    async updateUser(user) {
        try {
            await this.userService.updateUser(user);
            this.isReadOnly = true;
            this.toastr.fnSuccess('Profile updated successfully.');
        } catch (e) {
            console.error('Error in updating profile ===> ', e);
            this.toastr.fnError(e.message);
        }
    }

    async resetUserForm() {
        this.user = await this.userService.fnGetUser(this.currentUser._id);
        this.isReadOnly = true;
    }
}
