import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

import {Constant} from '../../common/constant';
import {User} from './user';
import {USER_STATUS_ENUM} from '../../common/common_enums';

@Injectable()
export class UsersService {

    constructor(private http: HttpClient) {
    }

    /**
     * Get list of users
     * @param {object} params
     * */
    fnGetUsers(params?: object) {
        let urlSearchParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key) && params[key]) {
                if (key === 'email') {
                    urlSearchParams = urlSearchParams.append(key, encodeURIComponent(params[key]));
                } else {
                    urlSearchParams = urlSearchParams.append(key, params[key]);
                }
            }
        }
        const qString = urlSearchParams.toString();
        return new Promise((resolve, reject) => {
            this.http
                .get(Constant.API_URL + 'api/users' + (qString ? '?' + qString : ''))
                .subscribe((response: User) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Get user
     * @param {string} id
     * */
    fnGetUser(id: string) {
        return new Promise((resolve, reject) => {
            this.http
                .get(Constant.API_URL + 'api/users/' + id)
                .subscribe((response: User) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Create new user
     * @param {object} userObj
     * */
    fnCreateUser(userObj: User) {
        return new Promise((resolve, reject) => {
            this.http
                .post(Constant.API_URL + 'api/users', userObj)
                .subscribe((response: User) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Update user
     * @param {object} userObj
     * */
    fnUpdateUser(userObj: User) {
        return new Promise((resolve, reject) => {
            this.http
                .put(Constant.API_URL + 'api/users/' + userObj._id, userObj)
                .subscribe((response: User) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Update user
     * @param {User} user
     * */
    updateUser(user: User) {
        return new Promise((resolve, reject) => {
            this.http
                .put(Constant.API_URL + 'api/user/' + user._id + '/profile', user)
                .subscribe((response: User) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }
    /**
     * Update users by plan
     * @param {object} userObj
     * */
    fnUpdateUsersPlan(planId, ids) {
        const obj = {
            newPlanId: planId,
            userIds: ids
        };
        return new Promise((resolve, reject) => {
            this.http
                .post(Constant.API_URL + 'api/users/update-users-plan', obj)
                .subscribe((response: any) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }
    /**
     * Delete user
     * @param {string} id
     **/
    fnDeleteUser(id: string) {
        return new Promise((resolve, reject) => {
            this.http
                .delete(Constant.API_URL + 'api/users/' + id)
                .subscribe((response) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }
    /**
     * Update users plan
     * @param {object} plansObj
     * */
    fnChangeUsersPlans(plansObj?: any) {
        return new Promise((resolve, reject) => {
            this.http
                .put(Constant.API_URL + 'api/users/payment-plan/' + plansObj.currentPlan._id, plansObj)
                .subscribe((response: any) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Reset password by user id
     * @param {string} id
     * @param {string} password
     * @param {string} token
     **/
    resetPassword(id: string, password?: string, token?: string) {
        let body = {};
        let options = {};
        if (password) {
            body = {password: password};
        }
        if (token) {
            options = {headers: new HttpHeaders({'Authorization': `Bearer ${token}`})};
        }
        return new Promise((resolve, reject) => {
            this.http
                .put(Constant.API_URL + 'api/user/' + id + '/reset-password', body, options)
                .subscribe((response) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * update user status by user id
     * @param {string} userId
     * @param {USER_STATUS_ENUM} newStatus
     **/
    updateUserStatus(userId: string, newStatus: USER_STATUS_ENUM) {
        const bodyObj = { status: newStatus };
        return new Promise((resolve, reject) => {
            this.http
                .put(Constant.API_URL + 'api/user/' + userId + '/status', bodyObj)
                .subscribe((response: any) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    generatePasswordResetLink(verificationData) {
        const obj = {
            email: verificationData.email,
            token: verificationData.tokenRes
        };
        return new Promise((resolve, reject) => {
            this.http
                .post(Constant.API_URL + 'api/user/reset-password', obj)
                .subscribe((response: any) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }
}
