import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {SignInComponent} from './sign-in/sign-in.component';
import {VerifyOTPComponent} from './verify-otp/verify-otp.component';
import {AuthenticateComponent} from './authenticate/authenticate.component';
import {ShipmentsComponent} from './authenticate/shipments/shipments.component';
import {ShipmentComponent} from './authenticate/shipments/shipment/shipment.component';
import {UsersComponent} from './authenticate/users/users.component';
import {UserComponent} from './authenticate/users/user/user.component';
import {CompaniesComponent} from './authenticate/companies/companies.component';
import {CompanyComponent} from './authenticate/companies/company/company.component';
import {LockersComponent} from './authenticate/lockers/lockers.component';
import {LockerLogsComponent} from './authenticate/lockers/locker-logs/locker-logs.component';
import {LockerComponent} from './authenticate/lockers/locker/locker.component';
import {CabinetsComponent} from './authenticate/cabinets/cabinets.component';
import {CabinetComponent} from './authenticate/cabinets/cabinet/cabinet.component';
import {SlotsComponent} from './authenticate/slots/slots.component';
import {SlotComponent} from './authenticate/slots/slot/slot.component';
import {ManageAPKComponent} from './authenticate/manage-apk/manage-apk.component';
import {UploadAPKComponent} from './authenticate/manage-apk/upload-apk/upload-apk.component';
import {TransactionsComponent} from './authenticate/transactions/transactions.component';
import {FeedbackComponent} from './authenticate/feedback/feedback.component';
import {AuthenticateGuard} from './common/authenticate.guard';
import {NotAuthenticateGuard} from './common/not.authenticate.guard';
import {BuildingDataComponent} from './authenticate/building-data/building-data.component';
import {AnalyticsComponent} from './authenticate/analytics/analytics.component';
import {NotificationsComponent} from './authenticate/notifications/notifications.component';
import {DashboardComponent} from './authenticate/dashboard/dashboard.component';
import {ActivationStatusComponent} from './activation-status/activation-status.component';
import {SettingsComponent} from './authenticate/settings/settings.component';
import {SupportComponent} from './authenticate/support/support.component';
import {PaymentPlansComponent} from './authenticate/payment-plans/payment-plans.component';
import {PaymentPlanComponent} from './authenticate/payment-plans/payment-plan/payment-plan.component';
import {ProfileComponent} from './profile/profile.component';
import {EnrollmentUserComponent} from './authenticate/company-admin/enrollment-user/enrollment-user.component';
import {CompanyLockersComponent} from './authenticate/company-admin/company-lockers/company-lockers.component';
import {PaymentDetailsComponent} from './authenticate/payment-details/payment-details.component';
import {UserProfileComponent} from './authenticate/user-profile/user-profile.component';
import {LockerDirectoryComponent} from './authenticate/locker-directory/locker-directory.component';
import {UserShipmentsComponent} from './authenticate/user-shipments/user-shipments.component';
import {PendingShipmentsComponent} from './authenticate/pending-shipments/pending-shipments.component';
import {ResetPasswordVerificationComponent} from './reset-password-verification/reset-password-verification.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import {PendingShipmentComponent} from './authenticate/pending-shipments/pending-shipment/pending-shipment.component';
import {OutboundShipmentComponent} from './authenticate/outbound-shipment/outbound-shipment.component';

const appRoutes: Routes = [
    {path: '', redirectTo: '/dashboard', pathMatch: 'full'},
    {
        path: '', component: AuthenticateComponent, canActivate: [AuthenticateGuard],
        children: [
            {path: 'dashboard', component: DashboardComponent, canActivate: [AuthenticateGuard]},
            {path: 'companies', component: CompaniesComponent, canActivate: [AuthenticateGuard]},
            {path: 'companies/:id', component: CompanyComponent, canActivate: [AuthenticateGuard]},
            {path: 'lockers', component: LockersComponent, canActivate: [AuthenticateGuard]},
            {path: 'company-lockers', component: CompanyLockersComponent, canActivate: [AuthenticateGuard]},
            {path: 'locker-logs/:id', component: LockerLogsComponent, canActivate: [AuthenticateGuard]},
            {path: 'lockers/:id', component: LockerComponent, canActivate: [AuthenticateGuard]},
            {path: 'cabinets', component: CabinetsComponent, canActivate: [AuthenticateGuard]},
            {path: 'cabinets/:id', component: CabinetComponent, canActivate: [AuthenticateGuard]},
            {path: 'slots', component: SlotsComponent, canActivate: [AuthenticateGuard]},
            {path: 'slots/:id', component: SlotComponent, canActivate: [AuthenticateGuard]},
            {path: 'shipments', component: ShipmentsComponent, canActivate: [AuthenticateGuard]},
            {path: 'notifications', component: NotificationsComponent, canActivate: [AuthenticateGuard]},
            {path: 'shipments/:id', component: ShipmentComponent, canActivate: [AuthenticateGuard]},
            {path: 'enrollment-user', component: EnrollmentUserComponent, canActivate: [AuthenticateGuard]},
            {path: 'users', component: UsersComponent, canActivate: [AuthenticateGuard]},
            {path: 'users/:id', component: UserComponent, canActivate: [AuthenticateGuard]},
            {path: 'locker-directory', component: LockerDirectoryComponent, canActivate: [AuthenticateGuard]},
            {path: 'shipment-history', component: UserShipmentsComponent, canActivate: [AuthenticateGuard]},
            {path: 'pending-shipments', component: PendingShipmentsComponent, canActivate: [AuthenticateGuard]},
            {path: 'manage-apk', component: ManageAPKComponent, canActivate: [AuthenticateGuard]},
            {path: 'manage-apk/:id', component: UploadAPKComponent, canActivate: [AuthenticateGuard]},
            {path: 'transactions', component: TransactionsComponent, canActivate: [AuthenticateGuard]},
            {path: 'feedback', component: FeedbackComponent, canActivate: [AuthenticateGuard]},
            {path: 'building-data', component: BuildingDataComponent, canActivate: [AuthenticateGuard]},
            {path: 'analytics', component: AnalyticsComponent, canActivate: [AuthenticateGuard]},
            {path: 'settings', component: SettingsComponent, canActivate: [AuthenticateGuard]},
            {path: 'support', component: SupportComponent, canActivate: [AuthenticateGuard]},
            {path: 'payment-plans', component: PaymentPlansComponent, canActivate: [AuthenticateGuard]},
            {path: 'payment-plan/:id', component: PaymentPlanComponent, canActivate: [AuthenticateGuard]},
            {path: 'payment-details', component: PaymentDetailsComponent, canActivate: [AuthenticateGuard]},
            {path: 'my-profile', component: UserProfileComponent, canActivate: [AuthenticateGuard]},
            {path: 'pending-shipments/:id', component: PendingShipmentComponent, canActivate: [AuthenticateGuard]},
            {path: 'outbound-shipments', component: OutboundShipmentComponent, canActivate: [AuthenticateGuard]},
        ]
    },
    {path: 'sign-in', component: SignInComponent, canActivate: [NotAuthenticateGuard]},
    {path: 'verify-otp', component: VerifyOTPComponent, canActivate: [AuthenticateGuard]},
    {path: 'profile/:userId', component: ProfileComponent, canActivate: [NotAuthenticateGuard]},
    {path: 'activate/:code', component: ActivationStatusComponent, canActivate: [NotAuthenticateGuard]},
    {path: 'reset-password-verification', component: ResetPasswordVerificationComponent, canActivate: [NotAuthenticateGuard]},
    {path: 'reset-password/:code', component: ResetPasswordComponent, canActivate: [NotAuthenticateGuard]},
    {path: '**', redirectTo: '/sign-in', pathMatch: 'full'},
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, {useHash: true})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
