import { Component, OnInit } from '@angular/core';
import {User} from '../users/user';
import {AuthService} from '../../common/auth.service';
import { Constant } from '../../common/constant';
import {Locker} from '../lockers/locker';
import {LockersService} from '../lockers/lockers.service';
import {CompaniesService} from '../companies/companies.service';
import {Company} from '../companies/company';
import {USER_STATUS_ENUM} from '../../common/common_enums';
import {ActivatedRoute, Router} from '@angular/router';
import * as  _ from 'lodash';
import {StorageService} from '../../common/storage.service';
import {DeleteModalComponent} from '../../delete-modal/delete-modal.component';
import { BsModalService} from 'ngx-bootstrap/modal';
import {UsersService} from '../users/users.service';
import {Observable, mergeMap} from 'rxjs';
import {ToastrService} from '../../common/toastr.service';

@Component({
  selector: 'pp-locker-directory',
  templateUrl: './locker-directory.component.html',
  styleUrls: ['./locker-directory.component.css']
})
export class LockerDirectoryComponent implements OnInit {
    selectedLocker: string;
    selectedCompany: string;
    filterObj: any;
    isDataLoaded: boolean;
    typeAheadLoading: boolean;
    unitNumberSearch: string;
    selectedUser: any;
    public currentUser: User;
    readonly CONSTANT = Constant;
    readonly USER_STATUS = USER_STATUS_ENUM;
    public lockersArr: Array<Locker> = [];
    public companiesArr: Array<Company> = [];
    public userArray: Array<User> = [];
    public totUsers: number;
    dataSource: Observable<any>;
    private LOCKER_DIRECTORY_FILTER = 'lockerDirectoryFilter';

    constructor(private auth: AuthService, private lockersService: LockersService,
                private companiesService: CompaniesService, private router: Router,
                private storage: StorageService, private activatedRoute: ActivatedRoute,
                private _bsModalService: BsModalService,  private usersService: UsersService,
                private toastr: ToastrService) {
        this.filterObj = { sortBy: '', orderBy: '', page: 0, limit: 0, companyId: '', lockerId: '' };
        this.currentUser = this.auth.fnGetCurrentUser();
        this.isDataLoaded = false;
        this.mergeQueryParams();
    }

    ngOnInit() {
        this.setDefaultData();
    }

    setDefaultData() {
        this.filterObj.sortBy = 'unit';
        this.filterObj.orderBy = 'asc';
        this.filterObj.page = 1;
        this.filterObj.limit = 10;
        this.filterObj.searchUser = this.selectedUser = '';
        this.filterObj.unit = this.unitNumberSearch = '';
        this.getCompanies();

        this.dataSource = new Observable((observer: any) => {
            observer.next(this.selectedUser);
        }).pipe(mergeMap((token: string) => this.filterResults(token)));
    }

    /**
     * Refresh data
     * */
    async refreshData() {
        this.storage.fnRemove(this.LOCKER_DIRECTORY_FILTER);
        await this.setDefaultData();
    }

    fnOnUnitNumberInput() {
        this.unitNumberSearch ? this.filterObj.unit = this.unitNumberSearch.trim() : delete this.filterObj.unit;
        this.filterObj.page = 1;
        this.getUsers(this.selectedLocker, this.filterObj);
    }

    filterResults(token: string) {
        return this.usersService.fnGetUsers({companyId: this.selectedCompany, preferredLockerId: this.selectedLocker, name: token});
    }

    onUserSearch(): void {
        this.filterObj.searchUser = this.selectedUser =  this.selectedUser.trim();
        this.getUsers(this.selectedLocker, this.filterObj);
    }

    /**
     * Get companies
     * */
    async getCompanies(): Promise<void> {
        this.isDataLoaded = false;
        try {
            switch (this.currentUser.role) {
                case this.CONSTANT.ROLE_ADMIN:
                    const companiesRes: any = await this.companiesService.fnGetCompanies({limitData: true});
                    this.companiesArr = companiesRes;
                    if (!this.selectedCompany) {
                        this.selectedCompany = this.companiesArr[0]._id;
                    }
                    this.getLockers(this.selectedCompany);
                    break;
                case this.CONSTANT.ROLE_COMPANY_ADMIN:
                    this.selectedCompany = this.currentUser.companyId;
                    await this.getLockers(this.selectedCompany);
                    break;
            }
        } catch (e) {
            console.error('LockerDirectoryComponent: getCompanies(): error', e);
            this.isDataLoaded = true;
        }
    }

    /**
     * Get list of lockers
     * */
    async getLockers(companyId: string): Promise<void> {
        this.isDataLoaded = false;
        try {
            this.lockersArr = <Array<Locker>> await this.lockersService.getLockersNameAndNumber(companyId);
            if (this.lockersArr.length) {
                if (!this.selectedLocker) {
                    this.selectedLocker = this.lockersArr[0]._id;
                }
                this.filterObj.lockerId = this.selectedLocker;
                this.filterObj.companyId = companyId;
            }
            this.isDataLoaded = true;
            this.getUsers(this.selectedLocker, this.filterObj);
        } catch (e) {
            console.error('LockerDirectoryComponent: getLockers(): error', e);
            this.isDataLoaded = true;
        }
    }

    /**
     * Get all the users by lockerId
     * */
    async getUsers(lockerId: string, params?: object): Promise<void> {
        if (params) {
            this.storage.fnStoreData(this.LOCKER_DIRECTORY_FILTER, params);
        }
        this.isDataLoaded = false;
        try {
            let res: any;
            if (params) {
                res = await this.lockersService.getUsersByLocker(lockerId, params);
            } else {
                res = await this.lockersService.getUsersByLocker(lockerId);
            }
            this.isDataLoaded = true;
            this.userArray = res.data;
            this.totUsers = res.total;
        } catch (error) {
            console.error('LockerDirectoryComponent: getUsers(): error', error);
            this.isDataLoaded = true;
            if (error && error.message) {
                this.toastr.fnError(error.message);
            } else {
                this.toastr.fnError('Facing difficulties in fetching data');
            }
        }
    }

    /**
     * Set column sort icon
     * @param {string} sortBy
     * */
    setSortIcon(sortBy: string) {
        if (this.filterObj.sortBy === sortBy) {
            return this.filterObj.orderBy === 'desc' ? 'fa fa-sort-desc' : 'fa fa-sort-asc';
        }
        return 'fa fa-sort';
    }

    /**
     * Sort by column name
     * @param {string} sortBy
     * */
    async sort(sortBy: string) {
        if (this.filterObj.sortBy === sortBy) {
            this.filterObj.sortBy = sortBy;
            if (this.filterObj.orderBy === 'asc') {
                this.filterObj.orderBy = 'desc';
            } else if (this.filterObj.orderBy === 'desc') {
                this.filterObj.sortBy = 'unit';
                this.filterObj.orderBy = 'asc';
            } else {
                this.filterObj.orderBy = 'asc';
            }
        } else {
            this.filterObj.orderBy = 'asc';
            this.filterObj.sortBy = sortBy;
        }

        await this.getUsers(this.selectedLocker, this.filterObj);
    }

    /**
     * Merge local storage filter object into query params.
     * */
    mergeQueryParams() {
        const storedFilterObj = this.storage.fnGetData(this.LOCKER_DIRECTORY_FILTER);
        if (!_.isEmpty(storedFilterObj)) {
            for (const key in storedFilterObj) {
                if (storedFilterObj.hasOwnProperty(key)) {
                    if (key === 'companyId') {
                        this.filterObj[key] = storedFilterObj[key];
                        this.selectedCompany = storedFilterObj[key];
                    }
                    if (key === 'lockerId') {
                        this.filterObj[key] = storedFilterObj[key];
                        this.selectedLocker = storedFilterObj[key];
                    }
                    this.filterObj[key] = storedFilterObj[key];
                }
            }
        } else {
            this.filterObj.sortBy = 'unit';
            this.filterObj.orderBy = 'asc';
            this.filterObj.page = 1;
            this.filterObj.limit = 10;
        }
        if (this.filterObj.userId === this.currentUser._id) {
            this.filterObj.companyId = '';
        }
        if (!this.selectedUser)  {
            this.filterObj.searchUser = '';
        }
        if (!this.unitNumberSearch)  {
            this.filterObj.unit = undefined;
        }
    }

    /**
     * Change pagination
     * @param {any} event
     * */
    async pageChanged(event: any) {
        this.filterObj.page = event.page;
        await this.getUsers(this.selectedLocker, this.filterObj);
    }

    /**
     * Change company
     * */
    async companyChanged() {
        await this.getLockers(this.selectedCompany);
        if (this.lockersArr.length) {
            this.selectedLocker = this.lockersArr[0]._id;
        }
        this.filterObj.lockerId = this.selectedLocker;
        this.filterObj.searchUser = this.selectedUser = '';
        this.filterObj.unit = this.unitNumberSearch = '';
        await this.getUsers(this.selectedLocker, this.filterObj);
    }

    /**
     * Change locker
     * */
    async lockerChanged() {
        this.filterObj.page = 1;
        this.filterObj.limit = 10;
        this.filterObj.lockerId = this.selectedLocker;
        this.filterObj.searchUser = this.selectedUser = '';
        this.filterObj.unit = this.unitNumberSearch = '';
        await this.getUsers(this.selectedLocker, this.filterObj);
    }

    /**
     * Archive user
     * @param {string} id
     * */
    archiveUser(id: string) {
        const modal = this._bsModalService.show(DeleteModalComponent, {'class': 'modal-sm'});
        (<DeleteModalComponent>modal.content).showConfirmationModal(
            'Archive',
            'Are you sure you want to archive this user?',
            'Archive'
        );

        (<DeleteModalComponent>modal.content).onClose.subscribe(result => {
            if (result) {
                this.usersService.updateUserStatus(id, USER_STATUS_ENUM.ARCHIVED)
                    .then(() => {
                        _.remove(this.userArray, function (user) {
                            return user._id === id;
                        });
                    });
            }
        });
    }
}

