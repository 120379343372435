<div class="row page">
    <div class="col-lg-12">
        <h1 class="page-header">Slot</h1>
        <ul class="breadcrumb">
            <li>
                <a href="javascript:void(0);" [routerLink]="['/slots']">Slots</a>
            </li>
            <li *ngIf="!isDataLoaded">{{slotObj._id ? 'Edit' : 'Add'}} Slot</li>
        </ul>
    </div>
    <pp-spinner [isSpinnerLoad]="isDataLoaded"></pp-spinner>
    <div class="col-lg-12" *ngIf="!isDataLoaded">
        <form #slotForm="ngForm" (ngSubmit)="slotForm.valid && fnSaveSlot(slotObj)" novalidate>
            <fieldset class="col-md-12 fieldset">
                <legend class="fieldset-legend">Basic</legend>

                <div class="form-group" *ngIf="currentUser.role === CONSTANT.ROLE_ADMIN && !slotObj._id" [ngClass]="{'has-error': (slotForm.submitted || company.touched) && !company.valid}">
                    <label for="company">Select Company
                        <span class="text-danger">*</span>
                    </label>
                    <select id="company" class="form-control" name="company" [(ngModel)]="slotObj.companyId" (change)="slotObj.lockerId = '';slotObj.cabinetId = '';fnGetLockers({companyId: slotObj.companyId})"
                            #company="ngModel" required>
                        <option [ngValue]="item._id" *ngFor="let item of companiesArr">{{item.name}}</option>
                    </select>
                    <div *ngIf="(slotForm.submitted || company.touched) && !company.valid">
                        <small class="text-danger" *ngIf="company.errors['required']">Company is required.</small>
                    </div>
                </div>

                <div class="form-group" *ngIf="slotObj._id">
                    <label>Company</label>
                    <label class="form-control" disabled="true">{{selectedCompany}}</label>
                </div>

                <div class="form-group" *ngIf="!slotObj._id" [ngClass]="{'has-error': (slotForm.submitted || locker.touched) && !locker.valid}">
                    <label for="locker">Select Locker
                        <span class="text-danger">*</span>
                    </label>
                    <select id="locker" class="form-control" name="locker" [(ngModel)]="slotObj.lockerId" #locker="ngModel" (change)="slotObj.cabinetId = '';fnGetCabinets({lockerId: slotObj.lockerId})"
                            required>
                        <option [ngValue]="item._id" *ngFor="let item of lockersArr">
                            {{item.name}}
                        </option>
                    </select>
                    <div *ngIf="(slotForm.submitted || locker.touched) && !locker.valid">
                        <small class="text-danger" *ngIf="locker.errors['required']">Locker is required.</small>
                    </div>
                </div>

                <div class="form-group" *ngIf="slotObj._id">
                    <label>Locker</label>
                    <label class="form-control" disabled="true">{{selectedLocker}}</label>
                </div>

                <div class="form-group" *ngIf="!slotObj._id" [ngClass]="{'has-error': (slotForm.submitted || cabinetNumber.touched) && !cabinetNumber.valid}">
                    <label for="cabinetNumber">Cabinet Number
                        <span class="text-danger">*</span>
                    </label>
                    <select id="cabinetNumber" class="form-control" name="cabinetNumber" (change)="getAvailableSlotNumbers()" [(ngModel)]="slotObj.cabinetId" #cabinetNumber="ngModel"
                            required>
                        <option [ngValue]="item._id" *ngFor="let item of cabinetsArr">
                            {{item.cabinetNumber ? item.type + ' - ' + item.cabinetNumber : 'Select Cabinet Number'}}
                        </option>
                    </select>
                    <div *ngIf="(slotForm.submitted || cabinetNumber.touched) && !cabinetNumber.valid">
                        <small class="text-danger" *ngIf="cabinetNumber.errors['required']">
                            Cabinet Number is required.
                        </small>
                    </div>
                </div>

                <div class="form-group" *ngIf="slotObj._id">
                    <label>Cabinet</label>
                    <label class="form-control" disabled="true">{{selectedCabinet}}</label>
                </div>

                <div class="form-group" *ngIf="!slotObj._id" [ngClass]="{'has-error': (slotForm.submitted || slotNumber.touched) && !slotNumber.valid}">
                    <label for="slotNumber">Slot Number</label>
                    <select id="slotNumber" class="form-control"  name="slotNumber" [(ngModel)]="slotObj.slotNumber" #slotNumber="ngModel" required>
                        <option [value]="''">Select Slot Number</option>
                        <option [value]="slotNumber" *ngFor="let slotNumber of availableSlotNumbers">
                            {{slotNumber}}
                        </option>
                    </select>
                    <div *ngIf="(slotForm.submitted || slotNumber.touched) && !slotNumber.valid">
                        <small class="text-danger" *ngIf="slotNumber.errors['required']">
                            Slot Number is required.
                        </small>
                    </div>
                </div>

                <div class="form-group" *ngIf="slotObj._id">
                    <label>Slot Number</label>
                    <label class="form-control" disabled="true">{{slotObj.slotNumber}}</label>
                </div>

                <div class="form-group">
                    <!-- *ngIf="slotObj.status !== CONSTANT.SLOT_STATUS_UNAVAILABLE"> -->
                    <label for="slotStatus">Select Status</label>
                    <select id="slotStatus" class="form-control" name="slotStatus" [(ngModel)]="slotObj.status" (change)="onSlotStatusUpdate(slotObj.status)">
                        <option *ngIf="slotObj.status !== CONSTANT.SLOT_STATUS_AVAILABLE" value="{{CONSTANT.SLOT_STATUS_UNAVAILABLE}}">UNAVAILABLE</option>
                        <option value="{{CONSTANT.SLOT_STATUS_AVAILABLE}}">AVAILABLE</option>
                        <option value="{{CONSTANT.SLOT_STATUS_REPAIR}}">REPAIR</option>
                        <option value="{{CONSTANT.SLOT_STATUS_RESERVED}}">RESERVED</option>
                        <option value="{{CONSTANT.SLOT_STATUS_RESERVED_FOR_HARDWARE}}">RESERVED FOR HARDWARE</option>
                        <option value="{{CONSTANT.SLOT_STATUS_JAMMED}}">DOOR JAMMED</option>
                        <option value="{{CONSTANT.SLOT_STATUS_NOT_IN_USE}}">NOT IN USE</option>
                        <option value="{{CONSTANT.SLOT_STATUS_OPEN}}" *ngIf="slotObj._id">OPEN</option>
                    </select>
                </div>

                <div class="form-group" *ngIf="shipmentId">
                    <button type="button" class="btn btn-primary" [routerLink]="['/shipments/'+shipmentId]" >View Shipment</button>
                </div>

                <div class="form-group" *ngIf="slotObj.status === CONSTANT.SLOT_STATUS_REPAIR || slotObj.status === CONSTANT.SLOT_STATUS_NOT_IN_USE || slotObj.status === CONSTANT.SLOT_STATUS_RESERVED">
                    <label for="slotStatus">{{ CONSTANT.REASON_MODAL_TITLE[slotObj.status] }} Reason <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" name="reason" placeholder="Enter Reason"
                           [(ngModel)]="slotObj.reason" #reason="ngModel" [pattern]="CONSTANT.BLANK_SPACE_REG_EX" [required]='slotObj.status !== CONSTANT.SLOT_STATUS_RESERVED'>
                    <div *ngIf="(slotForm.submitted || reason.touched) && !reason.valid">
                        <small class="text-danger" *ngIf="(reason.errors['required'] || reason.errors['pattern'])">
                            Reason is required.
                        </small>
                    </div>
                </div>

                <div class="form-group">
                    <label for="type">Select Type</label>
                    <select id="type" class="form-control" name="type" [(ngModel)]="slotObj.type">
                        <option value="{{CONSTANT.SLOT_TYPE_SMALL}}">SMALL</option>
                        <option value="{{CONSTANT.SLOT_TYPE_MEDIUM}}">MEDIUM</option>
                        <option value="{{CONSTANT.SLOT_TYPE_LARGE}}">LARGE</option>
                        <option value="{{CONSTANT.SLOT_TYPE_EXTRA_LARGE}}">EXTRA LARGE</option>
                        <option value="{{CONSTANT.SLOT_TYPE_XX_LARGE}}">XX LARGE</option>
                    </select>
                </div>
                <div class="form-group">
                    <label>Is upper slot?</label>
                    <div class="col-xs-12 p-0">
                        <div class="btn-group">
                            <label class="btn btn-success" [(ngModel)]="isUpperSlot" name="autoUpdateTrue" btnRadio="True">Yes</label>
                            <label class="btn btn-success" [(ngModel)]="isUpperSlot" name="autoUpdateFalse" btnRadio="False">No</label>

                        </div>
                    </div>
                </div>
            </fieldset>

            <div class="form-group">
                <button class="btn btn-primary m-r-4" type="submit">
                    {{slotObj._id ? 'Update Slot' : 'Create Slot'}}
                </button>
                <button class="btn btn-warning" [routerLink]="['/slots']">Cancel</button>
                <button type="button" class="btn btn-danger pull-right" *ngIf="slotObj._id && slotObj.packageId" (click)="unlinkShipment()" >Remove Shipment</button>
            </div>
        </form>
    </div>
</div>
