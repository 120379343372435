<div id="main" class="row page">
    <div class="col-lg-12" id="overlay" (click)="toggleNotificationUpdates(false)"></div>
    <div class="col-lg-12">
        <h1 class="page-header">Notifications
            <button class="pull-right btn btn-success m-r-2" (click)="fnExportCSV()" *ngIf="notificationArr.length" [disabled]="isExporting">
                <i class="fa fa-file-excel-o m-r-4"></i> <span>Export to CSV</span>
            </button>
        </h1>
    </div>
    <pp-spinner [isSpinnerLoad]="!isDataLoaded"></pp-spinner>
    <div class="col-lg-12">
        <div class="row">
            <div class="col-sm-4">
                <div class="form-group">
                    <ng-template #customItemTemplate let-model="item" let-index="index">
                        {{model.firstName}}&nbsp;{{model.lastName}}
                    </ng-template>
                    <input id="users" name="users" class="form-control" autocomplete="off" [(ngModel)]="selectedUser" [typeahead]="dataSource" (typeaheadLoading)="changeTypeAheadLoading($event)"
                           (typeaheadOnSelect)="fnOnSelectUser($event)" [typeaheadItemTemplate]="customItemTemplate"
                           [typeaheadScrollable]="true" [typeaheadOptionsLimit]="1000" container="body" [typeaheadMinLength]="0"
                           [typeaheadOptionsInScrollableView]="10" (typeaheadOnBlur)="fnTypeAheadOnBlur($event)" placeholder="Select User">
                </div>
            </div>

            <div class="col-sm-3">
                <div class="form-group">
                    <input type="text" name="daterangeInput" class="form-control" daterangepicker
                           placeholder="Select Date Range"
                           [(ngModel)]="selectedCreatedAt"
                           [options]="{autoUpdateInput: false, opens: 'center'}"
                           (change)="changeDateRange()"
                           (applyDaterangepicker)="selectedDate($event)"
                           (cancelDaterangepicker)="fnClearDate()"/>
                    <small class="text-danger" *ngIf="invalidDateRange">
                        Maximum date range is {{maxDateRangeDays}} days.
                    </small>
                    <!--<input type="text" placeholder="Select Date Range" class="form-control"-->
                           <!--[(ngModel)]="selectedCreatedAt" (bsValueChange)="fnOnDateChange($event)"-->
                           <!--bsDaterangepicker>-->
                </div>
            </div>

            <div class="col-sm-3">
                <div class="form-group">
                    <input type="text" placeholder="Search By Label #" class="form-control" [(ngModel)]="labelSearch"
                           (blur)="fnOnLabelInput()">
                </div>
            </div>

            <div class="col-sm-2">
                <button class="btn btn-primary btn-block" (click)="fnClearFilters()">Clear Filters</button>
            </div>
        </div>
    </div>
    <div class="col-lg-12">
        <div *ngIf="notificationArr.length">
            <p *ngIf="notificationCounts">
                <b>Sent Today: </b><span class="text-success">{{notificationCounts.sentToday}}</span>,
                <b>  Errors Today: </b><span class="text-danger">{{notificationCounts.errorsToday}}</span>
            </p>
            <table class="notifications-table">
                <thead>
                <tr>
                    <th class="bg-primary created-at" (click)="sortBy('createdAt')">
                        Sent At <i [class]="setSortIcon('createdAt')"></i>
                    </th>
                    <th class="bg-primary user" (click)="sortBy('userId.firstName')">
                        Receiver <i [class]="setSortIcon('userId.firstName')"></i>
                    </th>
                    <th class="bg-primary email" (click)="sortBy('toEmail')">
                        Email <i [class]="setSortIcon('toEmail')"></i>
                    </th>
                    <th class="bg-primary phone cursor-auto"> Phone </th>
                    <th class="bg-primary package-label cursor-auto"> Label # </th>
                    <th class="bg-primary locker-name" (click)="sortBy('shipmentId.lockerId.name')">
                        Locker Name <i [class]="setSortIcon('shipmentId.lockerId.name')"></i>
                    </th>
                    <th class="bg-primary slot cursor-auto"> Slot </th>
                    <th class="bg-primary cursor-auto"> More Updates </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let notification of notificationArr">
                    <td>{{notification.createdAt | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
                    <td>
                        <a [routerLink]="['/users/'+notification.userId._id]" *ngIf="notification.userId">
                            {{notification.userId['firstName'] + ' ' + notification.userId['lastName']}}
                        </a>
                    </td>
                    <td>{{ notification.toEmail }}</td>
                    <td>{{ notification.toNumber }}</td>
                    <td>
                        <a *ngIf="notification.shipmentId && notification.shipmentId['_id']" [routerLink]="['/shipments', notification.shipmentId['_id']]">
                            {{notification.packageId && notification.packageId['label'] ? notification.packageId['label'] : ''}}
                        </a>
                    </td>
                    <td>{{ (notification.shipmentId && notification.shipmentId.lockerId && notification.shipmentId.lockerId.name) ? notification.shipmentId.lockerId.name : '' }}</td>
                    <td>{{ (notification.packageId && notification.packageId.slotId && notification.packageId.slotId.cabinetId && notification.packageId.slotId.cabinetId.cabinetNumber && notification.packageId.slotId.slotNumber) ? (notification.packageId.slotId.cabinetId.cabinetNumber + notification.packageId.slotId.slotNumber) : '' }}</td>
                    <td>
                        <button class="btn btn-xs btn-link" (click)="toggleNotificationUpdates(true,notification);$event.stopPropagation();">
                            Show Updates
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <h3 *ngIf="isDataLoaded && !notificationArr.length" class="text-center">No notifications found.</h3>
        <div class="col-lg-12 text-center" *ngIf="notificationArr.length">
            <pagination [totalItems]="totNotifications" [(ngModel)]="filterObj.page" [itemsPerPage]="filterObj.limit"
                        [boundaryLinks]="true"
                        [maxSize]="5" (pageChanged)="fnPageChanged($event)"></pagination>
        </div>
    </div>
</div>

<div id="notificationUpdates" class="sidenav">
    <div class="col-sm-12 notification-toolbar">
        <h4 class="col-sm-10">Notification Updates</h4>
        <a class="col-sm-2" href="javascript:void(0)" class="closebtn" (click)="toggleNotificationUpdates(false)">&times;</a>
    </div>

    <div class="notification-detail-header">
        <div class="card">
            <div class="section-header">
                Notification Detail
            </div>
            <ul class="list-group list-group-flush">
                <li class="list-group-item">To Email: {{ notificationDetail.toEmail }}</li>
                <li class="list-group-item">To Number: {{ notificationDetail.toNumber }}</li>
            </ul>
        </div>

        <div class="card" *ngFor="let filteredRecord of notificationDetail.statusUpdates | groupBy:'messageType'">
            <div class="section-header">
                {{ filteredRecord.key | uppercase }} Statuses
            </div>
            <ul class="list-group" *ngIf="filteredRecord.value && filteredRecord.value.length ==  0">
                <li class="list-group-item">
                    No {{ filteredRecord.key | titlecase }} Statuses
                </li>
            </ul>
            <ul class="list-group" *ngIf="filteredRecord.value && filteredRecord.value.length > 0">
                <li class="list-group-item" *ngFor="let record of filteredRecord.value" >
                    <span class="event-status">
                       {{ record.status | titlecase }}
                    </span>
                    <span class="event-time">
                        {{ record.timestamp |  date: 'dd/MM/yyyy HH:mm:ss' }}
                    </span>
                </li>
            </ul>
        </div>
    </div>
</div>
