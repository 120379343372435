<div class="row page">
    <div class="col-lg-12">
        <h1 class="page-header ">Building Data</h1>
    </div>
    <div class="col-lg-12">
        <tabset>
            <tab heading="QV Raw Dump" id="tab1" class="m-t-10" (selectTab)="fnSelectTab('RAW_DUMP', $event)">
                    <pp-spinner [isSpinnerLoad]="!isDataLoaded"></pp-spinner>
                    <!--<p class="text-danger"><strong>NOTE: We have loaded static data for testing purpose.</strong></p>-->
                    <div class="table-responsive" id="tblUPSCarrier" *ngIf="upsCarrierArr.length">
                        <table>
                            <thead>
                                <tr>
                                    <th class="bg-primary cursor-auto">Created At</th>
                                    <th class="bg-primary cursor-auto">Raw Data</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let upsCarrier of upsCarrierArr">
                                    <td>
                                        <p class="m-b-0">{{upsCarrier.createdAt | date: 'dd/MM/yyyy HH:mm:ss'}}</p>
                                    </td>
                                    <td>
                                        <ngx-json-viewer [json]="{QuantumViewEvents: upsCarrier.QuantumViewEvents}" [expanded]="false"></ngx-json-viewer>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <h3 *ngIf="isDataLoaded && !upsCarrierArr.length" class="text-center">No QV Raw Dump data found.</h3>
                    <div class="col-lg-12 text-center" *ngIf="upsCarrierArr.length">
                        <pagination [totalItems]="totUpsCarriers" [(ngModel)]="upsFilterObj.page" [itemsPerPage]="upsFilterObj.limit"
                            [boundaryLinks]="true" [maxSize]="5" (pageChanged)="fnUpsPageChanged($event)"></pagination>
                    </div>
            </tab>
            <tab heading="QV from DB" class="m-t-10" (selectTab)="fnSelectTab('FROM_DB', $event)">
                <pp-spinner [isSpinnerLoad]="!isDataLoaded"></pp-spinner>
                <div class="table-responsive" *ngIf="carrierArr.length">
                    <table>
                        <thead>
                            <tr>
                                <th class="bg-primary cursor-auto">Tracking No</th>
                                <th class="bg-primary cursor-auto">Name</th>
                                <th class="bg-primary cursor-auto">Phone</th>
                                <th class="bg-primary cursor-auto">City</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let carrier of carrierArr">
                                <td>
                                    <p class="m-b-0">{{carrier.parcel.label}}</p>
                                </td>
                                <td>
                                    <p class="m-b-0">{{carrier.to ? carrier.to.firstName + ' ' + carrier.to.lastName :
                                        ''}}</p>
                                </td>
                                <td>
                                    <p class="m-b-0">{{carrier.to ? carrier.to.phone : ''}}</p>
                                </td>
                                <td>
                                        <p class="m-b-0">{{carrier.to ? carrier.to.city : ''}}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h3 *ngIf="isDataLoaded && !carrierArr.length" class="text-center">No carriers found.</h3>
                <div class="col-lg-12 text-center" *ngIf="carrierArr.length">
                    <pagination [totalItems]="totCarriers" [(ngModel)]="filterObj.page" [itemsPerPage]="filterObj.limit"
                        [boundaryLinks]="true" [maxSize]="5" (pageChanged)="fnPageChanged($event)"></pagination>
                </div>
            </tab>
            <tab heading="JSON from RBC AWS S3" class="m-t-10" (selectTab)="fnSelectTab('JSON_RBC', $event)">
                    <pp-spinner [isSpinnerLoad]="!isDataLoaded"></pp-spinner>
                    <div class="table-responsive" id="tblRbc" *ngIf="rbcArr.length">
                        <table>
                            <thead>
                                <tr>
                                    <th class="bg-primary cursor-auto">Given Name</th>
                                    <th class="bg-primary cursor-auto">SN</th>
                                    <th class="bg-primary cursor-auto">Mail</th>
                                    <th class="bg-primary cursor-auto">Mobile</th>
                                    <th class="bg-primary cursor-auto">Telephone Number</th>
                                    <th class="bg-primary cursor-auto">Postal Code</th>
                                    <th class="bg-primary cursor-auto">RBC Floor</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let rbc of rbcArr | slice: rbcFilterObj.start : rbcFilterObj.end; let i=index">
                                    <td><p class="m-b-0">{{rbc.givenname}}</p></td>
                                    <td><p class="m-b-0">{{rbc.sn}}</p></td>
                                    <td><p class="m-b-0">{{rbc.mail}}</p></td>
                                    <td><p class="m-b-0">{{rbc.mobile}}</p></td>
                                    <td><p class="m-b-0">{{rbc.telephonenumber}}</p></td>
                                    <td><p class="m-b-0">{{rbc.postalcode}}</p></td>
                                    <td><p class="m-b-0">{{rbc.rbcfloor}}</p></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <h3 *ngIf="isDataLoaded && !rbcArr.length" class="text-center">No RBC found.</h3>
                    <div class="col-lg-12 text-center" *ngIf="rbcArr.length">
                        <pagination [totalItems]="rbcArr.length" [(ngModel)]="rbcFilterObj.page" [itemsPerPage]="rbcFilterObj.limit"
                            [boundaryLinks]="true" [maxSize]="5" (pageChanged)="fnRbcPageChanged($event)"></pagination>
                    </div>
            </tab>
        </tabset>
    </div>
</div>
