<form (ngSubmit)="onConfirm()" *ngIf="active">
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            {{title}}
        </h4>
    </div>
    <div class="modal-body">
        <p>{{body}}</p>
    </div>
    <div class="modal-footer">
        <div class="text-right">
            <button type="submit" class="btn btn-danger">
                {{btnText? btnText: 'Delete'}}
            </button>
            <button type="button" class="btn btn-default" (click)="onCancel()">
                Cancel
            </button>
        </div>
    </div>
</form>
