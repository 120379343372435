<div class="row page">
    <div class="col-lg-12">
        <h1 class="page-header">New User</h1>
    </div>
    <pp-spinner [isSpinnerLoad]="isDataLoaded"></pp-spinner>
    <div class="col-lg-12" [hidden]="isDataLoaded">
        <form #userForm="ngForm" (ngSubmit)="userForm.valid && fnSaveUser(userObj)" novalidate>

                <div class="form-group" *ngIf="currentUser.role === CONSTANT.ROLE_COMPANY_ADMIN && lockersArr && lockersArr.length > 1">
                    <label for="locker">Select Locker<span class="text-danger">*</span></label>
                    <select id="locker" class="form-control" name="locker" [(ngModel)]="userObj.preferredLockerId"
                            #locker="ngModel" required>
                        <option [ngValue]="item._id" *ngFor="let item of lockersArr">{{item.name}}</option>
                    </select>
                    <div *ngIf="(userForm.submitted || locker.touched) && !locker.valid">
                        <small class="text-danger" *ngIf="locker.errors['required']">Locker is required.</small>
                    </div>
                </div>
                <div class="form-group" [ngClass]="{'has-error': (userForm.submitted || firstName.touched) && !firstName.valid}">
                    <label for="firstName">First Name<span class="text-danger">*</span></label>
                    <input id="firstName" type="text" class="form-control" name="firstName" placeholder="Enter a first name" [(ngModel)]="userObj.firstName"
                           #firstName="ngModel" required/>
                    <div *ngIf="(userForm.submitted || firstName.touched) && !firstName.valid">
                        <small class="text-danger" *ngIf="firstName.errors['required']">First name is required.</small>
                    </div>
                </div>
                <div class="form-group" [ngClass]="{'has-error': (userForm.submitted || lastName.touched) && !lastName.valid}">
                    <label for="lastName">Last Name<span class="text-danger">*</span></label>
                    <input id="lastName" type="text" class="form-control" name="lastName" placeholder="Enter a last name" [(ngModel)]="userObj.lastName"
                           #lastName="ngModel" required/>
                    <div *ngIf="(userForm.submitted || lastName.touched) && !lastName.valid">
                        <small class="text-danger" *ngIf="lastName.errors['required']">Last name is required.</small>
                    </div>
                </div>
                <div class="form-group" [ngClass]="{'has-error': userForm.submitted && isRequired}">
                    <label for="email">Email<span class="text-success">*</span></label>
                    <input id="email" type="email" class="form-control" name="email" placeholder="Enter an email" [(ngModel)]="userObj.email"
                           #email="ngModel" [pattern]="emailRegEx" (keyup)="resetRequired()"/>
                    <div *ngIf="(userForm.submitted || email.touched) && !email.valid">
                        <small class="text-danger" *ngIf="email.errors['pattern']">Email is invalid.</small>
                    </div>
                </div>
                <div class="form-group" [ngClass]="{'has-error': userForm.submitted && isRequired}">
                    <label for="phone">Mobile Phone #<span class="text-success">*</span></label>
                    <input id="phone" type="phone" class="form-control" name="phone" placeholder="Enter a phone #" [(ngModel)]="userObj.phone"
                           #phone="ngModel" (keyup)="resetRequired()"/>
                </div>
            <pp-location [locationId]="userObj.locationId" [form]="userForm"></pp-location>
            <div class="form-group">
                <button class="btn btn-primary m-r-4" type="submit">
                    Create User
                </button>
                <button class="btn btn-warning" [routerLink]="['/dashboard']">Cancel</button>
            </div>
        </form>
    </div>
</div>
