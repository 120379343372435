<div class="row page">
    <div class="col-lg-12">
        <h1 class="page-header">Payment Plans
            <button class="pull-right btn btn-primary" [routerLink]="['/payment-plan/add']">
                <i class="fa fa-plus m-r-4"></i> <span class="hidden-xs">New Plan</span>
            </button>
        </h1>
    </div>
    <pp-spinner [isSpinnerLoad]="!isDataLoaded"></pp-spinner>
    <div class="col-lg-12">
        <div class="row">
            <div class="col-sm-12" *ngIf="currentUser.role === CONSTANT.ROLE_ADMIN">
                <div class="form-group">
                    <select id="company" class="form-control" name="company" [(ngModel)]="selectedCompany"
                            (change)="companyChanged()">
                        <option [ngValue]="item._id" *ngFor="let item of companiesArr">{{item.name}}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-12">
        <div *ngIf="planArr.length">
            <table class="plans-table">
                <thead>
                <tr>
                    <th class="bg-primary plan-name">
                        Plan Name <i class="fa fa-sort-asc"></i>
                    </th>
                    <th class="bg-primary plan-type">
                        Type <i class="fa fa-sort"></i>
                    </th>
                    <th class="bg-primary plan-fee cursor-auto">
                        Fees
                    </th>
                    <th class="bg-primary active-plan">
                        Active <i class="fa fa-sort"></i>
                    </th>
                    <th class="bg-primary default-plan">
                        Default <i class="fa fa-sort"></i>
                    </th>
                    <th class="bg-primary cursor-auto">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let plan of planArr">
                    <td>{{plan.name}}</td>
                    <td>{{plan.type | SpaceBeforeUppercase}}</td>
                    <td>
                        <span *ngIf="plan.fee">
                            <label>Any Slot:</label> {{plan.fee}} {{plan.currency}}</span>
                        <span *ngIf="plan.slotFees">
                        <table class="plan-fees">
                            <thead>
                            <tr>
                                <th class="bg-info" *ngFor="let slot of plan.slotFees">
                                    <span *ngIf="SLOT_SIZE.SMALL === slot.size"><label class="p-t-5">Small Slot</label></span>
                                    <span *ngIf="SLOT_SIZE.MEDIUM === slot.size"><label class="p-t-5">Medium Slot</label></span>
                                    <span *ngIf="SLOT_SIZE.LARGE === slot.size"><label class="p-t-5">Large Slot</label></span>
                                    <span *ngIf="SLOT_SIZE.EXTRA_LARGE === slot.size"><label class="p-t-5">Extra Large Slot</label></span>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td *ngFor="let slot of plan.slotFees">
                                    <span *ngIf="SLOT_SIZE.SMALL === slot.size">{{slot.fee}}</span>
                                    <span *ngIf="SLOT_SIZE.MEDIUM === slot.size">{{slot.fee}}</span>
                                    <span *ngIf="SLOT_SIZE.LARGE === slot.size">{{slot.fee}}</span>
                                    <span *ngIf="SLOT_SIZE.EXTRA_LARGE === slot.size">{{slot.fee}}</span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        </span>
                    </td>
                    <td>{{plan.isActive ? 'Yes' : 'No'}}</td>
                    <td>{{plan.isDefault ? 'Yes' : 'No'}}</td>
                    <td>
                        <div class="btn-group btn-group-xs">
                            <button type="button" class="btn btn-primary" [routerLink]="['/payment-plan/' + plan._id]">
                                <i class="fa fa-edit"></i>
                            </button>
                            <button type="button" class="btn btn-danger" (click)="deletePlan(plan._id)">
                                <i class="fa fa-trash-o"></i>
                            </button>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <h3 *ngIf="isDataLoaded && !planArr.length" class="text-center">No Payment Plans found.</h3>
    </div>
</div>
