<div class="row page">
    <div class="col-lg-12">
        <h1 class="page-header">
            Locker
            <a href="javascript:void(0);" class="btn btn-primary pull-right" (click)="resetValues()" *ngIf="lockerObj._id && currentUser.role.endsWith(CONSTANT.ROLE_ADMIN)">
                <i class="fa fa-fw" [ngClass]="{'fa-edit': isReadOnly, 'fa-close': !isReadOnly}"></i>
            </a>
        </h1>
        <ul class="breadcrumb">
            <li>
                <a href="javascript:void(0);" [routerLink]="['/lockers']">Lockers</a>
            </li>
            <li *ngIf="currentUser.role.endsWith(CONSTANT.ROLE_ADMIN) && !isDataLoaded">{{lockerObj._id ? 'Edit' : 'Add'}} Locker</li>
        </ul>
    </div>
    <pp-spinner [isSpinnerLoad]="isDataLoaded"></pp-spinner>
    <div class="col-lg-12" [hidden]="isDataLoaded">
        <form #lockerForm="ngForm" (ngSubmit)="lockerForm.valid && fnSaveLocker(lockerObj)" novalidate autocomplete="off">
            <fieldset class="col-md-12 fieldset">
                <legend class="fieldset-legend">Basic</legend>

                <div class="form-group" *ngIf="currentUser.role === CONSTANT.ROLE_ADMIN"
                     [ngClass]="{'has-error': (lockerForm.submitted || company.touched) && company?.invalid}"
                    (change)="getAdminEmails()">
                    <label for="company">Select Company
                        <span class="text-danger">*</span>
                    </label>
                    <select id="company" class="form-control" name="company" [(ngModel)]="lockerObj.companyId" #company="ngModel" [disabled]="lockerId !== 'add'"
                        required>
                        <option [ngValue]="item._id" *ngFor="let item of companiesArr">{{item.name}}</option>
                    </select>
                    <div *ngIf="(lockerForm?.submitted || company?.touched) && company?.invalid">
                        <small class="text-danger" *ngIf="company.errors?.required">Company is required.</small>
                    </div>
                </div>
                <div class="form-group" [ngClass]="{'has-error': (lockerForm.submitted || locker.touched) && locker?.invalid}">
                    <label for="locker">Locker Name
                        <span class="text-danger">*</span>
                    </label>
                    <input id="locker" type="text" class="form-control" name="locker" placeholder="Enter a locker name"
                           [pattern]="CONSTANT.US_ASCII_NAME_REG_EX && CONSTANT.BLANK_SPACE_REG_EX" [(ngModel)]="lockerObj.name"
                        #locker="ngModel" [disabled]="isReadOnly" required/>
                    <div *ngIf="(lockerForm.submitted || locker.touched) && locker?.invalid">
                        <small class="text-danger" *ngIf="locker.errors?.required">Locker name is required.</small>
                        <small class="text-danger" *ngIf="locker.errors?.pattern">Locker name should not contain any
                            special characters and should not be blank.
                        </small>
                    </div>
                </div>
               <div class="form-group" *ngIf="currentUser.role === CONSTANT.ROLE_ADMIN && lockerObj.companyId">
                   <label>Admin emails</label>
                       <ngx-select [multiple]="true"
                                   [items]="adminEmails"
                                   [disabled]="isReadOnly"
                                   [(ngModel)]="lockerObj.adminEmails"
                                   [autoClearSearch]="true"
                                   name="adminEmails"
                                   placeholder="Select Emails">
                       </ngx-select>
                </div>
                <ng-template [ngIf]="!lockerObj._id" >
                    <label>Host Bank</label>
                    <app-bank [cabinet]="banksArray[0]" [cabinetNumber]="'A'" [cabinetType]="CONSTANT.HOST_BANK"></app-bank>
                </ng-template>
                <div *ngIf="currentUser.role === CONSTANT.ROLE_ADMIN && !lockerObj._id"
                     [ngClass]="{'has-error': (lockerForm.submitted || totalLockerbanks.touched) && !totalLockerbanks.valid}"
                     class="form-group">
                    <label for="totalLockerbanks">How many locker banks do you want to create?</label>
                    <select #totalLockerbanks="ngModel" (change)="generateLockerBanks($event.target?.value)" class="form-control"
                            [(ngModel)]="totalLockerBank"  class="form-control"
                            id="totalLockerbanks" name="totalLockerbanks"
                            required>
                        <option *ngFor="let item of totalLockerbanksArray" [value]="item">{{item}}</option>
                    </select>
                    <div *ngIf="(lockerForm.submitted || totalLockerbanks.touched) && !totalLockerbanks.valid">
                        <small *ngIf="totalLockerbanks.errors['required']" class="text-danger">
                            Please select how many Locker banks would you like to create.
                        </small>
                    </div>
                </div>
                <ng-template [ngIf]="this.banksArray.length>1">
                    <label>Locker Bank</label>
                    <ng-container *ngFor="let bank of this.banksArray; let index = index">
                        <app-bank *ngIf="index" [cabinet]="bank" [cabinetNumber]="getCharCode(index+1)" [cabinetType]="CONSTANT.LOCKER_BANK"></app-bank>
                    </ng-container>
                </ng-template>
                <div *ngIf="lockerObj._id && currentUser.role.endsWith(CONSTANT.ROLE_ADMIN)"
                     [ngClass]="{'has-error': (lockerForm.submitted || lockerNumber.touched) && lockerNumber?.invalid}"
                     class="form-group">
                    <label for="lockerNumber">Locker Number
                        <span class="text-danger">*</span>
                    </label>
                    <input id="lockerNumber" type="tel" class="form-control" name="lockerNumber" [(ngModel)]="lockerObj.lockerNumber" #lockerNumber="ngModel"
                        [disabled]="isReadOnly" required/>
                    <div *ngIf="(lockerForm.submitted || lockerNumber.touched) && lockerNumber?.invalid">
                        <small class="text-danger" *ngIf="lockerNumber.errors?.required">Locker lockerNumber is required.</small>
                    </div>
                </div>
                <div *ngIf="lockerObj._id && currentUser.role.endsWith(CONSTANT.ROLE_ADMIN) && brand === SKIN.POPCLEANER"
                     [ngClass]="{'has-error': (lockerForm.submitted || shipmentReminderTime.touched) && shipmentReminderTime?.invalid}"
                     class="form-group">
                    <label for="lockerNumber">Shipment Reminder Time (In Hour)
                        <span class="text-danger">*</span>
                    </label>
                    <input id="shipmentReminderTime" type="tel" class="form-control" name="shipmentReminderTime" [(ngModel)]="lockerObj.shipmentReminderTime" #shipmentReminderTime="ngModel"
                           [disabled]="isReadOnly" required/>
                    <div *ngIf="(lockerForm.submitted || shipmentReminderTime.touched) && shipmentReminderTime?.invalid">
                        <small class="text-danger" *ngIf="shipmentReminderTime.errors?.required">Shipment reminder time is required.</small>
                    </div>
                </div>
                <div class="form-group">
                    <label for="locker">Valid postal codes</label>
                    <input id="validPostalCodes" type="text" class="form-control" name="validPostalCodes" [pattern]="alphaNumRegEx"
                           (blur)="formatValidPostalCodes()" #validPostalCodes="ngModel" [disabled]="isReadOnly"
                           placeholder="Enter valid postal codes" [(ngModel)]="lockerObj.validPostalCodes"/>
                    <div *ngIf="(lockerForm.submitted || validPostalCodes.touched) && !validPostalCodes.valid">
                        <p><small class="text-danger" *ngIf="validPostalCodes.errors?.pattern">Contains invalid postal code.</small></p>
                    </div>
                </div>

                <div class="form-group">
                    <label for="defaultLanguage">Default Language</label>
                    <select id="defaultLanguage" class="form-control" name="defaultLanguage" [(ngModel)]="lockerObj.defaultLanguage"
                            [disabled]="isReadOnly" #defaultLanguage="ngModel">
                        <option [ngValue]="item.id" *ngFor="let item of languages">{{item.name}}</option>
                    </select>
                </div>

                <div class="form-group" *ngIf="lockerObj._id">
                    <label for="lockerTimezone">Timezone
                        <span class="text-danger">*</span>
                    </label>
                    <select id="lockerTimezone" class="form-control" name="lockerTimezone"
                            [(ngModel)]="lockerObj.timezone"
                            [disabled]="isReadOnly" #lockerTimezone="ngModel" required>
                        <option [ngValue]="item" *ngFor="let item of TIME_ZONES">{{item}}</option>
                    </select>
                    <div *ngIf="(lockerForm.submitted || lockerTimezone.touched) && !lockerTimezone.valid">
                        <p>
                            <small class="text-danger" *ngIf="lockerTimezone.errors?.required">Timezone is required.</small>
                        </p>
                    </div>
                </div>
                <div class="service-btns">
                    <div class="form-group">
                        <label>Product Return / Outbound Shipment Enabled (Retail Use Only)</label>
                        <div class="col-xs-12 p-0 m-b-15" [ngStyle]="{'pointer-events': isReadOnly ? 'none' : '' }">
                            <div class="btn-group">
                                <label class="btn btn-success" name="productReturnEnabledTrue" [(ngModel)]="isOutboundEnabled" btnRadio="true">Yes</label>
                                <label class="btn btn-success" name="productReturnEnabledFalse" [(ngModel)]="isOutboundEnabled" btnRadio="false">No</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Door Load Type</label>
                        <div class="col-xs-12 p-0 m-b-15" [ngStyle]="{'pointer-events': isReadOnly ? 'none' : '' }">
                            <div class="btn-group">
                                <label class="btn btn-success" name="dropOffEnabledTrue" [(ngModel)]="isDropOffEnabled" btnRadio="true">Front Load</label>
                                <label class="btn btn-success" name="dropOffEnabledFalse" [(ngModel)]="isDropOffEnabled" btnRadio="false">Back Load</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="lockerDetails" *ngIf="lockerObj._id && lockerProfile">
                    <div class="m-b-15" *ngFor="let item of lockerProfile"><label>{{formatLabel(item)}}:</label>{{ ' '+lockerObj.lockerProfile[item]}}</div>
                </div>
            </fieldset>

            <!-- Start : Access codes -->
            <fieldset class="col-md-12 fieldset" *ngIf="lockerObj._id && isReadOnly">
                <legend class="fieldset-legend">Front Desk Access</legend>
                <table class="table front-desk-access">
                    <thead>
                    <tr>
                        <th colspan="12" class="bg-primary cursor-auto">Front Desk Access Codes</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td class="col-md-4" *ngFor="let code of lockerObj.accessCodes; let j = index; trackBy: trackByFn">
                            <input type="text" class="input-sm col-md-9" name="access_code_{{j}}"
                                   #accessCodeInput="ngModel"
                                   [disabled]="selectedAccessCodeIndex !== j"
                                   [(ngModel)]="lockerObj.accessCodes[j]" [pattern]="alphaNumRegEx"
                                   placeholder="Enter access code {{j+1}}"/>
                            <button type="button" class="btn btn-primary btn-sm rounded-pill"
                                    *ngIf="selectedAccessCodeIndex !== j"
                                    (click)="toggleAccessCodeSaveBtn(lockerObj, j)">
                                Edit
                            </button>
                            <button type="button" class="btn btn-success btn-sm rounded-pill"
                                    (click)="saveAccessCodes(lockerObj, j)"
                                    *ngIf="selectedAccessCodeIndex === j"
                                    [disabled]="!accessCodeInput.valid">
                                Save
                            </button>
                            <button class="btn btn-warning btn-sm rounded-pill"
                                    *ngIf="j===selectedAccessCodeIndex" type="button" (click)="cancelAccessCode(lockerObj)">
                                Cancel
                            </button>
                            <div
                                *ngIf="!accessCodeInput.valid && !accessCodeInput.disabled">
                                <small class="text-danger">Access codes must be alpha-numeric</small>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <table class="table front-desk-access">
                    <thead>
                    <tr>
                        <th class="bg-primary cursor-auto" [ngStyle]="{'width': isOutboundEnabled==='true' ? '50%' : '100%'}">Front Desk Expired Shipment Pickup Code</th>
                        <th class="bg-primary cursor-auto" width="50%" *ngIf="isOutboundEnabled==='true'">Product Return / Outbound Shipment Pickup Code</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td >
                            <input id="returnPickupCode" type="text" class="input-sm" [ngClass]="isOutboundEnabled==='true' ? 'w-55': 'w-20'" name="returnPickupCode"
                                   [pattern]="'(^$)|(^[0-9]{6}$)'" #returnPickupCode="ngModel"
                                   [disabled]="!toggleReturnPickup" placeholder="Enter expired shipment pickup code"
                                   [(ngModel)]="lockerObj.returnPickupCode" autocomplete="off"/>
                            <button type="button" class="btn btn-primary btn-sm rounded-pill"
                                    *ngIf="!toggleReturnPickup" (click)="toggleSaveButtonReturnCode(lockerObj)">
                                Edit
                            </button>
                            <button type="button" class="btn btn-success btn-sm rounded-pill"
                                    [disabled]="!returnPickupCode.valid" *ngIf="toggleReturnPickup"
                                    (click)="saveReturnPickupCode();">
                                Save
                            </button>
                            <button type="button" class="btn btn-warning btn-sm rounded-pill"
                                    *ngIf="toggleReturnPickup" (click)="cancelReturnPickupCode(lockerObj)">
                                Cancel
                            </button>
                            <div *ngIf="!returnPickupCode.valid && !returnPickupCode.disabled">
                                    <small class="text-danger">Expired shipment pickup code must be 6 digits</small>
                            </div>
                        </td>
                        <td *ngIf="isOutboundEnabled==='true'">
                            <input id="productReturnPickupCode" type="text" class="input-sm w-55" name="productReturnPickupCode"
                                   [pattern]="'(^$)|(^[0-9]{6}$)'" #productReturnPickupCode="ngModel"
                                   [disabled]="!toggleProductReturnPickup" placeholder="Enter product return / outbound shipment pickup code"
                                   [(ngModel)]="lockerObj.productReturnPickupCode" autocomplete="off"/>
                            <button type="button" class="btn btn-primary btn-sm rounded-pill"
                                    *ngIf="!toggleProductReturnPickup" (click)="toggleSaveButtonProductReturnPickupCode(lockerObj)">
                                Edit
                            </button>
                            <button type="button" class="btn btn-success btn-sm rounded-pill"
                                    [disabled]="!productReturnPickupCode.valid" *ngIf="toggleProductReturnPickup"
                                    (click)="saveProductReturnPickupCode();">
                                Save
                            </button>
                            <button type="button" class="btn btn-warning btn-sm rounded-pill"
                                    *ngIf="toggleProductReturnPickup" (click)="cancelProductReturnPickupCode(lockerObj)">
                                Cancel
                            </button>
                            <div *ngIf="!productReturnPickupCode.valid && !productReturnPickupCode.disabled">
                                <small class="text-danger">Product return / outbound shipment pickup code must be 6 digits</small>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </fieldset>
            <!-- End : Access codes -->

            <fieldset class="col-md-12 fieldset table-responsive" *ngIf="lockerCarriers && lockerCarriers.length && isReadOnly">
                <legend class="fieldset-legend">Carriers</legend>

                <table>
                    <thead>
                    <tr>
                        <th class="bg-primary cursor-auto">Name</th>
                        <th class="bg-primary cursor-auto">Enabled?</th>
                        <th class="bg-primary cursor-auto">Allow expired shipment pickup process</th>
                        <th class="bg-primary cursor-auto">Expired shipment pickup code</th>
                        <th class="bg-primary cursor-auto">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let carrier of lockerCarriers; let i = index">
                        <td class="col-md-3 capitalize">{{carrier.name | CarrierName}}</td>
                        <td class="col-md-2" *ngIf="!carrier.isEditable">{{carrier.enabled ? 'Yes' : 'No'}}</td>
                        <td class="col-md-2" *ngIf="carrier.isEditable">
                            <div class="btn-group">
                                <label class="btn btn-sm btn-success" [id]="'carrierEnabled'+i"
                                       [name]="'carrierEnabled'+i" [(ngModel)]="carrier.enabled" [btnRadio]=true>Yes</label>
                                <label class="btn btn-sm btn-success" [id]="'carrierNotEnabled'+i"
                                       (click)="changeReturnProcessType(carrier, returnType.NO)"
                                       [name]="'carrierNotEnabled'+i" [(ngModel)]="carrier.enabled" [btnRadio]=false>No</label>
                            </div>
                        </td>
                        <td class="col-md-3">
                            <select [id]="'returnType'+i" class="form-control input-sm" [name]="'returnType'+i"
                                    [(ngModel)]="carrier.returnProcessType" [disabled]="!carrier.isEditable || !carrier.enabled"
                                    (change)="checkReturnProcessType(carrier)">
                                <option *ngFor="let type of returnTypes" [ngValue]="type.id">{{type.name}}</option>
                            </select>
                        </td>
                        <td class="col-md-3">
                            <div *ngIf="(carrier.returnProcessType === returnType.COURIER)">
                                    <input type="text" [pattern]="'(^$)|(^[0-9]{6}$)'" class="form-control input-sm"
                                           name="returnPickupCode-{{i}}"
                                           [(ngModel)]="carrier.returnPickupCode"
                                           #returnPickupCode="ngModel"
                                           [disabled]="!carrier.isEditable || !carrier.enabled">
                                    <div *ngIf="!returnPickupCode.valid && !returnPickupCode.disabled">
                                        <small class="text-danger">Return pickup code must be 6 digits</small>
                                    </div>
                            </div>
                        </td>
                        <td class="col-md-1">
                            <button type="button" class="btn btn-primary btn-xs" *ngIf="!carrier.isEditable" (click)="enableLockerCarrierConfigEdit(lockerObj, carrier)">
                                    <i class="fa fa-edit"></i>
                                </button>
                                <button type="button" class="btn btn-primary btn-xs btn-success" *ngIf="carrier.isEditable" (click)="saveLockerCarrierConfig(setCodeModal, carrier)"
                                        [disabled]="doNotAllowEmptyReturnCode(carrier)">
                                    Save
                                </button>
                            <button class="btn btn-warning btn-xs" type="button"
                                    *ngIf="carrier.isEditable" (click)="cancelCarrierReturnPickUpCode()">
                                Cancel
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </fieldset>

            <pp-location [locationId]="lockerObj.locationId" [form]="lockerForm" [placeholderText]="'Enter a locker address'" [isReadOnly]="isReadOnly"></pp-location>

            <div class="form-group" *ngIf="!isReadOnly">
                <button class="btn btn-primary m-r-4" type="submit">
                    {{lockerObj._id ? 'Update Locker' : 'Create Locker'}}
                </button>
                <button class="btn btn-warning" [routerLink]="['/lockers']">Cancel</button>
            </div>
        </form>
    </div>
</div>
<ng-template #setCodeModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            Error
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeSetCodesModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>
            {{this.setCodesDescription}}
        </p>
    </div>
</ng-template>
