<div class="row page">
    <div class="col-lg-12">
        <h1 class="page-header">Cabinets
            <button class="pull-right btn btn-primary" [routerLink]="['/cabinets/add']"
                    *ngIf="currentUser.role.endsWith(CONSTANT.ROLE_ADMIN)">
                <i class="fa fa-plus m-r-4"></i> <span class="hidden-xs">New Cabinet</span>
            </button>
            <button class="pull-right btn btn-success m-r-2" (click)="fnExportCSV()" *ngIf="cabinetsArr.length" [disabled]="isExporting">
                <i class="fa fa-file-excel-o m-r-4"></i> <span>Export to CSV</span>
            </button>
        </h1>
    </div>
    <pp-spinner [isSpinnerLoad]="!isDataLoaded"></pp-spinner>
    <div class="col-lg-12">
        <div class="row">
            <div class="col-sm-5" *ngIf="currentUser.role === CONSTANT.ROLE_ADMIN">
                <div class="form-group">
                    <select id="company" class="form-control" name="company" [(ngModel)]="filterObj.companyId"
                            (change)="filterObj.lockerId = '';fnGetLockers({companyId: filterObj.companyId})">
                        <option [ngValue]="item._id" *ngFor="let item of companiesArr">{{item.name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-sm-5">
                <div class="form-group">
                    <select id="locker" class="form-control" name="locker" [(ngModel)]="filterObj.lockerId"
                            (change)="fnChangeFilters(filterObj)">
                        <option [ngValue]="item._id" *ngFor="let item of lockersArr">{{item.name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-sm-2">
                <button class="btn btn-primary btn-block" (click)="fnClearFilters()">Clear Filters</button>
            </div>
        </div>
    </div>
    <div class="col-lg-12 m-t-10">
        <div class="table-responsive" *ngIf="cabinetsArr.length">
            <table>
                <thead>
                <tr>
                    <th class="bg-primary company-name" (click)="sortBy('companyId.name')">
                        Company Name <i [class]="setSortIcon('companyId.name')"></i></th>
                    <th class="bg-primary locker-name" (click)="sortBy('lockerId.name')">
                        Locker Name <i [class]="setSortIcon('lockerId.name')"></i></th>
                    <th class="bg-primary cabinet-type" (click)="sortBy('type')">
                        Cabinet Type <i [class]="setSortIcon('type')"></i></th>
                    <th class="bg-primary cabinet-number" (click)="sortBy('cabinetNumber')">
                        Cabinet Number <i [class]="setSortIcon('cabinetNumber')"></i></th>
                    <th class="bg-primary cursor-auto">Available Slots</th>
                    <th class="bg-primary cursor-auto">Total Slots</th>
                    <th class="bg-primary cursor-auto" *ngIf="currentUser.role.endsWith(CONSTANT.ROLE_ADMIN)">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let cabinet of cabinetsArr">
                    <td>{{cabinet.companyId ? cabinet.companyId.name : '&nbsp;'}}</td>
                    <td>{{cabinet.lockerId ? cabinet.lockerId.name : '&nbsp;'}}</td>
                    <td>{{(cabinet.type ? cabinet.type : '&nbsp;') | uppercase}}</td>
                    <td>{{cabinet.cabinetNumber ? cabinet.cabinetNumber : '&nbsp;'}}</td>
                    <td>{{cabinet.totAvailableSlots}}</td>
                    <td>{{cabinet.slots && cabinet.slots.length}}</td>
                    <td *ngIf="currentUser.role.endsWith(CONSTANT.ROLE_ADMIN)">
                        <div class="btn-group btn-group-xs">
                            <button type="button" class="btn btn-primary" [routerLink]="['/cabinets/'+cabinet._id]">
                                <i class="fa fa-edit"></i>
                            </button>
                            <button type="button" class="btn btn-danger" (click)="deleteCabinet(cabinet._id)">
                                <i class="fa fa-trash-o"></i>
                            </button>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <h3 *ngIf="isDataLoaded && !cabinetsArr.length" class="text-center">No cabinets found.</h3>
        <div class="col-lg-12 text-center" *ngIf="cabinetsArr.length">
            <pagination [totalItems]="totCabinets" [(ngModel)]="filterObj.page" [itemsPerPage]="filterObj.limit" [boundaryLinks]="true"
                        [maxSize]="5" (pageChanged)="fnPageChanged($event)"></pagination>
        </div>
    </div>
</div>

