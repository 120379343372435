import {Component, OnInit, TemplateRef, Input, Output,  EventEmitter} from '@angular/core';
import {ToastrService} from '../../../common/toastr.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PaymentPlansService} from '../payment-plans.service';
import * as _ from 'lodash';
import {Router} from '@angular/router';
import {PaymentPlan} from '../payment-plans';


@Component({
  selector: 'pp-user-plan-modal',
  templateUrl: './user-plan-modal.component.html',
  styleUrls: ['./user-plan-modal.component.css']
})
export class UserPlanModalComponent implements OnInit {
    @Input() planObj: any;
    @Input() template: any;
    @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();

    isActivePlanModalObj: any;
    public activatedPlans: any;
    public planRequiredErr: boolean;
    public isActivePlanModalRef:  BsModalRef;
    constructor(
                private BsModalService: BsModalService,
                private toastr: ToastrService,
                private router: Router,
                private planService: PaymentPlansService) {
    }

  ngOnInit() {
       this.fnOpenIsActivePlanModal(this.template, this.planObj);
  }

    onClose(value) {
        this.closeEvent.emit(value);
    }

    fnOpenIsActivePlanModal(template: TemplateRef<any>, modalObj: any) {
        this.isActivePlanModalObj = modalObj;
        if (this.isActivePlanModalObj.currentPlan && this.isActivePlanModalObj.currentPlan.companyId) {
            this.planService.fnGetPlans({companyId: this.isActivePlanModalObj.currentPlan.companyId._id ? this.isActivePlanModalObj.currentPlan.companyId._id : this.isActivePlanModalObj.currentPlan.companyId, isActive: true})
                .then((res: any) => {
                    const thisRef = this;
                    thisRef.activatedPlans = res.data;
                    thisRef.activatedPlans = _.remove(thisRef.activatedPlans, function (ele: PaymentPlan) {
                        return ele._id !== thisRef.isActivePlanModalObj.currentPlan._id;
                    });
                    thisRef.activatedPlans.unshift({_id: '', planName: 'Select Plan'});
                })
                .catch((err: any) => {
                    this.activatedPlans = [];
                    console.log('Error occurred in getting activated plans.', err);
                });
        }
    }

    fnChangeUsersPlans() {
        const plansObj = {
            currentPlan: this.isActivePlanModalObj.currentPlan,
            newPlanId: this.isActivePlanModalObj.newPlanId
        };
        if (this.activatedPlans.length > 1) {
            if (plansObj.newPlanId) {
                this.planService.fnChangeUserPlans(plansObj)
                    .then((res: any) => {
                        this.onClose({isClose: true, currentPlanId : plansObj.currentPlan._id });
                        this.toastr.fnSuccess('Payment plan updated successfully.');
                        this.router.navigateByUrl('/payment-plans');
                    })
                    .catch((err: any) => {
                        console.log('Error', err);
                    });
                this.planRequiredErr = false;
            } else {
                this.planRequiredErr = true;
            }
        } else {
            this.isActivePlanModalRef.hide();
        }
    }
}
