<div class="container-fluid reset-password-verification-page">
    <div class="row">
        <div class="col-sm-6 col-md-4 col-sm-offset-3 col-md-offset-4">
            <img class="img-logo" src="assets/images/pp_logo.png" alt="Parcel Port" width="100%"/>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6 col-md-4 col-sm-offset-3 col-md-offset-4">
            <div class="login-panel panel panel-default whiteframe-10dp" *ngIf="!showResetInstructionMessage">
                <div class="panel-heading">
                    <div class="panel-title text-center"><strong>Reset Password verification</strong></div>
                </div>
                <div class="panel-body">
                    <form #resetPasswordVerificationForm="ngForm" role="form"
                          (ngSubmit)="resetPasswordVerificationForm.valid && onSubmit(verificationData)" >
                        <fieldset>
                            <div class="form-group"
                                 [ngClass]="{'has-error': (resetPasswordVerificationForm.submitted || email.touched) && !email.valid}">
                                <input class="form-control" placeholder="E-mail" name="email" type="text"
                                       [(ngModel)]="verificationData.email" #email="ngModel"
                                       [pattern]="emailRegEx" required/>
                                <div *ngIf="(resetPasswordVerificationForm.submitted || email.touched) && !email.valid">
                                    <small class="text-danger" *ngIf="email.errors['required']">
                                        Email is required.
                                    </small>
                                    <small class="text-danger" *ngIf="email.errors['pattern']">
                                        Email is invalid.
                                    </small>
                                </div>
                            </div>
                            <div class="form-group"  style="min-height: 85px" >
                                <re-captcha [siteKey]="captchaSiteKey"
                                            [(ngModel)]="verificationData.tokenRes"
                                            name="tokenRes"
                                            #tokenRes = "ngModel"
                                            required (resolved)="getCaptchaToken($event)"></re-captcha>
                                <div *ngIf="(resetPasswordVerificationForm.submitted || tokenRes.touched) && !tokenRes.valid">
                                    <small class="text-danger" *ngIf="tokenRes.errors['required']">
                                        Captcha is required.
                                    </small>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary btn-block">Send</button>
                        </fieldset>
                    </form>
                </div>
            </div>
            <div class="login-panel panel panel-default whiteframe-10dp" *ngIf="showResetInstructionMessage">
                <div class="panel-body alert-success text-center">
                    <p>
                        <i class="fa fa-check-circle" aria-hidden="true"></i> &nbsp; Please check your email for password reset instructions! <br><br>
                        <a [routerLink]="['/sign-in']">Click here</a> to sign-in using different account.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
