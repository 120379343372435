import {Pipe, PipeTransform} from '@angular/core';

/**
 * Add space before uppercase of string
 */
@Pipe({name: 'SpaceBeforeUppercase'})
export class SpaceBeforeUppercase implements PipeTransform {
    transform(value: string): string {
        const resultArr = [];
        const strArr = value.split('');
        strArr.forEach(str => {
           if (str === str.toUpperCase()) {
               str = ' ' + str;
           }
           resultArr.push(str);
        });
        const newValue = resultArr.toString().replace(/,/g, '').trim();
        return `${newValue}`;
    }
}
