import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Constant} from '../../common/constant';
import {User} from '../users/user';
import {CreditCardInfo} from './credit_card';

@Injectable()
export class PaymentDetailsService {

    constructor(private http: HttpClient) {
    }

    /**
     * Save card
     * @param {object} credit card info
     * @param {string} userId
     * */
    saveCard(creditCardInfo: string, userId: string) {
        return new Promise((resolve, reject) => {
            this.http
                .post(Constant.API_URL + 'api/user/' + userId + '/register-card', {data: creditCardInfo})
                .subscribe((response: User) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Delete card
     * @param {string} cardId
     * @param {string} userId
     * */
    deleteCard(cardId: string, userId: string): Promise<object> {
        return new Promise((resolve, reject) => {
            this.http
                .delete(Constant.API_URL + 'api/user/' + userId + '/card/' + cardId)
                .subscribe((response: object) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Get User by id
     * @param {string} userId
     * */
    getUserById(userId: string) {
        return new Promise((resolve, reject) => {
            this.http
                .get(Constant.API_URL + 'api/users/' + userId)
                .subscribe((response: User) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Get list of credit card by userId
     * @param {string} userId
     * */
    getCreditCards(userId: string) {
        return new Promise((resolve, reject) => {
            this.http
                .get(Constant.API_URL + 'api/user/' + userId + '/cards')
                .subscribe((response: User) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

}
