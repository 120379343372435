<div class="row page">
    <div class="col-lg-12">
        <h1 class="page-header">Lockers
            <button class="pull-right btn btn-primary" [routerLink]="['/lockers/add']"
                    *ngIf="currentUser.role.endsWith(CONSTANT.ROLE_ADMIN)">
                <i class="fa fa-plus m-r-4"></i> <span class="hidden-xs">New Locker</span>
            </button>
            <button class="pull-right btn btn-success m-r-2" (click)="fnExportCSV()" *ngIf="lockersArr.length" [disabled]="isExporting">
                <i class="fa fa-file-excel-o m-r-4"></i> <span>Export to CSV</span>
            </button>
        </h1>
    </div>
    <pp-spinner [isSpinnerLoad]="!isDataLoaded"></pp-spinner>
    <div class="col-lg-12" *ngIf="currentUser.role === CONSTANT.ROLE_ADMIN">
        <div class="row">
            <div class="col-sm-10">
                <div class="form-group">
                    <select id="company" class="form-control" name="company" [(ngModel)]="filterObj.companyId"
                            (change)="fnChangeFilters(filterObj)">
                        <option [ngValue]="item._id" *ngFor="let item of companiesArr">{{item.name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-sm-2">
                <button class="btn btn-primary btn-block" (click)="fnClearFilters()">Clear Filters</button>
            </div>
        </div>
    </div>
    <div class="col-lg-12 m-t-10">
        <div class="table-responsive" *ngIf="lockersArr.length">
            <!-- START : Lockers Table -->
            <form  #lockersForm="ngForm" novalidate>
                <table>
                    <thead>
                    <tr class="lockers-table-headers">
                        <th class="bg-primary" style="width: 33px">
                            <a class="m-l-4" href="javascript:void(0);" (click)="fnToggleAllCabinets()">
                                <span class="fa" [ngClass]="isAllCabinets ? 'fa-minus' : 'fa-plus'"></span></a></th>
                        <th class="bg-primary company-name" (click)="sortBy('companyId.name')">
                            Company <i [class]="setSortIcon('companyId.name')"></i></th>
                        <th class="bg-primary locker-number" (click)="sortBy('lockerNumber')">
                            Locker No. <i [class]="setSortIcon('lockerNumber')"></i></th>
                        <th class="bg-primary locker-name" (click)="sortBy('name')">
                            Name <i [class]="setSortIcon('name')"></i></th>
                        <th class="bg-primary country" (click)="sortBy('locationId.country')">
                            Country <i [class]="setSortIcon('locationId.country')"></i></th>
                        <th class="bg-primary postalCode" (click)="sortBy('locationId.postalCode')">
                            Postal Code <i [class]="setSortIcon('locationId.postalCode')"></i></th>
                        <th class="bg-primary is-online" (click)="sortBy('isOnline')">
                            Online <i [class]="setSortIcon('isOnline')"></i></th>
                        <th class="bg-primary cursor-auto">Locker ver.</th>
                        <th class="bg-primary cursor-auto">Middleware ver.</th>
                        <th class="bg-primary cursor-auto">App State</th>
                        <th class="bg-primary cursor-auto">Total Cabinets</th>
                        <th class="bg-primary cursor-auto">Total Slots</th>
                        <th class="bg-primary cursor-auto" [style.width]="'90px'" *ngIf="currentUser.role.endsWith(CONSTANT.ROLE_ADMIN)">Locker Logs</th>
                        <th class="bg-primary cursor-auto" *ngIf="currentUser.role.endsWith(CONSTANT.ROLE_ADMIN)">Action&nbsp;</th>
                    </tr>
                    </thead>
                    <tbody>
                    <ng-template ngFor let-locker [ngForOf]="lockersArr" let-i="index">
                        <tr>
                            <td><a class="m-l-4" href="javascript:void(0);" (click)="fnToggleCabinets(locker)">
                                <i class="fa" [ngClass]="locker.isCabinets ? 'fa-minus' : 'fa-plus'"></i></a></td>
                            <td>{{locker.companyId ? locker.companyId.name : '&nbsp;'}}</td>
                            <td>{{locker.lockerNumber ? locker.lockerNumber : '&nbsp;'}}</td>
                            <td>
                                <a [routerLink]="['/lockers/'+locker._id]">
                                    {{locker.name ? locker.name : '&nbsp;'}}
                                </a>
                            </td>
                            <td>{{locker.locationId.country ? locker.locationId.country : '&nbsp;'}}</td>
                            <td>{{locker.locationId.postalCode ? locker.locationId.postalCode : '&nbsp;'}}</td>
                            <td>{{locker.isOnline ? 'Yes' : 'No'}}</td>
                            <td>{{locker.lastKnownVersion || ' '}}</td>
                            <td>{{locker.middlewareVersion || ' '}}</td>
                            <td>{{locker.appState || ' '}}</td>
                            <td>{{locker.cabinets.length}}</td>
                            <td>{{locker.totalSlots}}</td>
                            <td *ngIf="currentUser.role.endsWith(CONSTANT.ROLE_ADMIN)">
                                <li class="dropdown">
                                    <a class="dropdown-toggle" data-toggle="dropdown" href="#" [style.text-decoration]="'none'">
                                        View Logs <i class="fa fa-caret-down"></i>
                                    </a>
                                    <ul class="dropdown-menu dropdown-position" [ngClass]="{ 'dropdown-position-last': i===lockersArr.length-1 }" [style.min-width]="0">
                                        <li>
                                            <a href="javascript:void(0);" [routerLink]="['/locker-logs/'+locker._id]">
                                                Regular &nbsp; <i class="fa fa-history" [ngStyle]="{'color': (locker.isOnline) ? '#001f89' : 'red'}"></i></a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);" [routerLink]="['/locker-logs/'+locker._id, {fetchFromServer: true}]">
                                                Interval &nbsp; (1 min) </a>
                                        </li>
                                    </ul>
                                </li>
                            </td>
                            <td *ngIf="currentUser.role.endsWith(CONSTANT.ROLE_ADMIN)">
                                <div class="btn-group btn-group-xs">
                                    <button type="button" class="btn btn-primary" [routerLink]="['/lockers/'+locker._id]">
                                        <i class="fa fa-edit"></i>
                                    </button>
                                    <button type="button" class="btn btn-danger" (click)="fnDeleteLocker(locker._id)">
                                        <i class="fa fa-trash-o"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>


                    <!-- Start : Access codes Table -->
                    <tr class="access-codes-row" width="100%" *ngIf="locker.hasAccessCodes && currentUser.role.endsWith(CONSTANT.ROLE_ADMIN)">
                        <td></td>
                        <td colspan="12">
                            <table width="100%">
                                <thead>
                                <tr width="100%">
                                    <th class="bg-primary cursor-auto" colspan="12">
                                       Front Desk Access Codes
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr width="100%">
                                    <td *ngFor="let code of locker.accessCodes; let j = index; trackBy: trackByFn"
                                        [width]="(100/locker.accessCodes.length)+'%'">
                                        <input type="text" name="access_code_{{i}}_{{j}}" #accessCodeInput="ngModel"
                                               [(ngModel)]="locker.accessCodes[j]"
                                               placeholder="Enter access code {{j+1}}"
                                               [disabled]="!selectedLocker || (locker._id !== selectedLocker._id || ( locker._id === selectedLocker._id && j !== selectedAccessCodeIndex))"
                                               maxlength="30" [pattern]="alphaNumRegEx"/>
                                        <button type="button" class="btn btn-primary btn-sm rounded-pill"
                                                (click)="toggleAccessCodeSaveBtn(locker, j)"
                                                *ngIf="!selectedLocker || (locker._id !== selectedLocker._id || ( locker._id === selectedLocker._id && j !== selectedAccessCodeIndex))">
                                            Edit
                                        </button>
                                        <button type="button" class="btn btn-success btn-sm rounded-pill"
                                                (click)="saveAccessCodes(locker, j)"
                                                *ngIf="selectedLocker && locker._id === selectedLocker._id && j === selectedAccessCodeIndex"
                                                [disabled]="!accessCodeInput.valid">
                                            Save
                                        </button>
                                        <button class="btn btn-warning btn-sm rounded-pill"
                                                *ngIf="selectedLocker && locker._id === selectedLocker._id && j === selectedAccessCodeIndex" (click)="cancelAccessCode()">
                                            Cancel
                                        </button>
                                        <div
                                            *ngIf="!accessCodeInput.valid && !accessCodeInput.disabled">
                                            <small class="text-danger">Access codes must be alpha-numeric</small>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <!-- End : Access codes Table -->

                    <!-- Start : Return pickup codes Table -->
                    <tr class="access-codes-row" width="100%" *ngIf="locker.hasReturnPickupCode && currentUser.role.endsWith(CONSTANT.ROLE_ADMIN)">
                        <td></td>
                        <td colspan="12">
                            <table width="100%">
                                <thead>
                                <tr width="100%">
                                    <th class="bg-primary cursor-auto" >
                                        Front Desk Expired Shipment Pickup Code
                                    </th>
                                    <th class="bg-primary cursor-auto" *ngIf="locker?.isOutboundEnabled">
                                        Product Return / Outbound Shipment Pickup Code
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr width="100%">
                                    <td [width]="locker.isOutboundEnabled ? '50%' : '100%'">
                                        <input id="returnPickupCode" type="text" class="input-sm" [ngClass]="locker.isOutboundEnabled ? 'w-55' : 'w-20'"
                                                   name="returnPickupCode_{{locker._id}}" #returnPickupCode="ngModel"
                                                   [disabled]="!selectedLocker || (selectedLocker && ((selectedLocker._id !== locker._id) || (selectedLocker._id === locker._id && !toggleReturnPickup)))"
                                                   [pattern]="'(^$)|(^[0-9]{6}$)'"
                                                   placeholder="Enter expired shipment pickup code"
                                                   [(ngModel)]="locker.returnPickupCode" autocomplete="off"/>
                                        <button type="button" class="btn btn-primary btn-sm rounded-pill"
                                                *ngIf="!selectedLocker || (selectedLocker && (selectedLocker._id !== locker._id || (selectedLocker._id === locker._id && !toggleReturnPickup)))"
                                                (click)="toggleReturnPickupCodeBtn(locker)">
                                            Edit
                                        </button>
                                        <button type="button" class="btn btn-success btn-sm rounded-pill"
                                                [disabled]="!selectedLocker || (selectedLocker && ((selectedLocker._id !== locker._id) || (selectedLocker._id === locker._id && !returnPickupCode.valid)))"
                                                *ngIf="selectedLocker && selectedLocker._id === locker._id && toggleReturnPickup"
                                                (click)="saveReturnPickupCode(locker)">
                                            Save
                                        </button>
                                        <button class="btn btn-warning btn-sm rounded-pill"
                                                *ngIf="selectedLocker && selectedLocker._id === locker._id && toggleReturnPickup" (click)="cancelReturnPickupCode()">
                                            Cancel
                                        </button>
                                        <div *ngIf="!returnPickupCode.valid && !returnPickupCode.disabled">
                                            <p class="m-t-0 m-b-0">
                                                <small class="text-danger">Expired shipment return pickup code must be 6 digits</small>
                                            </p>
                                        </div>
                                    </td>
                                    <td width="50%" *ngIf="locker?.isOutboundEnabled">
                                        <input id="productReturnPickupCode" type="text" class="input-sm w-55" #productReturnPickupCode="ngModel"
                                               name="productReturnPickupCode_{{locker._id}}"
                                               [disabled]="!selectedLocker || (selectedLocker && ((selectedLocker._id !== locker._id) || !toggleProductReturnPickup))"
                                               [pattern]="'(^$)|(^[0-9]{6}$)'"
                                               placeholder="Enter product return/ outbound shipment pickup code"
                                               [(ngModel)]="locker.productReturnPickupCode" autocomplete="off"/>
                                        <button type="button" class="btn btn-primary btn-sm rounded-pill"
                                                *ngIf="!selectedLocker || (selectedLocker && (selectedLocker._id !== locker._id || (selectedLocker._id === locker._id && !toggleProductReturnPickup)))"
                                                (click)="toggleProductReturnPickupCode(locker)">
                                            Edit
                                        </button>
                                        <button type="button" class="btn btn-success btn-sm rounded-pill"
                                                [disabled]="!productReturnPickupCode.valid"
                                                *ngIf="selectedLocker && selectedLocker._id === locker._id && toggleProductReturnPickup"
                                                (click)="saveProductReturnPickupCode(locker)">
                                            Save
                                        </button>
                                        <button class="btn btn-warning btn-sm rounded-pill"
                                                *ngIf="selectedLocker && selectedLocker._id === locker._id && toggleProductReturnPickup" (click)="cancelProductReturnPickupCode()">
                                            Cancel
                                        </button>
                                        <div *ngIf="!productReturnPickupCode.valid && !productReturnPickupCode.disabled">
                                            <p class="m-t-0 m-b-0">
                                                <small class="text-danger">Product return / outbound shipment pickup code must be 6 digits</small>
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <!-- End : Return pickup codes Table -->

                    <!-- START : Cabinets Table -->
                    <tr class="cabinets-row" *ngIf="locker.isCabinets">
                        <td></td>
                        <td colspan="12">
                            <table *ngIf="locker.cabinets.length">
                                <thead>
                                <tr>
                                    <th class="bg-primary" style="width: 33px">
                                        <a class="m-l-4" href="javascript:void(0);" (click)="fnToggleAllSlots(locker)">
                                            <span class="fa"
                                                  [ngClass]="locker.isAllSlots ? 'fa-minus' : 'fa-plus'"></span>
                                            </a>
                                        </th>
                                        <th class="bg-primary cursor-auto">Cabinet Type</th>
                                        <th class="bg-primary cursor-auto">Cabinet Number</th>
                                        <th class="bg-primary cursor-auto">Available Slots</th>
                                        <th class="bg-primary cursor-auto">Total Slots</th>
                                        <th class="bg-primary cursor-auto" *ngIf="currentUser.role.endsWith(CONSTANT.ROLE_ADMIN)">
                                            Action
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <ng-template ngFor let-cabinet [ngForOf]="locker.cabinets" let-j="index">
                                        <tr>
                                            <td><a class="m-l-4" href="javascript:void(0);"
                                                   (click)="fnToggleSlots(locker, cabinet)">
                                                <i class="fa" [ngClass]="cabinet.isSlots ? 'fa-minus' : 'fa-plus'"></i></a>
                                            </td>
                                            <td>{{(cabinet.type ? cabinet.type : '&nbsp;') | uppercase}}</td>
                                            <td>{{cabinet.cabinetNumber ? cabinet.cabinetNumber : '&nbsp;'}}</td>
                                            <td>{{cabinet.totAvailableSlots}}</td>
                                            <td>{{cabinet.slots.length}}</td>
                                            <td *ngIf="currentUser.role.endsWith(CONSTANT.ROLE_ADMIN)">
                                                <div class="btn-group btn-group-xs">
                                                    <button type="button" class="btn btn-primary"
                                                            [routerLink]="['/cabinets/'+cabinet._id]">
                                                        <i class="fa fa-edit"></i>
                                                    </button>
                                                    <button type="button" class="btn btn-danger"
                                                            (click)="deleteCabinet(cabinet._id, locker.cabinets)">
                                                        <i class="fa fa-trash-o"></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                        <!-- START : Slots Table -->
                                        <tr class="slots-row" *ngIf="cabinet.isSlots">
                                            <td></td>
                                            <td colspan="5">
                                                <table *ngIf="cabinet.slots.length">
                                                    <thead>
                                                    <tr>
                                                        <th class="bg-primary cursor-auto" width="10%">Slot Number</th>
                                                        <th class="bg-primary cursor-auto" width="10%">Size</th>
                                                        <th class="bg-primary cursor-auto" width="20%">Status</th>
                                                        <th class="bg-primary cursor-auto" width="10%">Upper Slot</th>
                                                        <th class="bg-primary cursor-auto" width="10%">Door</th>
                                                        <th class="bg-primary cursor-auto" width="10%">Occupancy</th>
                                                        <th class="bg-primary cursor-auto" width="15%">Shipment</th>
                                                        <th class="bg-primary cursor-auto" *ngIf="currentUser.role.endsWith(CONSTANT.ROLE_ADMIN)">View Status</th>
                                                        <th class="bg-primary cursor-auto" width="15%"
                                                            *ngIf="currentUser.role.endsWith(CONSTANT.ROLE_ADMIN)">Action
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr *ngFor="let slot of cabinet.slots">
                                                        <td width="10%">{{slot.slotNumber}}</td>
                                                        <td width="10%">{{slot.type | RemoveUnderscore}}</td>
                                                        <td width="20%">{{slot.status | RemoveUnderscore}}</td>
                                                        <td width="10%">{{slot.isUpperSlot ? 'Yes' : 'No'}}</td>
                                                        <td width="10%">{{(slot.open !== undefined) ? slot.open ? 'Open' : 'Close' : ''}}</td>
                                                        <td width="10%">{{(slot.occupied !== undefined) ? slot.occupied ? 'Yes' : 'No' : ''}}</td>
                                                        <td width="15%">
                                                            <div
                                                                *ngIf="((slot.occupied !== undefined) && slot.occupied) || (slot.packageId.shipmentId !== '' && (slot.packageId.shipmentId | json) !== '{}')">
                                                                <a [routerLink]="['/shipments/'+slot.packageId.shipmentId._id]">{{ slot.packageId.label }}</a>
                                                            </div>
                                                        </td>
                                                        <td *ngIf="currentUser.role.endsWith(CONSTANT.ROLE_ADMIN)" width="8%">
                                                            <div class="btn-group btn-group-md" >
                                                                <button type="button" class="btn btn-default" (click)="showStatus(StatusModal, slot.slotNumber, cabinet.cabinetNumber, locker?._id)">
                                                                    <i class="fa fa-info-circle"></i>
                                                                </button>
                                                            </div>
                                                        </td>
                                                        <td *ngIf="currentUser.role.endsWith(CONSTANT.ROLE_ADMIN)" width="15%">
                                                            <div class="btn-group btn-group-xs">
                                                                <button type="button" class="btn btn-primary"
                                                                        [routerLink]="['/slots/'+slot._id]"
                                                                        *ngIf="slot.status === CONSTANT.SLOT_STATUS_AVAILABLE
                                                                    || slot.status === CONSTANT.SLOT_STATUS_REPAIR
                                                                    || slot.status === CONSTANT.SLOT_STATUS_RESERVED_FOR_HARDWARE
                                                                    || slot.status === CONSTANT.SLOT_STATUS_CLOSE_AND_PURGED">
                                                                    <i class="fa fa-edit"></i>
                                                                </button>
                                                                <button type="button" class="btn btn-danger"
                                                                        *ngIf="slot.status === CONSTANT.SLOT_STATUS_AVAILABLE"
                                                                        (click)="deleteSlot(slot._id, cabinet.slots)">
                                                                    <i class="fa fa-trash-o"></i>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                                <h5 *ngIf="!cabinet.slots.length" class="text-center">No slots found.</h5>
                                            </td>
                                        </tr>
                                        <!-- END : Slots Table -->
                                    </ng-template>
                                    </tbody>
                                </table>
                                <h5 *ngIf="!locker.cabinets.length" class="text-center">No cabinets found.</h5>
                            </td>
                        </tr>
                        <!-- END : Cabinets Table -->
                    </ng-template>
                    </tbody>
                </table>
            </form>
            <!-- END : Lockers Table -->
        </div>
        <h3 *ngIf="isDataLoaded && !lockersArr.length" class="text-center">No lockers found.</h3>
        <div class="col-lg-12 text-center" *ngIf="lockersArr.length">
            <pagination [totalItems]="totalLockers" [(ngModel)]="filterObj.page" [itemsPerPage]="filterObj.limit"
                        [boundaryLinks]="true" [maxSize]="5" (pageChanged)="fnPageChanged($event)"></pagination>
        </div>
    </div>
    <ng-template #StatusModal>
        <div class="modal-header">
            <h4 class="modal-title pull-left">
                <b>Slot Status: {{ slotDetails?.boxId }}</b>
            </h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="closeStatusModal();">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p *ngIf="slotStatus">
                <b>Open:</b> {{ slotStatusResp?.isOpened }}
            </p>
            <p *ngIf="slotStatus">
                <b>Storage:</b> {{ slotStatusResp?.isStorage }}
            </p>
            <pp-spinner [isSpinnerLoad]="!slotStatus"></pp-spinner>
        </div>
    </ng-template>
</div>
