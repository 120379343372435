<div class="row page">
    <div class="col-lg-12">
        <h1 class="page-header">Pending Shipments
            <button class="pull-right btn btn-primary" *ngIf="currentUser.role.endsWith(CONSTANT.ROLE_ADMIN)" [routerLink]="['/pending-shipments/add']">
                <i class="fa fa-plus m-r-4"></i> <span class="hidden-xs">New Pending Shipment</span>
            </button>
            <button class="pull-right btn btn-success m-r-2" (click)="generateReport()" *ngIf="isReportAllowed && currentUser && currentUser.role === CONSTANT.ROLE_COMPANY_ADMIN" [disabled]="isExporting">
                <i class="fa fa-file-excel-o m-r-4"></i> <span>Generate Report</span>
            </button>
        </h1>
    </div>
    <pp-spinner [isSpinnerLoad]="!isDataLoaded"></pp-spinner>
    <div class="col-lg-12">
        <div class="row">
            <div class="col-sm-1">
                <div class="form-group">
                    <select id="limit" class="form-control" name="pageLimit" [(ngModel)]="filterObj.limit" (change)="getShipments(filterObj)">
                        <option [ngValue]="item" *ngFor="let item of perPageArr">{{item}}</option>
                    </select>
                </div>
            </div>
            <div class="col-sm-2" *ngIf="currentUser.role === USER_ROLE.ADMIN">
                <div class="form-group">
                    <select id="company" class="form-control" name="company" [(ngModel)]="filterObj.companyId"
                            (change)="changeCompany()">
                        <option [ngValue]="item._id" *ngFor="let item of companiesArr">{{item.name}}</option>
                    </select>
                </div>
            </div>
            <div [ngClass]="{'col-sm-2': currentUser.role === USER_ROLE.ADMIN, 'col-sm-4': currentUser.role === USER_ROLE.COMPANY_ADMIN}">
                <div class="form-group">
                    <select id="locker" class="form-control" name="locker" [(ngModel)]="filterObj.lockerId"
                            (change)="changeLocker(filterObj)">
                        <option [ngValue]="item._id" *ngFor="let item of lockersArr">{{item.name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-sm-2">
                <div class="form-group">
                    <ng-template #customItemTemplate let-model="item" let-index="index">
                        {{model.firstName}}&nbsp;{{model.lastName}}
                    </ng-template>
                    <input id="users" name="users" class="form-control" autocomplete="off" [(ngModel)]="selectedUser" [typeahead]="dataSource"
                           (typeaheadOnSelect)="onSelectUser($event)" [typeaheadItemTemplate]="customItemTemplate" [typeaheadAsync]="true"
                           [typeaheadScrollable]="true" [typeaheadOptionsLimit]="1000" container="body" [typeaheadMinLength]="0" autocomplete="off"
                           [typeaheadOptionsInScrollableView]="10" (blur)="typeAheadOnBlur($event)" placeholder="Select User">
                </div>
            </div>
            <div class="col-sm-3">
                <div class="form-group">
                    <input type="text" placeholder="Search By Tracking #" class="form-control" [(ngModel)]="filterObj.trackingNumber"
                           (blur)="onLabelInput()">
                </div>
            </div>
            <div class="col-sm-2">
                <button class="btn btn-primary btn-block" (click)="clearFilters()">Clear Filters</button>
            </div>
        </div>
    </div>
    <div class="col-lg-12">
        <div *ngIf="isDataLoaded && shipmentArr.length">
            <table class="shipments-table">
                <thead>
                <tr class="row">
                    <th class="bg-primary" (click)="sortBy('companyId.name')">Company Name <i [class]="setSortIcon('companyId.name')"></i></th>
                    <th class="bg-primary" (click)="sortBy('lockerId.name')">Locker Name  <i [class]="setSortIcon('lockerId.name')"></i></th>
                    <th class="bg-primary courier" (click)="sortBy('carrierCode')">
                        Courier <i [class]="setSortIcon('carrierCode')"></i>
                    </th>
                    <th class="bg-primary tracking-number cursor-auto">
                        Tracking Number
                    </th>
                    <th class="bg-primary receiver" (click)="sortBy('receiver.firstName')">
                        Consignee Name <i [class]="setSortIcon('receiver.firstName')"></i>
                    </th>
                    <th class="bg-primary receiver" (click)="sortBy('receiver.email')">
                        Consignee Email <i [class]="setSortIcon('receiver.email')"></i>
                    </th>
                    <th class="bg-primary receiver cursor-auto">
                        Consignee Phone
                    </th>
                    <th class="bg-primary created-at" (click)="sortBy('createdAt')">
                        Created At <i [class]="setSortIcon('createdAt')"></i>
                    </th>
                    <th class="bg-primary cursor-auto">
                        Action
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let shipment of shipmentArr" class="row">
                    <td>{{shipment?.companyId?.name}}</td>
                    <td>{{shipment?.lockerId?.name}}</td>
                    <td>{{shipment.carrierCode}}</td>
                    <td>
                        <p class="m-b-0">
                            {{shipment.parcel ? shipment.parcel.trackingNumber : ''}}
                        </p>
                        <small *ngIf="shipment.parcel && shipment.parcel.trackingNumber && shipment.parcel.trackingNumber !== ' ' " (click)="getBarcode(shipment.parcel.trackingNumber)" style="white-space: pre-wrap; cursor: pointer"><a>View Tracking Barcode</a></small>
                    </td>
                    <td>{{shipment.receiver && shipment.receiver.firstName && shipment.receiver.lastName ?
                        shipment.receiver.firstName + ' ' + shipment.receiver.lastName : ''}}</td>
                    <td>{{shipment.receiver ? shipment.receiver.email : ''}}</td>
                    <td>{{shipment.receiver ? shipment.receiver.phone : ''}}</td>
                    <td>{{shipment.createdAt | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
                    <td>
                        <div class="btn-group btn-group-xs">
                            <button type="button" class="btn btn-danger" *ngIf="currentUser.role.endsWith(CONSTANT.ROLE_ADMIN)"
                                    (click)="deletePendingShipment(shipment._id)">
                                <i class="fa fa-trash-o"></i>
                            </button>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <h3 *ngIf="isDataLoaded && !shipmentArr.length" class="text-center">
            No pending shipments found.
        </h3>
        <div class="col-lg-12 text-center" *ngIf="shipmentArr.length">
            <pagination [totalItems]="totShipments" [(ngModel)]="filterObj.page" [itemsPerPage]="filterObj.limit"
                        [boundaryLinks]="true"
                        [maxSize]="5" (pageChanged)="pageChanged($event)"></pagination>
        </div>
    </div>
</div>
