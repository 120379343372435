<div id="wrapper">
    <!-- Navigation -->
    <nav class="navbar navbar-default navbar-fixed-top" role="navigation" style="margin-bottom: 0">
        <div class="navbar-header pull-left">
            <a class="navbar-brand p-0" href="javascript:void(0);">
                <img class="navbar-logo" src="assets/images/pp_logo.png" alt="Parcel Port"/>
            </a>
        </div>
        <!-- /.navbar-header -->

        <ul class="nav navbar-top-links navbar-right pull-right">
            <li class="dropdown">
                <a class="dropdown-toggle" data-toggle="dropdown" href="javascript:void(0);">
                    <i class="fa fa-user fa-fw"></i> {{this.currentUser.fullName}} <i class="fa fa-caret-down"></i>
                </a>
                <ul class="dropdown-menu">
                    <li>
                        <a href="javascript:void(0);" (click)="fnSignOut()">
                            <i class="fa fa-sign-out fa-fw"></i> Sign out</a>
                    </li>
                </ul>
                <!-- /.dropdown-user -->
            </li>
            <!-- /.dropdown -->
        </ul>
        <!-- /.navbar-top-links -->
    </nav>
    <!-- /.navbar-static-side -->
    <div id="page-wrapper">
        <div class="row">
            <div class="col-sm-6 col-md-4 col-sm-offset-3 col-md-offset-4">
                <div class="panel panel-default whiteframe-10dp m-t-50">
                    <div class="panel-heading">
                        <div class="panel-title text-center"><strong>Validate OTP (One Time Passcode)</strong></div>
                    </div>
                    <div class="panel-body">
                        <form #otpForm="ngForm" role="form" (ngSubmit)="otpForm.valid && fnVerifyOTP(otp)">
                            <fieldset>
                                <p>A One Time Verification Code has been sent to your <strong>email</strong>
                                    and <strong>phone</strong>.</p>
                                <p>Please enter the <strong>OTP</strong> below to complete your sign-in. If you can't
                                    see
                                    the email in your inbox, make sure to check your SPAM folder.</p>
                                <div class="form-group"
                                     [ngClass]="{'has-error': (otpForm.submitted || otpInput.touched) && !otpInput.valid}">
                                    <input class="form-control input-lg" placeholder="Eg.384957" name="otp"
                                           type="text"
                                           [(ngModel)]="otp" #otpInput="ngModel" #otpRef required/>
                                    <div *ngIf="(otpForm.submitted || otpInput.touched) && !otpInput.valid">
                                        <small class="text-danger" *ngIf="otpInput.errors['required']">
                                            OTP is required.
                                        </small>
                                    </div>
                                </div>
                                <!-- Change this to a button or input when using this as a form -->
                                <button type="submit" class="btn btn-primary btn-block">Validate OTP</button>
                            </fieldset>
                        </form>
                    </div>
                    <div class="panel-footer text-center">
                        <a href="javascript:void(0);" (click)="fnResendOTP()">Resend OTP</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
