import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Constant} from '../../common/constant';

@Injectable()
export class FeedbackService {

    constructor(private http: HttpClient) {
    }

    /**
     * Get list of feedback
     * @param {object} params
     * */
    fnGetAllFeedback(params?: object) {
        let urlSearchParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key) && params[key]) {
                urlSearchParams = urlSearchParams.append(key, params[key]);
            }
        }
        const qString = urlSearchParams.toString();
        return new Promise((resolve, reject) => {
            this.http
                .get(Constant.API_URL + 'api/feedback' + (qString ? '?' + qString : ''))
                .subscribe((response: any) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Get feedback
     * @param {string} id
     * */
    fnGetFeedback(id: string) {
        return new Promise((resolve, reject) => {
            this.http
                .get(Constant.API_URL + 'api/feedback/' + id)
                .subscribe((response: any) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Create new feedback
     * @param {object} feedbackObj
     * */
    fnCreateFeedback(feedbackObj: any) {
        return new Promise((resolve, reject) => {
            this.http
                .post(Constant.API_URL + 'api/feedback', feedbackObj)
                .subscribe((response: any) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Update feedback
     * @param {object} feedbackObj
     * */
    fnUpdateFeedback(feedbackObj: any) {
        return new Promise((resolve, reject) => {
            this.http
                .put(Constant.API_URL + 'api/feedback/' + feedbackObj._id, feedbackObj)
                .subscribe((response: any) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Delete feedback
     * @param {string} id
     **/
    fnDeleteFeedback(id: string) {
        return new Promise((resolve, reject) => {
            this.http
                .delete(Constant.API_URL + 'api/feedback/' + id)
                .subscribe((response) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }


}
