import {Component, OnInit} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import * as _ from 'lodash';

import {DeleteModalComponent} from '../../delete-modal/delete-modal.component';
import {AuthService} from '../../common/auth.service';
import {StorageService} from '../../common/storage.service';
import {SlotsService} from './slots.service';
import {CompaniesService} from '../companies/companies.service';
import {LockersService} from '../lockers/lockers.service';
import {CabinetsService} from '../cabinets/cabinets.service';
import {Slot} from './slot';
import {Company} from '../companies/company';
import {Locker} from '../lockers/locker';
import {Cabinet} from '../cabinets/cabinet';
import {User} from '../users/user';
import {Constant} from '../../common/constant';
import {ToastrService} from '../../common/toastr.service';
import {ExportService} from '../../common/export.service';
import {SearchResults} from '../../common/search-results';
import {SocketIOService} from '../../common/socketio.service';

@Component({
    selector: 'pp-slots',
    templateUrl: './slots.component.html',
    styleUrls: ['./slots.component.css']
})
export class SlotsComponent implements OnInit {

    public moment: any = moment;
    public isDataLoaded;
    public isExporting: boolean;
    public filterObj: any;
    public currentUser: User;
    public slotsArr: Array<Slot> = [];
    public lockersArr: Array<Locker> = [{_id: '', name: 'Select Locker'}];
    public companiesArr: Array<Company> = [{_id: '', name: 'Select Company'}];
    public cabinetsArr: Array<Cabinet> = [{_id: '', cabinetNumber: ''}];
    readonly CONSTANT = Constant;
    private SLOTS_FILTERS = 'slotsFilter';
    public totSlots: number;
    public slotInfo: { slotNumber: string, reason: string, status: string, reasonDate: Date };
    public slotReasonModelRef: BsModalRef;
    // public slotStatusResp: any;
    // public slotStatusModelRef: BsModalRef;
    // public slotOpenModelRef: BsModalRef;
    // public otpModelRef: BsModalRef;
    // public slotDetails: { boxId: string, lockerId: string, slotOpenReason?: string};
    // public slotStatus: any;
    // public slotOpenPassword: string;
    // public slotOpenReason: string;
    // public SlotOpenRequestotp: string;
    // public slotStatusInterval: number = null;
    // public slotResponse: any;

    constructor(private _bsModalService: BsModalService, private slotsService: SlotsService,
                private auth: AuthService, private cabinetsService: CabinetsService,
                private lockersService: LockersService, private companiesService: CompaniesService,
                private storage: StorageService, private toastr: ToastrService, private exportService: ExportService, private socketIOService: SocketIOService) {
        this.currentUser = this.auth.fnGetCurrentUser();
        this.isDataLoaded = false;
        this.isExporting = false;
        this.fnGetSlotsFilters();
    }

    ngOnInit() {
        this.fnGetCompanies();
        // this.socketIOService.connectSocket();
        this.fnGetSlots(this.filterObj);
    }

    /**
     * Get local storage data
     * */
    fnGetSlotsFilters() {
        const SLOTS_FILTERS = this.storage.fnGetData(this.SLOTS_FILTERS);
        this.filterObj = _.isEmpty(SLOTS_FILTERS) ? {
            companyId: '',
            lockerId: '',
            cabinetId: '',
            sortBy: 'lockerId.name',
            orderBy: 'asc',
            page: 1,
            limit: 20
        } : SLOTS_FILTERS;
    }

    /**
     * Clear filter
     * */
    fnClearFilters() {
        this.storage.fnRemove(this.SLOTS_FILTERS);
        this.fnGetSlotsFilters();
        if (this.currentUser.role === Constant.ROLE_ADMIN) {
            this.lockersArr = [{_id: '', name: 'Select Locker'}];
        }
        this.filterObj.sortBy = 'lockerId.name';
        this.filterObj.orderBy = 'asc';
        this.fnChangeFilters(this.filterObj);
    }

    /**
     * Filter change.
     * @param {object} params
     * */
    fnChangeFilters(params?: object) {
        this.filterObj.page = 1;
        this.filterObj.limit = 20;
        this.storage.fnStoreData(this.SLOTS_FILTERS, params);
        this.fnGetSlots(params);
    }

    /**
     * Set column sort icon
     * @param {string} sortBy
     * */
    setSortIcon(sortBy: string) {
        if (this.filterObj.sortBy === sortBy) {
            return this.filterObj.orderBy === 'desc' ? 'fa fa-sort-desc' : 'fa fa-sort-asc';
        }
        return 'fa fa-sort';
    }

    /**
     * Sort by column name
     * @param {string} sortBy
     * */
    sortBy(sortBy: string) {
        if (this.filterObj.sortBy === sortBy) {
            this.filterObj.sortBy = sortBy;
            if (this.filterObj.orderBy === 'asc') {
                this.filterObj.orderBy = 'desc';
            } else if (this.filterObj.orderBy === 'desc') {
                this.filterObj.sortBy = 'lockerId.name';
                this.filterObj.orderBy = 'asc';
            } else {
                this.filterObj.orderBy = 'asc';
            }
        } else {
            this.filterObj.orderBy = 'asc';
            this.filterObj.sortBy = sortBy;
        }

        this.slotsService.fnGetSlots(this.filterObj)
            .then((res: SearchResults<Slot>) => {
                this.isDataLoaded = true;
                this.totSlots = res.total;
                this.slotsArr = res.data;
            });
    }

    /**
     * Get companies
     * */
    fnGetCompanies() {
        this.companiesService.fnGetCompanies({limitData: true})
            .then((companiesArr: Array<Company>) => {
                this.companiesArr = companiesArr;
                this.companiesArr.unshift({_id: '', name: 'Select Company'});
                if (this.currentUser.role === Constant.ROLE_ADMIN) {
                    if (this.filterObj.companyId) {
                        this.fnGetLockers({companyId: this.filterObj.companyId});
                    }
                } else {
                    this.filterObj.companyId = this.currentUser.companyId;
                    this.fnGetLockers({companyId: this.filterObj.companyId});
                }
            });
    }

    /**
     * Get lockers
     * */
    async fnGetLockers(params?: object) {
        this.lockersArr = [{_id: '', name: 'Select Locker'}];
        this.cabinetsArr = [{_id: '', cabinetNumber: ''}];
        if (params['companyId']) {
            this.lockersArr = <Array<Locker>> await this.lockersService.getLockersNameAndNumber(params['companyId']);
            this.lockersArr.unshift({_id: '', name: 'Select Locker'});
            if (this.filterObj.lockerId) {
                this.fnGetCabinets({lockerId: this.filterObj.lockerId});
            }
        }
    }

    /**
     * Get cabinets
     * */
    fnGetCabinets(params) {
        this.cabinetsArr = [{_id: '', cabinetNumber: ''}];
        if (params['lockerId']) {
            this.cabinetsService.fnGetCabinets(params)
                .then((resp: SearchResults<Cabinet>) => {
                    this.cabinetsArr = [];
                    this.cabinetsArr = resp.data;
                    this.cabinetsArr.unshift({_id: '', cabinetNumber: ''});
                });
        }
    }

    /**
     * Get list of slots
     * */
    fnGetSlots(params?: object) {
        this.isDataLoaded = false;
        this.slotsService.fnGetSlots(params)
            .then((res: SearchResults<Slot>) => {
                this.isDataLoaded = true;
                this.totSlots = res.total;
                this.slotsArr = res.data;
            });
    }

    fnPageChanged(event: any) {
        this.filterObj.page = event.page;
        this.fnGetSlots(this.filterObj);
    }

    /**
     * Delete slot
     * @param {string} id
     * */
    fnDeleteSlot(id: string) {
        const modal = this._bsModalService.show(DeleteModalComponent, {'class': 'modal-sm'});
        (<DeleteModalComponent>modal.content).showConfirmationModal(
            'Delete',
            'Are you sure you want to delete this slot?'
        );

        (<DeleteModalComponent>modal.content).onClose.subscribe(async result => {
            if (result) {
                try {
                    await this.slotsService.fnDeleteSlot(id);
                    this.toastr.fnSuccess('Slot deleted successfully.');
                    const deleteIndex = this.slotsArr.findIndex(slot => slot._id === id);
                    this.slotsArr.splice(deleteIndex, 1);
                } catch (error) {
                    this.toastr.fnError(error.message);
                }
            }
        });
    }

    fnExportCSV() {
        this.isExporting = true;
        this.slotsService.fnGetSlots({ page: 1, limit: 10000 })
            .then((res: any) => {
                const slots = res.data;
                const csvArr = [];
                _.forEach(slots, element => {
                    if (element.cabinetId) {
                        element.cabinet = element.cabinetId.type && element.cabinetId.cabinetNumber
                            ? element.cabinetId.type + ' - ' + element.cabinetId.cabinetNumber : '';
                    } else {
                        element.cabinet = '';
                    }
                    if (element.packageId && element.packageId.shipmentId && element.packageId.shipmentId.status === Constant.SHIPMENT_STATUS_EXPIRED) {
                        element.status = Constant.SHIPMENT_STATUS_EXPIRED;
                    }
                    csvArr.push(element);
                });
                const headers = ['companyId.name', 'lockerId.name', 'cabinet', 'slotNumber', 'type', 'status',
                'updatedAt', 'createdAt'];
                this.exportService.fnDownloadCSV('Slots', csvArr, headers);
                this.isExporting = false;
            });
    }

    showReason(template: any, slotInfo: Slot) {
        this.slotInfo = {
            slotNumber: slotInfo.cabinetId.cabinetNumber + slotInfo.slotNumber,
            reason: slotInfo.reason || `No ${Constant.REASON_MODAL_TITLE[slotInfo.status].toLowerCase()} reason added.`,
            status: slotInfo.status,
            reasonDate: slotInfo.reasonDate ? slotInfo.reasonDate : null
        };
        this.slotReasonModelRef = this._bsModalService.show(template, {'class': 'modal-sm'});
    }

    changeCompany(params: object) {
        this.fnGetLockers(params);
        this.fnChangeFilters(this.filterObj);
    }

    changeLocker(params: object) {
        this.fnGetCabinets(params);
        this.fnChangeFilters(this.filterObj);
    }

    // async showStatus(template, slotNum, cabinetNumber, lockerId) {
    //     const isOnline = await this.checkLockerOnline(lockerId);
    //     if (!isOnline) {
    //         return this.toastr.fnError('Locker is Offline');
    //     }
    //     this.slotStatus = false;
    //     this.slotDetails = {
    //         boxId: cabinetNumber + slotNum,
    //         lockerId: lockerId,
    //     };
    //
    //     if (!lockerId) {
    //         this.toastr.fnError('LockerId is required');
    //         return;
    //     }
    //     if (!(/[A-Z][0-9]{2}$/).test(this.slotDetails.boxId)) {
    //         this.toastr.fnError('Slot number with cabinet number is required');
    //         return;
    //     }
    //     this.socketIOService.emitEvent('fetch_status', this.slotDetails);
    //     this.slotResponse = this.socketIOService.listenEvent('receive_status').subscribe(status => {
    //         if (typeof status.slotStatus === 'string' && status.slotStatus.indexOf('offline') !== -1) {
    //             this.toastr.fnError(status.slotStatus);
    //         } else {
    //             this.slotStatusModelRef = this._bsModalService.show(template, {'class': 'modal-sm'});
    //             this.slotStatus = true;
    //             this.slotStatusResp = {
    //                 isOpened: status.slotStatus.open,
    //                 isStorage: status.slotStatus.occupied
    //             };
    //         }
    //     });
    // }
    //
    // async showSlotOpenModal(template, slotNum, cabinetNumber, lockerId) {
    //     const isOnline = await this.checkLockerOnline(lockerId);
    //     if (!isOnline) {
    //         return this.toastr.fnError('Locker is Offline');
    //     }
    //     this.slotOpenReason = '';
    //     this.slotOpenPassword = '';
    //     this.slotDetails = {
    //         boxId: cabinetNumber + slotNum,
    //         lockerId: lockerId,
    //     };
    //     if (!lockerId) {
    //         this.toastr.fnError('LockerId is required');
    //         return;
    //     }
    //     if (!(/[A-Z][0-9]{2}$/).test( this.slotDetails.boxId)) {
    //         this.toastr.fnError('Slot number with cabinet number is required');
    //         return;
    //     }
    //     this.slotOpenModelRef = this._bsModalService.show(template, {'class': 'modal-md'});
    // }
    //
    // async validateReasonAndPassword(template) {
    //     try  {
    //         const isPasswordCorrect = await this.slotsService.validatePassword(this.slotOpenPassword);
    //         if (isPasswordCorrect) {
    //             this.slotOpenModelRef.hide();
    //             this.otpModelRef = this._bsModalService.show(template, {'class': 'modal-sm'});
    //         }
    //     } catch (error) {
    //         this.toastr.fnError(error.message);
    //     }
    // }
    //
    // async validateOTP(template) {
    //     try {
    //         const isOTP =  await this.slotsService.validateOTP(this.SlotOpenRequestotp);
    //         if (isOTP) {
    //             this.slotDetails.slotOpenReason = this.slotOpenReason;
    //             this.socketIOService.emitEvent('open_slot_query', this.slotDetails);
    //             this.socketIOService.listenEvent('slot_door_open').subscribe(response => {
    //                     this.toastr.fnSuccess('Slot door opened successfully');
    //             });
    //            this.closeOTPModal();
    //            this.slotStatusModelRef = this._bsModalService.show(template, {'class': 'modal-sm', backdrop: 'static'});
    //            this.slotStatusInterval = setInterval(() => {
    //                 this.socketIOService.emitEvent('fetch_status', this.slotDetails);
    //            }, 1000);
    //             this.slotResponse = this.socketIOService.listenEvent('receive_status').subscribe(response => {
    //                 if (response.slotStatus.indexOf('offline') !== -1) {
    //                     this.closeStatusModal();
    //                     this.toastr.fnError(response.slotStatus);
    //                 } else {
    //                     this.slotStatus = true;
    //                     this.slotStatusResp = {
    //                         isOpened: response.slotStatus.open,
    //                         isStorage: response.slotStatus.occupied
    //                     };
    //                 }
    //             });
    //         }
    //     } catch (error) {
    //         this.toastr.fnError(error.message);
    //     }
    // }
    //
    // closeReasonModal() {
    //     this.slotReasonModelRef.hide();
    //     this.slotInfo = { slotNumber: '', reason: '', status: ''};
    // }

    // closeSlotOpenModal() {
    //     this.slotOpenModelRef.hide();
    //     this.slotOpenReason = '';
    //     this.slotOpenPassword = '';
    // }
    //
    // closeStatusModal() {
    //     this.slotStatusModelRef.hide();
    //     this.slotResponse.unsubscribe();
    //     this.slotDetails = { boxId: '', lockerId: ''};
    //     this.slotStatusResp = {
    //         isOpened: '',
    //         isStorage: ''
    //     };
    // }
    //
    // closeOTPModal() {
    //     this.otpModelRef.hide();
    //     this.SlotOpenRequestotp = '';
    // }
    //
    // closeSocketConnection() {
    //     clearInterval(this.slotStatusInterval);
    //     this.closeStatusModal();
    // }
    //
    // ngOnDestroy() {
    //     this.socketIOService.closeConnection();
    // }
    //
    // checkLockerOnline(lockerId) {
    //     this.socketIOService.emitEvent('locker_status', {lockerId: lockerId});
    //     return new Promise((resolve, reject) => {
    //         this.socketIOService.listenEvent('receive_locker_status').subscribe((data) => {
    //             resolve(data);
    //         });
    //     });
    // }
}
