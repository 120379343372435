<div class="row page">
    <div class="col-lg-12">
        <h1 class="page-header">Shipment
            <div class="btn-group pull-right" *ngIf="!shipmentObj._id && !isDataLoaded">
                <button type="button" class="btn btn-primary" [ngClass]="{'active': !isLabelScanView}"
                        (click)="isLabelScanView = false">
                    Manual
                </button>
                <button type="button" class="btn btn-primary" [ngClass]="{'active': isLabelScanView}"
                        (click)="isLabelScanView = true">
                    Scan Label
                </button>
            </div>
            <a href="javascript:void(0);" class="btn btn-primary pull-right" (click)="isReadOnly = !isReadOnly" *ngIf="shipmentObj._id && currentUser.role.endsWith(CONSTANT.ROLE_ADMIN) && !isDataLoaded">
                <i class="fa fa-fw" [ngClass]="{'fa-edit': isReadOnly, 'fa-close': !isReadOnly}"></i>
            </a>
            <div class="clearfix"></div>
        </h1>
        <ul class="breadcrumb">
            <li><a href="javascript:void(0);" [routerLink]="['/shipments']">Shipments</a></li>
            <li *ngIf="!isDataLoaded">{{shipmentObj._id ? 'Edit' : 'Add'}} Shipment</li>
        </ul>
    </div>
    <pp-spinner [isSpinnerLoad]="isDataLoaded"></pp-spinner>
    <!-- START: Manual -->
    <div class="col-lg-12" *ngIf="!isDataLoaded && !isLabelScanView">
        <form novalidate #shipmentForm="ngForm"
              (ngSubmit)="shipmentForm.valid && fnSaveShipment(shipmentObj, manualUserObj)">
            <fieldset class="col-md-12 fieldset" *ngIf="currentUser.role === CONSTANT.ROLE_ADMIN">
                <legend class="fieldset-legend">Basic</legend>

                <div class="form-group col-md-6"
                     [ngClass]="{'has-error': (shipmentForm.submitted || company.touched) && !company.valid}">
                    <label for="company">Select Company<span class="text-danger">*</span></label>
                    <select id="company" class="form-control" name="company" [(ngModel)]="shipmentObj.companyId"
                            #company="ngModel" (change)="fnChangeCompany({companyId: shipmentObj.companyId})"
                            [disabled]="shipmentObj._id" required>
                        <ng-template ngFor let-item [ngForOf]="companiesArr" let-i="index">
                            <option [ngValue]="item._id"
                                    *ngIf="shipmentObj._id && item.name === CONSTANT.COMPANY_PUROLATOR">
                                {{item.name}}
                            </option>
                            <option [ngValue]="item._id" *ngIf="item.name !== CONSTANT.COMPANY_PUROLATOR">
                                {{item.name}}
                            </option>
                        </ng-template>
                    </select>
                    <div *ngIf="(shipmentForm.submitted || company.touched) && !company.valid">
                        <small class="text-danger" *ngIf="company.errors?.required">Company is required.</small>
                    </div>
                </div>
                <div class="form-group col-md-6"
                     [ngClass]="{'has-error': (shipmentForm.submitted || locker.touched) && !locker.valid}">
                    <label for="locker">Select Locker (Optional)</label>
                    <select id="locker" class="form-control" name="locker" [(ngModel)]="shipmentObj.lockerId && shipmentObj.lockerId._id ? shipmentObj.lockerId._id : shipmentObj.lockerId"
                            #locker="ngModel" [disabled]="shipmentObj._id">
                        <ng-template ngFor let-item [ngForOf]="lockersArr" let-i="index">
                            <option [ngValue]="item._id">
                                {{item.name}}
                            </option>
                        </ng-template>
                        <option [ngValue]="shipmentObj.lockerId._id" *ngIf="shipmentObj.lockerId && shipmentObj.lockerId._id">
                            {{shipmentObj.lockerId.name}}
                        </option>
                    </select>
                </div>
            </fieldset>
            <fieldset class="col-md-12 fieldset">
                <legend class="fieldset-legend">Parcel</legend>

                <div class="row" *ngFor="let package of shipmentObj.packages;trackBy i; let i = index;">
                    <div class="form-group col-md-3">
                        <label for="size">Size</label>
                        <select id="size" class="form-control" name="size{{i}}" [(ngModel)]="package.size"
                                [disabled]="shipmentObj._id">
                            <option value="{{CONSTANT.SLOT_TYPE_SMALL}}">SMALL</option>
                            <option value="{{CONSTANT.SLOT_TYPE_MEDIUM}}">MEDIUM</option>
                            <option value="{{CONSTANT.SLOT_TYPE_LARGE}}">LARGE</option>
                            <option value="{{CONSTANT.SLOT_TYPE_EXTRA_LARGE}}">EXTRA LARGE</option>
                            <option value="{{CONSTANT.SLOT_TYPE_XX_LARGE}}">XX LARGE</option>
                        </select>
                    </div>
                    <div class="form-group col-md-3"
                         [ngClass]="{'has-error': (shipmentForm.submitted || weight.touched) && !weight.valid}">
                        <label for="weight">Weight (Kg)<span class="text-danger">*</span></label>
                        <input id="weight" type="text" class="form-control" name="weight{{i}}"
                               placeholder="Parcel weight" #weight="ngModel" [(ngModel)]="package.weight"
                               [disabled]="shipmentObj._id" required/>
                        <div *ngIf="(shipmentForm.submitted || weight.touched) && !weight.valid">
                            <small class="text-danger" *ngIf="weight.errors?.required">Weight is required.</small>
                        </div>
                    </div>
                    <div class="form-group col-md-3"
                         [ngClass]="{'has-error': (shipmentForm.submitted || label.touched) && !label.valid}">
                        <label for="label">Label No. (Optional)</label>
                        <input id="label" type="tel" minlength="6" class="form-control" name="label{{i}}"
                               placeholder="Parcel label no." #label="ngModel" [(ngModel)]="package.label"
                               [disabled]="shipmentObj._id"/>
                        <div *ngIf="(shipmentForm.submitted || label.touched) && !label.valid">
                            <small class="text-danger" *ngIf="label.errors?.minlength">Label No. should be at least 6 digits longer.</small>
                        </div>
                    </div>

                    <div class="form-group col-md-3" *ngIf="shipmentStatus != CONSTANT.SHIPMENT_STATUS_PREPARING && package.droppedAt">
                        <label>Dropped At</label>
                        <div class="form-control" disabled>{{package.droppedAt | LocalDate}} </div>
                    </div>

                    <div class="col-md-1 m-b-5 delete-package" *ngIf="i !== 0">
                        <a href="javascript:void(0);" class="btn btn-danger"
                           (click)="fnRemovePackage(shipmentObj.packages, i)"><i class="fa fa-trash-o"></i></a>
                    </div>
                </div>
                <div class="row" *ngIf="!shipmentObj._id && shipmentObj.packages.length < 5">
                    <div class="col-sm-12">
                        <a href="javascript:void(0);" class="btn btn-primary"
                           (click)="fnAddMorePackage(shipmentObj.packages)">Add more</a>
                    </div>
                </div>
            </fieldset>

            <fieldset class="col-md-12 fieldset">
                <legend class="fieldset-legend">Shipping</legend>
                <div class="row" *ngIf="!shipmentObj._id">
                    <div class="col-sm-12">
                        <div class="btn-group pull-right">
                            <button type="button" class="btn btn-primary" [ngClass]="{'active': !isSelectUsersView}"
                                    (click)="isSelectUsersView = false">
                                Registered
                            </button>
                            <button type="button" class="btn btn-primary" [ngClass]="{'active': isSelectUsersView}"
                                    (click)="isSelectUsersView = true">
                                Manual
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="!isSelectUsersView">
                    <div class="col-md-6">
                        <fieldset class="fieldset">
                            <legend class="fieldset-legend">Recipient</legend>

                            <div class="form-group"
                                 [ngClass]="{'has-error': (shipmentForm.submitted || receiver.touched) && !receiver.valid}">
                                <label for="receiver">Select Recipient<span class="text-danger">*</span></label>
                                <select id="receiver" class="form-control" name="receiver" required
                                        [(ngModel)]="shipmentObj.receiver && shipmentObj.receiver._id ? shipmentObj.receiver._id : shipmentObj.receiver" #receiver="ngModel"
                                        [disabled]="shipmentObj._id">
                                    <option [ngValue]="user._id" *ngFor="let user of usersArr">
                                        {{user.firstName + ' ' +user.lastName}}
                                    </option>
                                    <option [ngValue]="shipmentObj.receiver._id" *ngIf="shipmentObj.receiver && shipmentObj.receiver._id">
                                        {{shipmentObj.receiver.firstName + ' ' +shipmentObj.receiver.lastName}}
                                    </option>
                                </select>
                                <div *ngIf="(shipmentForm.submitted || receiver.touched) && !receiver.valid">
                                    <small class="text-danger" *ngIf="receiver.errors?.required">
                                        Recipient is required.
                                    </small>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-md-6" *ngIf="shipmentObj._id">
                        <fieldset class="fieldset">
                            <legend class="fieldset-legend">Status</legend>
                            <div class="form-group" *ngIf="shipmentObj._id">
                                <label for="shipmentStatus">Select Status</label>
                                <select id="shipmentStatus" class="form-control" name="shipmentStatus" [(ngModel)]="shipmentObj.status" [disabled]="shipmentObj._id && isReadOnly">
                                    <option *ngIf="shipmentStatus === CONSTANT.SHIPMENT_STATUS_PREPARING" value="{{CONSTANT.SHIPMENT_STATUS_PREPARING}}">{{ shipmentObj.isOutbound === true ? CONSTANT.READABLE_OUTBOUND_SHIPMENT_STATUS[CONSTANT.SHIPMENT_STATUS_PREPARING] : CONSTANT.READABLE_SHIPMENT_STATUS[CONSTANT.SHIPMENT_STATUS_PREPARING] | RemoveUnderscore }}</option>
                                    <option *ngIf="(shipmentStatus === CONSTANT.SHIPMENT_STATUS_PREPARING
                                                   || shipmentStatus === CONSTANT.SHIPMENT_STATUS_OUTBOUND)
                                                    && shipmentObj.isOutbound === true" value="{{CONSTANT.SHIPMENT_STATUS_OUTBOUND}}">{{ CONSTANT.READABLE_OUTBOUND_SHIPMENT_STATUS[CONSTANT.SHIPMENT_STATUS_OUTBOUND] | RemoveUnderscore }}</option>
                                    <option *ngIf="(shipmentStatus === CONSTANT.SHIPMENT_STATUS_IN_TRANSIT
                                            || shipmentStatus === CONSTANT.SHIPMENT_STATUS_PREPARING
                                            || shipmentStatus === CONSTANT.SHIPMENT_STATUS_EXPIRED
                                            || shipmentStatus === CONSTANT.SHIPMENT_STATUS_EXCEPTION) && shipmentObj.isOutbound === false"
                                    value="{{CONSTANT.SHIPMENT_STATUS_IN_TRANSIT}}">{{ CONSTANT.READABLE_SHIPMENT_STATUS[CONSTANT.SHIPMENT_STATUS_IN_TRANSIT] | RemoveUnderscore }}</option>
                                    <option *ngIf="shipmentStatus === CONSTANT.SHIPMENT_STATUS_CANCELLED
                                            || shipmentStatus === CONSTANT.SHIPMENT_STATUS_PREPARING
                                            || shipmentStatus === CONSTANT.SHIPMENT_STATUS_IN_TRANSIT
                                            || shipmentStatus === CONSTANT.SHIPMENT_STATUS_EXPIRED
                                            || shipmentStatus === CONSTANT.SHIPMENT_STATUS_PRODUCT_RETURN"
                                            value="{{CONSTANT.SHIPMENT_STATUS_CANCELLED}}">{{ CONSTANT.READABLE_SHIPMENT_STATUS[CONSTANT.SHIPMENT_STATUS_CANCELLED] }}</option>
                                    <option *ngIf="shipmentStatus === CONSTANT.SHIPMENT_STATUS_EXPIRED
                                            || shipmentStatus === CONSTANT.SHIPMENT_STATUS_IN_TRANSIT"
                                            value="{{CONSTANT.SHIPMENT_STATUS_EXPIRED}}">{{ CONSTANT.READABLE_SHIPMENT_STATUS[CONSTANT.SHIPMENT_STATUS_EXPIRED] }}</option>
                                    <option *ngIf="shipmentStatus === CONSTANT.SHIPMENT_STATUS_DELIVERED
                                            || shipmentStatus === CONSTANT.SHIPMENT_STATUS_IN_TRANSIT
                                            || shipmentStatus === CONSTANT.SHIPMENT_STATUS_OUTBOUND
                                            || shipmentStatus === CONSTANT.SHIPMENT_STATUS_EXPIRED"
                                            value="{{CONSTANT.SHIPMENT_STATUS_DELIVERED}}">{{ shipmentObj.isOutbound === true ? CONSTANT.READABLE_OUTBOUND_SHIPMENT_STATUS[CONSTANT.SHIPMENT_STATUS_DELIVERED] : CONSTANT.READABLE_SHIPMENT_STATUS[CONSTANT.SHIPMENT_STATUS_DELIVERED] }}</option>
                                    <option *ngIf="shipmentStatus === CONSTANT.SHIPMENT_STATUS_EXCEPTION
                                            || shipmentStatus === CONSTANT.SHIPMENT_STATUS_IN_TRANSIT"
                                            value="{{CONSTANT.SHIPMENT_STATUS_EXCEPTION}}">{{ CONSTANT.READABLE_SHIPMENT_STATUS[CONSTANT.SHIPMENT_STATUS_EXCEPTION] }}</option>
                                    <option *ngIf="shipmentStatus === CONSTANT.SHIPMENT_STATUS_RETURNED
                                            || shipmentStatus === CONSTANT.SHIPMENT_STATUS_EXPIRED
                                            || shipmentStatus === CONSTANT.SHIPMENT_STATUS_PRODUCT_RETURN"
                                            value="{{CONSTANT.SHIPMENT_STATUS_RETURNED}}">{{ CONSTANT.READABLE_SHIPMENT_STATUS[CONSTANT.SHIPMENT_STATUS_RETURNED] | RemoveUnderscore }}</option>
                                    <option *ngIf="shipmentStatus === CONSTANT.SHIPMENT_STATUS_PRODUCT_RETURN" value="{{CONSTANT.SHIPMENT_STATUS_PRODUCT_RETURN}}">
                                        {{ CONSTANT.READABLE_SHIPMENT_STATUS[CONSTANT.SHIPMENT_STATUS_PRODUCT_RETURN] }} </option>
                                </select>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-sm-12" *ngIf="!shipmentObj._id">
                        <a href="javascript:void(0);" class="btn btn-primary"
                           (click)="fnOpenUserModal()">Add user</a>
                    </div>
                </div>
                <div class="row" *ngIf="isSelectUsersView">
                    <div class="col-md-12">
                        <pp-shipment-user title="Recipient" [form]="shipmentForm"
                                          [userObj]="manualUserObj.to"></pp-shipment-user>
                    </div>
                </div>
            </fieldset>

            <div class="form-group" *ngIf="!shipmentObj._id || !isReadOnly">

                <div class="pull-right" *ngIf="shipmentStatus === CONSTANT.SHIPMENT_STATUS_IN_TRANSIT
                                            || shipmentStatus === CONSTANT.SHIPMENT_STATUS_EXPIRED
                                            || shipmentStatus === CONSTANT.SHIPMENT_STATUS_EXCEPTION">
                    <button *ngIf="!shipmentObj.isOutbound" class="btn btn-primary" type="button" (click)="resendNotification(shipmentObj._id)">
                        Re-send notification
                    </button>
                </div>

                <button class="btn btn-primary m-r-4" type="submit">
                    {{shipmentObj._id ? 'Update Shipment' : 'Create Shipment'}}
                </button>
                <button class="btn btn-warning" [routerLink]="shipmentObj.isOutbound ? ['/outbound-shipments'] : ['/shipments']">Cancel</button>
            </div>
        </form>
    </div>
    <!-- END: Manual -->

    <!-- START: Scan Label -->
    <div class="col-lg-12" *ngIf="!isDataLoaded && isLabelScanView">
        <form novalidate #scanLabelForm="ngForm" (ngSubmit)="scanLabelForm.valid && fnGetCarrier(scanLabelStr)">
            <fieldset class="col-md-12 fieldset">
                <legend class="fieldset-legend">Label</legend>
                <div class="form-group"
                     [ngClass]="{'has-error': (scanLabelForm.submitted || scanLabel.touched) && !scanLabel.valid}">
                    <label for="scanLabel">Scan or Enter Label<span class="text-danger">*</span>
                        (e.g. 81512, 85644)</label>
                    <input id="scanLabel" type="text" class="form-control" name="scanLabel" autocomplete="off"
                           [typeahead]="dataSource"
                           (typeaheadOnSelect)="fnTypeAheadOnSelect($event)"
                           typeaheadOptionsLimit="7"
                           typeaheadOptionField="parcel.label"
                           [(ngModel)]="scanLabelStr"
                           placeholder="Type parcel label" #scanLabel="ngModel" required/>
                    <div *ngIf="(scanLabelForm.submitted || scanLabel.touched) && !scanLabel.valid">
                        <small class="text-danger" *ngIf="scanLabel.errors?.required">
                            Scan Label is required.
                        </small>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <button type="submit" class="btn btn-primary">Submit</button>
                    </div>
                </div>
            </fieldset>
        </form>
        <form novalidate #shippingForm="ngForm"
              (ngSubmit)="shippingForm.valid && fnCreateShipmentUsing3rdPartyData(shippingObj)"
              *ngIf="shippingObj && isCompanyInfoView">
            <fieldset class="col-md-12 fieldset">
                <legend class="fieldset-legend">Company<span class="text-danger">*</span></legend>

                <div class="form-group"
                     [ngClass]="{'has-error': (shippingForm.submitted || companyName.touched) && !companyName.valid}">
                    <label for="companyName">Name</label>
                    <input id="companyName" type="text" class="form-control" name="companyName"
                           placeholder="Enter a company name" [(ngModel)]="shippingObj.company" #companyName="ngModel"
                           required/>
                    <div *ngIf="(shippingForm.submitted || companyName.touched) && !companyName.valid">
                        <small class="text-danger" *ngIf="companyName.errors?.required">Company is required.</small>
                    </div>
                </div>
            </fieldset>
            <fieldset class="col-md-12 fieldset">
                <legend class="fieldset-legend">Parcel</legend>

                <div class="row">
                    <div class="form-group col-sm-4">
                        <label for="parcelLabel">Label</label>
                        <input id="parcelLabel" type="text" class="form-control" name="parcelLabel"
                               required disabled readonly placeholder="Parcel code"
                               [(ngModel)]="shippingObj.parcel.label"/>
                    </div>
                    <div class="form-group col-sm-4">
                        <label for="parcelSize">Size</label>
                        <select id="parcelSize" class="form-control" name="parcelSize"
                                [(ngModel)]="shippingObj.parcel.size">
                            <option value="{{CONSTANT.SLOT_TYPE_SMALL}}">SMALL</option>
                            <option value="{{CONSTANT.SLOT_TYPE_MEDIUM}}">MEDIUM</option>
                            <option value="{{CONSTANT.SLOT_TYPE_LARGE}}">LARGE</option>
                            <option value="{{CONSTANT.SLOT_TYPE_EXTRA_LARGE}}">EXTRA LARGE</option>
                            <option value="{{CONSTANT.SLOT_TYPE_XX_LARGE}}">XX LARGE</option>
                        </select>
                    </div>
                    <div class="form-group col-sm-4"
                         [ngClass]="{'has-error': (shippingForm.submitted || parcelWeight.touched) && !parcelWeight.valid}">
                        <label for="parcelWeight">Weight<span class="text-danger">*</span></label>
                        <input id="parcelWeight" type="text" class="form-control" name="parcelWeight"
                               placeholder="Parcel weight" #parcelWeight="ngModel"
                               [(ngModel)]="shippingObj.parcel.weight" required/>
                        <div *ngIf="(shippingForm.submitted || parcelWeight.touched) && !parcelWeight.valid">
                            <small class="text-danger" *ngIf="parcelWeight.errors?.required">Weight is required.
                            </small>
                        </div>
                    </div>
                </div>
            </fieldset>

            <fieldset class="col-md-12 fieldset">
                <legend class="fieldset-legend">Shipping</legend>

                <div class="row">
                    <div class="col-md-12">
                        <pp-shipment-user title="Recipient" [form]="shippingForm"
                                          [userObj]="shippingObj.to"></pp-shipment-user>
                    </div>
                </div>
            </fieldset>
            <div class="form-group" *ngIf="!shipmentObj._id">
                <button class="btn btn-primary" type="submit">
                    {{shipmentObj._id ? 'Update Shipment' : 'Create Shipment'}}
                </button>
                <button class="btn btn-warning m-l-4" [routerLink]="['/shipments']">Cancel</button>
            </div>
        </form>
    </div>
    <!-- END: Scan Label -->
</div>
