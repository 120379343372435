import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AuthService} from '../../../common/auth.service';
import {User} from '../../users/user';
import {Constant} from '../../../common/constant';
import {DomSanitizer} from '@angular/platform-browser';
import {LockersService} from '../lockers.service';
import * as moment from 'moment';
import {ToastrService} from '../../../common/toastr.service';

@Component({
    selector: 'pp-locker-logs',
    templateUrl: './locker-logs.component.html',
    styleUrls: ['./locker-logs.component.css']
})

export class LockerLogsComponent implements OnInit {

    public isDataLoaded;
    public isReadOnly;
    public lockerId: string;
    public logFileUrl: any;
    public downloadLogFileUrl: any;
    public currentUser: User;
    public date: Date;
    public locker: any;
    public isLockerLogsAvailable: boolean;
    public logsType: any;
    public logPath: string;
    public maxDate: any = new Date();
    public minDate = new Date(moment().subtract(29, 'days').format(Constant.CURRENT_DATE_FORMAT));
    public fetchFromServer: boolean;

    readonly CONSTANT = Constant;

    constructor(
        private activatedRoute: ActivatedRoute,
        private auth: AuthService,
        private domSanitizer: DomSanitizer,
        private lockersService: LockersService,
        private toastr: ToastrService,
    ) {
        this.isDataLoaded = false;
        this.isReadOnly = false;
        this.currentUser = this.auth.fnGetCurrentUser();
        this.activatedRoute.params.subscribe(params => {
            this.lockerId = params['id'];
            this.logPath = params.fetchFromServer ? 'locker-logs-from-server' : 'logsByDate';
            this.fetchFromServer = params.fetchFromServer ? params.fetchFromServer : false;
        });
        this.date = new Date();
        this.logsType = Constant.ANDROID;
        this.fnGetLockerDetails();
        this.fnSetUrl(this.date);
    }

    ngOnInit() {
        this.isDataLoaded = true;
    }

    fnGetLockerDetails() {
        this.lockersService.fnGetLocker(this.lockerId)
            .then((lockersLogs: any) => {
                this.locker = lockersLogs;
            }).catch((error) => {
            console.log('error is : ' + error);
        });
    }

    fnSetUrl(date?: any, isRefresh?: boolean) {
        const selectedDate = moment(date).format(Constant.CURRENT_DATE_FORMAT);
        const downloadLogFileUrl = Constant.SERVER_URL + '/locker/logs/download?lockerId=' + this.lockerId + '&date=' + selectedDate + '&type=' + this.logsType;
        this.downloadLogFileUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(downloadLogFileUrl);
        const url = Constant.SERVER_URL + `/locker/${this.logPath}?lockerId=` + this.lockerId + '&date=' + selectedDate + '&type=' + this.logsType + `${isRefresh ? '&isRefresh=true' : ''}`;
        this.logFileUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
        setTimeout(() => {
            this.fnCheckLockerLogs();
        }, 1100);
    }

    fnCheckLockerLogs() {
        const param = {
            lockerId: this.lockerId,
            date: moment(this.date).format(Constant.CURRENT_DATE_FORMAT),
            type: this.logsType
        };
        this.lockersService.fnCheckLockerLogs(param, this.fetchFromServer)
            .then((resp: boolean) => {
                this.isLockerLogsAvailable = resp;
            })
            .catch((e) => {
                this.isLockerLogsAvailable = false;
            });
    }

    fnRefreshLockeLogs() {
        if (this.fetchFromServer) {
            this.toastr.fnSuccess('Locker logs updated successfully.');
            this.fnSetUrl(this.date);
        } else {
            const param = {
                lockerId: this.lockerId,
                date: moment(this.date).format(Constant.CURRENT_DATE_FORMAT),
                type: this.logsType,
                isRefresh: true
            };
            this.lockersService.fnRefreshLockerLogsLocker(param)
                .then(() => {
                    this.toastr.fnSuccess('Locker logs updated successfully.');
                    this.fnSetUrl(this.date);
                })
                .catch((error) => {
                    this.toastr.fnError(error);
                });
        }
    }
}
