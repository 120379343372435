<div class="form-group">
    <div>
        <label class="thin-label">How many slots are in
            <b>{{ (cabinetType === CONSTANT.HOST_BANK) ? 'Host Bank' : 'Cabinet ' + cabinetNumber }}</b>?</label>
        <div class="counter">
            <button class="btn btn-danger" (click)="deleteSlot()" type="button"><i aria-hidden="true"
                                                                                   class="fa fa-minus"></i></button>
            <input class="form-control text-center" (change)="adjustSlots($event.target?.value)" name="hostBankSlots"
                   type="number" [value]="cabinet.length" min="1" max="10">
            <button class="btn btn-success" (click)="addSlot()" type="button"><i aria-hidden="true"
                                                                                 class="fa fa-plus"></i></button>
        </div>
    </div>
    <table class="slot-table">
        <tr>
            <td class="m-b-5 text-center slot-td" *ngFor="let slot of cabinet; let index = index">
                <h5 class="bg-primary"> {{ cabinetNumber }}{{ slot.slotNumber }} </h5>
                <div class="slot-details">
                    <select (change)="setSlotSize($event.target?.value, index)">
                        <option [value]="size" *ngFor="let size of slotSizes">{{ size | RemoveUnderscore }}</option>
                    </select>
                    <div>
                        <label class="upperSlot thin-label m-t-5 m-b-0" [for] =cabinetNumber+slot.slotNumber>Is upper slot?</label>
                        <input [id]=cabinetNumber+slot.slotNumber (change)="setUpperSlot($event.target?.checked, index)" type="checkbox">
                    </div>
                </div>
            </td>
        </tr>
    </table>
</div>
