import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';

import {AuthService} from '../common/auth.service';
import {ToastrService} from '../common/toastr.service';
import {User, USER_ROLE} from '../../../server/model/user';

@Component({
    selector: 'pp-verify-otp',
    templateUrl: './verify-otp.component.html',
    styleUrls: ['./verify-otp.component.css']
})
export class VerifyOTPComponent implements OnInit, AfterViewInit {
    @ViewChild('otpRef', {static: false}) otpRef: ElementRef;
    public otp: number;
    public currentUser: any;

    constructor(private router: Router, private auth: AuthService, private toastr: ToastrService) {
        this.currentUser = this.auth.fnGetCurrentUser();
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
        this.otpRef.nativeElement.focus();
    }

    /**
     * Sign out
     * */
    fnSignOut() {
        this.auth.fnSignOut()
            .then(() => {
                this.router.navigate(['/sign-in']);
            });
    }

    /**
     * Verify otp token
     * @param {any} otp
     * */
    fnVerifyOTP(otp: any) {
        this.auth.fnVerifyOTP({otp: otp})
            .then((user: User) => {
                if (user.role === USER_ROLE.ADMIN) {
                    this.router.navigate(['/dashboard']);
                } else {
                    this.router.navigate(['/shipments']);
                }
            })
            .catch((error: any) => {
                this.toastr.fnError(error.message);
            });
    }

    /**
     * Resend otp request
     * */
    fnResendOTP() {
        this.auth.fnResendOTP()
            .then((res: any) => {
                this.toastr.fnSuccess(res.message);
            })
            .catch((error: any) => {
                this.toastr.fnError(error.message);
            });
    }

}
