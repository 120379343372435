import {Component, OnInit} from '@angular/core';
import {Locker} from '../../lockers/locker';
import {User} from '../../users/user';
import {Company} from '../../companies/company';
import {Observable} from 'rxjs';
import {Constant} from '../../../common/constant';
import {CompaniesService} from '../../companies/companies.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UsersService} from '../../users/users.service';
import {LockersService} from '../../lockers/lockers.service';
import {ShipmentsService} from '../../shipments/shipments.service';
import {ToastrService} from '../../../common/toastr.service';
import {AuthService} from '../../../common/auth.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {GoogleMapsService} from '../../../common/google.maps.service';

@Component({
    selector: 'pp-pending-shipment',
    templateUrl: './pending-shipment.component.html',
    styleUrls: ['./pending-shipment.component.css']
})
export class PendingShipmentComponent implements OnInit {

    public isDataLoaded;
    public isSelectUsersView: boolean;
    public isCompanyInfoView: boolean;
    public currentUser: User;
    public shipmentId: string;
    public shipmentObj: any;
    public companiesArr: Array<Company> = [{_id: '', name: 'Select Company'}];
    public lockersArr: Array<Locker> = [{lockerNumber: '', name: 'Select Locker'}];

    public dataSource: Observable<any>;
    public carriersArr: any[] = [];
    public isReadOnly;

    readonly CONSTANT = Constant;

    public emailRegEx: any = Constant.EMAIL_REG_EX;
    public phoneRegEx: any = Constant.PHONE_REG_EX;

    public slotSizes: Array<string> = [];

    constructor(
        private router: Router, private activatedRoute: ActivatedRoute, private _bsModalService: BsModalService,
        private auth: AuthService, private toastr: ToastrService, private googleMapsService: GoogleMapsService,
        private usersService: UsersService, private shipmentService: ShipmentsService,
        private lockersService: LockersService, private companiesService: CompaniesService
    ) {
        this.isDataLoaded = false;
        this.isReadOnly = true;
        this.isSelectUsersView = false;
        this.isCompanyInfoView = false;
        this.currentUser = this.auth.fnGetCurrentUser();
        this.activatedRoute.params.subscribe(params => {
            this.shipmentId = params['id'];
        });

        this.shipmentObj = {
            companyId: '',
            locker: '',
            courier: '',
            consignee: {
                firstName: '',
                lastName: '',
                email: '',
                phone: ''
            },
            reservationPeriod: '',
            trackingNumber: '',
            slotSizeRequirement: '',
            additionalAuthenticationCode: '',
            isOutbound: false,
        };
    }

    async ngOnInit() {
        if (this.currentUser.role === Constant.ROLE_ADMIN) {
            await this.getCompanies();
        } else if (this.currentUser.role === Constant.ROLE_COMPANY_ADMIN) {
            this.shipmentObj.companyId = this.currentUser.companyId;
            const params = {companyId: this.currentUser.companyId};
            await this.getLockers(params);
        }
        await this.getCarriers();
    }

    /**
     * Get companies
     * */
    public async getCompanies() {
        this.isDataLoaded = true;
        try {
            this.companiesArr = <Company[]> await this.companiesService.fnGetCompanies();
            this.isDataLoaded = false;
        } catch (e) {
            this.companiesArr = [];
            this.isDataLoaded = false;
        }
        this.companiesArr.unshift({_id: '', name: 'Select Company'});
    }

    /**
     * Get 3rd party data for typeahead.
     * */
    public async getCarriers() {
        try {
            const response = <Array<any>> await this.lockersService.getCarrierConfigs();
            if (response) {
                this.carriersArr = response;
            } else {
                this.carriersArr = [];
            }
        } catch (e) {
            this.carriersArr = [];
        }
        this.carriersArr.unshift({carrierCode: '', name: 'Select Courier'});
    }

    /**
     * Change company event
     * @param {string} companyId
     * @returns {Promise<void>}
     */
    public async changeCompany(companyId: string) {
        const params = {companyId: companyId};
        await this.getLockers(params);
        this.shipmentObj.courier = '';
    }


    public changeLocker() {
        const selectedLocker = this.lockersArr.find((locker) => locker.lockerNumber === this.shipmentObj.locker)
        if (selectedLocker) {
            this.shipmentObj.isOutboundEnabled = selectedLocker.isOutboundEnabled;
            this.slotSizes = selectedLocker.slotSizes;
        }
    }

    /**
     * Get Lockers
     * @param {object} params
     * */
    public async getLockers(params?: object) {
        //params['isMultiBuilding'] = 'False';
        this.lockersArr = <Array<Locker>> await this.lockersService.getLockersNameAndNumber(params['companyId']);
        if (this.lockersArr.length === 0) {
            this.lockersArr.unshift({lockerNumber: '', name: 'Select Locker'});
            this.shipmentObj.locker = '';
        } else {
            this.shipmentObj.locker = this.lockersArr[0].lockerNumber;
            this.shipmentObj.isOutboundEnabled = this.lockersArr[0].isOutboundEnabled;
            this.slotSizes = this.lockersArr[0].slotSizes;
        }
    }


    /**
     * Create/Update shipment
     * @param {object} shipmentObj
     * @param {object} shippingObj
     * */
    public async saveShipment(shipmentObj: any) {
        const reqBody: any = {};
        reqBody.locker = shipmentObj.locker;
        reqBody.courier = shipmentObj.courier;
        reqBody.shipments = [];

        const shipment: any = {};
        shipment.trackingNumber = shipmentObj.trackingNumber;
        shipment.slotSizeRequirement = shipmentObj.slotSizeRequirement;
        shipment.reservationPeriod = shipmentObj.reservationPeriod ? (shipmentObj.reservationPeriod + 'H') : '';
        shipment.consignee = shipmentObj.consignee;
        shipment.additionalAuthenticationCode = shipmentObj.additionalAuthenticationCode;
        shipment.isOutbound = shipmentObj.isOutbound;

        reqBody.shipments = [shipment];
        this.shipmentService.createPendingShipment(reqBody).then((response) => {
            this.toastr.fnSuccess('Pending shipment created successfully.');
            this.router.navigateByUrl('/pending-shipments');
        }).catch((error) => {
            this.toastr.fnError(error.message);
        });
    }
}
