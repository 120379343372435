import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Constant} from '../../common/constant';
import {Locker, LOCKER_CODES_TYPE} from './locker';
import {CarrierConfig} from './carrier-config';
import {LockerCarrier} from './locker-carrier';
import {User} from '../users/user';
import {SearchResultsWithMetadata} from "../../common/search-results";

@Injectable()
export class LockersService {

    constructor(private http: HttpClient) {
    }

    getLockersNameAndNumber = (companyId?: string) => {
        return this.http.get(Constant.API_URL + `api/lockers/no-lookup` + (companyId ? `?companyId=${companyId}` : '')).toPromise();
    }

    /**
     * Get list of lockers
     * @param {object} params
     * */
    getLockers(params: object, lookup: boolean): Promise<Array<SearchResultsWithMetadata<Locker>>> {
        const apiPath = lookup ? 'lookup' : 'no-lookup';
        let urlSearchParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key) && params[key]) {
                urlSearchParams = urlSearchParams.append(key, params[key]);
            }
        }
        const qString = urlSearchParams.toString();
        return new Promise((resolve, reject) => {
            this.http
                .get(Constant.API_URL + 'api/lockers/' + apiPath + (qString ? '?' + qString : ''))
                .subscribe((response: Array<SearchResultsWithMetadata<Locker>>) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Get list of carrier configs
     * */
    getCarrierConfigs(): Promise<Array<CarrierConfig>> {
        return new Promise((resolve, reject) => {
            this.http.get(Constant.API_URL + 'api/carrier-configs')
                .subscribe((response: Array<CarrierConfig>) => {
                    resolve(response);
                }, error => {
                    reject(error.error);
                });
        });
    }

    /**
     * Save Locker Carrier Config
     * @param {string} lockerId
     * @param {LockerCarrier} lockerCarrier
     * */
    saveLockerCarrierConfig(lockerId: string, lockerCarrier: LockerCarrier) {
        const lockerCarrierConfigObj: any = {
            returnProcessType: lockerCarrier.returnProcessType,
            enabled: lockerCarrier.enabled
        };
        if (lockerCarrier.returnPickupCode) {
            lockerCarrierConfigObj.returnPickupCode = lockerCarrier.returnPickupCode;
        }
        return new Promise((resolve, reject) => {
            this.http.patch(Constant.API_URL + 'api/locker/' + lockerId + '/carrier-config/' + lockerCarrier.carrierCode, lockerCarrierConfigObj)
                .subscribe((response: any) => {
                    resolve(response);
                }, error => {
                    reject(error.error);
                });
        });
    }

    /**
     * Get Users by Locker ID
     * @param {string} lockerId
     * @param {object} params
     * */
    getUsersByLocker(lockerId: string, params?: object): Promise<Array<User>> {
        let apiPath;
        if (params) {
            let urlSearchParams = new HttpParams();
            for (const key in params) {
                if (params.hasOwnProperty(key) && params[key]) {
                    urlSearchParams = urlSearchParams.append(key, params[key]);
                }
            }
            const qString = urlSearchParams.toString();
            apiPath = lockerId + '/users' + (qString ? '?' + qString : '');
        } else {
            apiPath = lockerId + '/users';
        }
        return new Promise((resolve, reject) => {
            this.http
                .get(Constant.API_URL + 'api/locker/' + apiPath)
                .subscribe((response: any) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Get locker
     * @param {string} id
     * */
    fnGetLocker(id: string) {
        return new Promise((resolve, reject) => {
            this.http
                .get(Constant.API_URL + 'api/lockers/' + id)
                .subscribe((response: Locker) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Create new locker
     * @param {object} lockerObj
     * */
    fnCreateLocker(lockerObj: Locker) {
        return new Promise((resolve, reject) => {
            this.http
                .post(Constant.API_URL + 'api/lockers', lockerObj)
                .subscribe((response: Locker) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Update locker
     * @param {object} lockerObj
     * */
    fnUpdateLocker(lockerObj: Locker) {
        return new Promise((resolve, reject) => {
            this.http
                .put(Constant.API_URL + 'api/lockers/' + lockerObj._id, lockerObj)
                .subscribe((response: Locker) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Update locker
     * @param {object} lockerObj
     * */
    fnUpdateLockerAutoUpdate(lockerId, updateObj) {
        return new Promise((resolve, reject) => {
            this.http
                .put(Constant.API_URL + 'api/lockers/auto-update/' + lockerId, updateObj)
                .subscribe((response: Locker) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Delete locker
     * @param {string} id
     **/
    fnDeleteLocker(id: string) {
        return new Promise((resolve, reject) => {
            this.http
                .delete(Constant.API_URL + 'api/lockers/' + id)
                .subscribe((response) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    fnGetLockerLogs(params?: object) {
       let urlSearchParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key) && params[key]) {
                urlSearchParams = urlSearchParams.append(key, params[key]);
            }
        }
        const qString = urlSearchParams.toString();
        return new Promise((resolve, reject) => {
            this.http
                .get(Constant.API_URL + 'api/locker/logs' + (qString ? '?' + qString : ''))
                .subscribe((response: any) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    fnCheckLockerLogs(params?: object, fetchFromServer?: any) {
        let urlSearchParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key) && params[key]) {
                urlSearchParams = urlSearchParams.append(key, params[key]);
            }
        }
        urlSearchParams = urlSearchParams.append('checkStatus', 'true');
        urlSearchParams = urlSearchParams.append('fetchFromServer', `${fetchFromServer}`);
        const qString = urlSearchParams.toString();
        return new Promise((resolve, reject) => {
            this.http
                .get(Constant.SERVER_URL + '/locker/logs/download' + (qString ? '?' + qString : ''))
                .subscribe((response: any) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    fnRefreshLockerLogsLocker(params?: object) {
        let urlSearchParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key) && params[key]) {
                urlSearchParams = urlSearchParams.append(key, params[key]);
            }
        }
        const qString = urlSearchParams.toString();
        return new Promise((resolve, reject) => {
            this.http
                .get(Constant.SERVER_URL + '/locker/logsByDate' + (qString ? '?' + qString : ''))
                .subscribe((response: any) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    setCodes = (lockerId: string, type: LOCKER_CODES_TYPE, code: string | string[]) => {
        return this.http.post(Constant.API_URL + 'api/locker/'+lockerId+'/codes', {[type]: code}).toPromise();
    }
}
