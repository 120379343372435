import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Constant} from '../../common/constant';

@Injectable()
export class NotificationsService {

    constructor(private http: HttpClient) {
    }

    /**
     * Get notifications
     * @param {object} params
     * */
    fnGetNotifications(params?: object) {
        let urlSearchParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key) && params[key]) {
                urlSearchParams = urlSearchParams.append(key, params[key]);
            }
        }
        const qString = urlSearchParams.toString();
        return new Promise((resolve, reject) => {
            this.http
                .get(Constant.API_URL + 'api/notifications' + (qString ? '?' + qString : ''))
                .subscribe((response) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Get notifications
     * @param {object} params
     * */
    fnGetNotificationsCountByDate(params?: object) {
        let urlSearchParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key) && params[key]) {
                urlSearchParams = urlSearchParams.append(key, params[key]);
            }
        }
        const qString = urlSearchParams.toString();
        return new Promise((resolve, reject) => {
            this.http
                .get(Constant.API_URL + 'api/notifications/bydate' + (qString ? '?' + qString : ''))
                .subscribe((response) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    getNotificationStatusDetail(notificationId: string) {
        return new Promise((resolve, reject) => {
           this.http
               .get(Constant.API_URL + 'api/notification/' + notificationId + '/details')
               .subscribe((response) => {
                   resolve(response);
               }, (error) => {
                   reject(error.error);
               });
        });
    }
}
