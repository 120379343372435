import {Component, OnInit} from '@angular/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import * as _ from 'lodash';

import {DeleteModalComponent} from '../../delete-modal/delete-modal.component';
import {AuthService} from '../../common/auth.service';
import {StorageService} from '../../common/storage.service';
import {CabinetsService} from './cabinets.service';
import {LockersService} from '../lockers/lockers.service';
import {CompaniesService} from '../companies/companies.service';
import {Cabinet} from './cabinet';
import {User} from '../users/user';
import {Company} from '../companies/company';
import {Locker} from '../lockers/locker';
import {Constant} from '../../common/constant';
import {ToastrService} from '../../common/toastr.service';
import {ExportService} from '../../common/export.service';
import {SearchResults} from '../../common/search-results';

@Component({
    selector: 'pp-cabinets',
    templateUrl: './cabinets.component.html',
    styleUrls: ['./cabinets.component.css']
})

export class CabinetsComponent implements OnInit {

    public isDataLoaded;
    public isExporting: boolean;
    public filterObj: any;
    public currentUser: User;
    public cabinetsArr: Array<Cabinet> = [];
    public lockersArr: Array<Locker> = [{_id: '', name: 'Select Locker'}];
    public companiesArr: Array<Company> = [{_id: '', name: 'Select Company'}];
    readonly CONSTANT = Constant;
    private CABINETS_FILTERS = 'cabinetsFilter';
    public totCabinets: number;

    constructor(private _bsModalService: BsModalService, private cabinetsService: CabinetsService,
                private auth: AuthService, private lockersService: LockersService,
                private companiesService: CompaniesService, private storage: StorageService,
                private toastr: ToastrService, private exportService: ExportService) {
        this.currentUser = this.auth.fnGetCurrentUser();
        this.isDataLoaded = false;
        this.isExporting = false;
        this.fnGetCabinetsFilters();
    }

    ngOnInit() {
        this.fnGetCompanies();
    }

    /**
     * Get local storage data
     * */
    fnGetCabinetsFilters() {
        const CABINETS_FILTERS = this.storage.fnGetData(this.CABINETS_FILTERS);
        this.filterObj = _.isEmpty(CABINETS_FILTERS) ? {
            companyId: '',
            lockerId: '',
            sortBy: 'lockerId.name',
            orderBy: 'asc'
        } : CABINETS_FILTERS;
        this.filterObj.page = 0;
        this.filterObj.limit = 10;
    }

    /**
     * Clear filter
     * */
    fnClearFilters() {
        this.storage.fnRemove(this.CABINETS_FILTERS);
        this.fnGetCabinetsFilters();
        if (this.currentUser.role === Constant.ROLE_ADMIN) {
            this.lockersArr = [{_id: '', name: 'Select Locker'}];
        }
        this.filterObj.sortBy = 'lockerId.name';
        this.filterObj.orderBy = 'asc';
        this.fnChangeFilters(this.filterObj);
    }

    /**
     * Filter change.
     * @param {object} params
     * */
    fnChangeFilters(params?: object) {
        this.filterObj.page = 0;
        this.storage.fnStoreData(this.CABINETS_FILTERS, params);
        this.fnGetCabinets(params);
    }

    /**
     * Set column sort icon
     * @param {string} sortBy
     * */
    setSortIcon(sortBy: string) {
        if (this.filterObj.sortBy === sortBy) {
            return this.filterObj.orderBy === 'desc' ? 'fa fa-sort-desc' : 'fa fa-sort-asc';
        }
        return 'fa fa-sort';
    }

    /**
     * Sort by column name
     * @param {string} sortBy
     * */
    sortBy(sortBy: string) {
        if (this.filterObj.sortBy === sortBy) {
            this.filterObj.sortBy = sortBy;
            if (this.filterObj.orderBy === 'asc') {
                this.filterObj.orderBy = 'desc';
            } else if (this.filterObj.orderBy === 'desc') {
                this.filterObj.sortBy = 'lockerId.name';
                this.filterObj.orderBy = 'asc';
            } else {
                this.filterObj.orderBy = 'asc';
            }
        } else {
            this.filterObj.orderBy = 'asc';
            this.filterObj.sortBy = sortBy;
        }

        this.filterObj.page = 0;
        this.cabinetsService.fnGetCabinets(this.filterObj)
            .then((res: SearchResults<Cabinet>) => {
                this.cabinetsArr = this.fnMapCabinets(res.data);
            });
    }

    /**
     * Get companies
     * */
    fnGetCompanies() {
        this.companiesService.fnGetCompanies({limitData: true})
            .then((companiesArr: Array<Company>) => {
                this.companiesArr = companiesArr;
                this.companiesArr.unshift({_id: '', name: 'Select Company'});
                if (this.currentUser.role === Constant.ROLE_ADMIN) {
                    if (this.filterObj.companyId) {
                        this.fnGetLockers({companyId: this.filterObj.companyId});
                    } else {
                        this.fnGetCabinets(this.filterObj);
                    }
                } else {
                    this.filterObj.companyId = this.currentUser.companyId;
                    this.fnGetLockers({companyId: this.filterObj.companyId});
                    this.fnGetCabinets(this.filterObj);
                }
            });
    }

    /**
     * Get list of lockers
     * @param {object} params
     * */
    async fnGetLockers(params?: object) {
        this.lockersArr = [{_id: '', name: 'Select Locker'}];
        if (params['companyId']) {
            this.lockersArr = <Array<Locker>> await this.lockersService.getLockersNameAndNumber(params['companyId']);
            this.lockersArr.unshift({_id: '', name: 'Select Locker'});
            this.fnChangeFilters(this.filterObj);
        } else {
            this.fnChangeFilters(this.filterObj);
        }
    }

    /**
     * Get list of cabinets
     * @param {object} params
     * */
    fnGetCabinets(params?: object) {
        this.isDataLoaded = false;
        this.cabinetsService.fnGetCabinets(params)
            .then((res: SearchResults<Cabinet>) => {
                this.isDataLoaded = true;
                this.cabinetsArr = res.data;
                this.totCabinets = res.total;
                this.fnMapCabinets(res.data);
            }).catch((error) => {
                this.toastr.fnError(error.message);
            });
    }

    /**
     * Delete cabinet
     * @param {string} id
     * */
    deleteCabinet(id: string) {
        const modal = this._bsModalService.show(DeleteModalComponent, {'class': 'modal-sm'});
        (<DeleteModalComponent>modal.content).showConfirmationModal(
            'Delete',
            'Are you sure you want to delete this cabinet?'
        );

        (<DeleteModalComponent>modal.content).onClose.subscribe(result => {
            if (result) {
                this.cabinetsService.fnDeleteCabinet(id).then(() => {
                    this.fnGetCabinets(this.filterObj);
                    this.toastr.fnSuccess('Cabinet deleted successfully.');
                }).catch((error) => {
                    this.toastr.fnError(error.message);
                });
            }
        });
    }

    /**
     * Map cabinets array
     * */
    fnMapCabinets(cabinets: Array<Cabinet>) {
        return _.map(cabinets, (cabinetObj: Cabinet) => {
            cabinetObj.totAvailableSlots = _.filter(cabinetObj.slots, (slotObj) => {
                return slotObj.status === 'AVAILABLE';
            }).length;
            return cabinetObj;
        });
    }

    fnPageChanged(event: any) {
        this.filterObj.page = event.page;
        this.fnGetCabinets(this.filterObj);
    }

    fnExportCSV() {
        this.isExporting = true;
        this.cabinetsService.fnGetCabinets({ page: 1, limit: 10000 })
            .then((res: SearchResults<Cabinet>) => {
                const cabinets = this.fnMapCabinets(res.data);
                const csvArr = [];
                _.forEach(cabinets, (element: Cabinet)  => {
                    element.totalSlots = element.slots ? element.slots.length : 0;
                    csvArr.push(element);
                });
                const headers = ['companyId.name', 'lockerId.name', 'type', 'cabinetNumber', 'totAvailableSlots',
                'totalSlots', 'updatedAt', 'createdAt'];
                this.exportService.fnDownloadCSV('Cabinets', csvArr, headers);
                this.isExporting = false;
            });
    }

}
