import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse,
    HttpResponse
} from '@angular/common/http';
import {Observable} from 'rxjs';
import { tap } from 'rxjs/operators';
import {Cookie} from './cookie';

@Injectable()
export class InterceptorService implements HttpInterceptor {

    constructor(private router: Router, private cookie: Cookie) {
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.cookie.get('AUTH_TOKEN') && !request.headers.has('Authorization')) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.cookie.get('AUTH_TOKEN')}`
                }
            });
        }
        return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // do stuff with response if you want
                }
            }, (error: any) => {
                if (error instanceof HttpErrorResponse) {
                    switch (error.status) {
                        case 401:
                            this.cookie.delete('AUTH_TOKEN');
                            this.router.navigate(['/sign-in']);
                            console.log(error.status, error.error);
                            break;
                        default:
                            console.log(error.status, error.error);
                    }
                }
            }));
    }

}
