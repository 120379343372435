<div class="row page">
    <div class="col-lg-12">
        <h1 class="page-header">User</h1>
        <ul class="breadcrumb">
            <li><a href="javascript:void(0);" [routerLink]="['/users']">Users</a></li>
            <li *ngIf="!isDataLoaded">{{userObj._id ? 'Edit' : 'Add'}} User</li>
        </ul>
    </div>
    <pp-spinner [isSpinnerLoad]="isDataLoaded"></pp-spinner>
    <div class="col-lg-12" [hidden]="isDataLoaded">
        <form #userForm="ngForm" (ngSubmit)="userForm.valid && fnSaveUser(userObj)" novalidate>
            <fieldset class="col-md-12 fieldset">
                <legend class="fieldset-legend">Basic</legend>

                <div class="form-group" [ngClass]="{'has-error': (userForm.submitted || company.touched) && !company.valid}">
                    <label for="company">Select Company<span class="text-danger">*</span></label>
                    <select id="company" class="form-control" name="company" [(ngModel)]="userObj.companyId" (change)="userObj.preferredLockerId = '';fnGetLockers({companyId: userObj.companyId});"
                           #company="ngModel" required>
                        <option [ngValue]="item._id" *ngFor="let item of companiesArr">{{item.name}}</option>
                    </select>
                    <div *ngIf="(userForm.submitted || company.touched) && !company.valid">
                        <small class="text-danger" *ngIf="company.errors['required']">Company is required.</small>
                    </div>
                </div>
                <div class="form-group" [ngClass]="{'has-error': (userForm.submitted || role.touched) && !role.valid}">
                    <label for="role">Select Role<span class="text-danger">*</span></label>
                    <select id="role" class="form-control" name="role" [(ngModel)]="userObj.role" #role="ngModel" required>
                        <option value="">Select Role</option>
                        <option value="{{CONSTANT.ROLE_ADMIN}}">ADMIN</option>
                        <option value="{{CONSTANT.ROLE_COMPANY_ADMIN}}">COMPANY ADMIN</option>
                        <option value="{{CONSTANT.ROLE_USER}}">USER</option>
                    </select>
                    <div *ngIf="(userForm.submitted || role.touched) && !role.valid">
                        <small class="text-danger" *ngIf="role.errors['required']">Role is required.</small>
                    </div>
                </div>
                <div class="form-group" *ngIf="CONSTANT.ROLE_USER === userObj.role">
                    <label for="locker">Select Locker<span class="text-danger">*</span></label>
                    <select id="locker" class="form-control" name="locker" [(ngModel)]="userObj.preferredLockerId"
                            #locker="ngModel" required>
                        <option [ngValue]="item._id" *ngFor="let item of lockersArr">{{item.name}}</option>
                    </select>
                    <div *ngIf="(userForm.submitted || locker.touched) && !locker.valid">
                        <small class="text-danger" *ngIf="locker.errors['required']">Locker is required.</small>
                    </div>
                </div>
                <div class="form-group" [ngClass]="{'has-error': (userForm.submitted || role.touched) && !role.valid}">
                    <label for="language">Select Language<span class="text-danger">*</span></label>
                    <select id="language" class="form-control" name="language" [(ngModel)]="userObj.language" #language="ngModel" required>
                        <option value="">Select Language</option>
                        <option [ngValue]="item.key" *ngFor="let item of CONSTANT.USER_LANGUAGES">{{item.value}}</option>
                    </select>
                    <div *ngIf="(userForm.submitted || language.touched) && !role.valid">
                        <small class="text-danger" *ngIf="language.errors['required']">Language is required.</small>
                    </div>
                </div>
                <div class="form-group" [ngClass]="{'has-error': (userForm.submitted || firstName.touched) && !firstName.valid}">
                    <label for="firstName">First Name<span class="text-danger">*</span></label>
                    <input id="firstName" type="text" class="form-control" name="firstName" placeholder="Enter a first name" [(ngModel)]="userObj.firstName"
                           #firstName="ngModel" [pattern]="blankSpaceRegEx" required/>
                    <div *ngIf="(userForm.submitted || firstName.touched) && !firstName.valid">
                        <small class="text-danger" *ngIf="firstName.errors['required']">First name is required.</small>
                        <small class="text-danger" *ngIf="firstName.errors['pattern']">First name must not be blank.</small>
                    </div>
                </div>
                <div class="form-group" [ngClass]="{'has-error': (userForm.submitted || lastName.touched) && !lastName.valid}">
                    <label for="lastName">Last Name<span class="text-danger">*</span></label>
                    <input id="lastName" type="text" class="form-control" name="lastName" placeholder="Enter a last name" [(ngModel)]="userObj.lastName"
                           #lastName="ngModel" [pattern]="blankSpaceRegEx" required/>
                    <div *ngIf="(userForm.submitted || lastName.touched) && !lastName.valid">
                        <small class="text-danger" *ngIf="lastName.errors['required']">Last name is required.</small>
                        <small class="text-danger" *ngIf="lastName.errors['pattern']">Last name must not be blank.</small>
                    </div>
                </div>
                <div class="form-group">
                    <label for="unit">Unit</label>
                    <input id="unit" type="text" class="form-control" name="unit" placeholder="Enter tenant unit detail" [(ngModel)]="userObj.unit"
                        #unit="ngModel" />
                </div>
                <div class="form-group" [ngClass]="{'has-error': (userForm.submitted || email.touched) && !email.valid}">
                    <label for="email">Email<span class="text-danger">*</span></label>
                    <input id="email" type="email" class="form-control" name="email" placeholder="Enter an email" [(ngModel)]="userObj.email" [disabled]="userObj.email === CONSTANT.ADMIN_EMAIL"
                        #email="ngModel" [pattern]="emailRegEx" required/>
                    <div *ngIf="(userForm.submitted || email.touched) && !email.valid">
                        <small class="text-danger" *ngIf="email.errors['required']">Email is required.</small>
                        <small class="text-danger" *ngIf="email.errors['pattern']">Email is invalid.</small>
                    </div>
                </div>
                <div class="form-group" [ngClass]="{'has-error': (userForm.submitted || phone.touched) && !phone.valid}">
                    <label for="phone">Phone #<span class="text-danger">*</span></label>
                    <input id="phone" type="phone" class="form-control" name="phone" placeholder="Enter a phone #" [(ngModel)]="userObj.phone"
                        #phone="ngModel" required/>
                    <div *ngIf="(userForm.submitted || phone.touched) && !phone.valid">
                        <small class="text-danger" *ngIf="phone.errors['required']">Phone # is required.</small>
                    </div>
                </div>
                <div class="form-group" [ngClass]="{'has-error': (userForm.submitted ) && !businessPhone.valid}">
                    <label for="businessPhone">Business Phone #</label>
                    <input id="businessPhone" type="phone" class="form-control" name="businessPhone" placeholder="Enter a business phone #" [(ngModel)]="userObj.businessPhone"
                           #businessPhone="ngModel"/>
                </div>
                <div class="form-group" *ngIf="userObj._id">
                    <label>Status</label>
                    <div class="col-xs-12 p-0 m-b-15" >
                        <div class="btn-group">
                            <button type="button" class="btn btn-success" name="activeUser" [(ngModel)]="userObj.status" btnRadio="ACTIVE" (click)="setUserStatus(USER_STATUS.ACTIVE)">Active</button>
                            <button type="button" class="btn btn-success" name="inactiveUser" [(ngModel)]="userObj.status" btnRadio="INACTIVE" (click)="setUserStatus(USER_STATUS.INACTIVE)">Inactive</button>
                            <button type="button" class="btn btn-success" name="archivedUser" [(ngModel)]="userObj.status" btnRadio="ARCHIVED" (click)="setUserStatus(USER_STATUS.ARCHIVED)">Archived</button>
                        </div>
                    </div>
                </div>
            </fieldset>

            <pp-location [locationId]="userObj.locationId" [form]="userForm"></pp-location>

            <div class="form-group">
                <div class="pull-right" *ngIf="userObj._id">
                    <button class="btn btn-primary" type="button" (click)="fnOpenRPConfirmModal()">
                        Reset Password
                    </button>
                </div>
                <button class="btn btn-primary m-r-4" type="submit">
                    {{userObj._id ? 'Update User' : 'Create User'}}
                </button>
                <button class="btn btn-warning" type="button" (click)="navigateToUsers()">Cancel</button>
            </div>
        </form>
    </div>
</div>
