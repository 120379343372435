import {Component, OnInit} from '@angular/core';
import {Company} from '../companies/company';
import {CompaniesService} from '../companies/companies.service';
import {DashboardService} from './dashboard.service';
import {User} from '../users/user';
import * as moment from 'moment';
import * as _ from 'lodash';
import {AuthService} from '../../common/auth.service';
import {Constant} from '../../common/constant';
import {COUNTRY, CANADA_PROVINCE, USA_STATES} from '../../common/enums';

@Component({
    selector: 'pp-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
    public currentUser: User;
    readonly CONSTANT = Constant;
    public companiesArr: Array<Company> = [{ _id: '', name: 'Select Company' }];
    public packageFilterObj: any;
    public feedbackFilterObj: any;
    public errorsFilterObj: any;
    public selectedCompany: any;

    public packageDates: string;
    public feedbackDates: string;
    public errorsDates: string;

    public packageCounts: any = {};
    public feedbackCounts: any = {};
    public errorCounts: any = {};
    public slotCounts: any = {};

    public packageDateRange: any = {};
    public errorDateRange: any = {};
    public feedbackDateRange: any = {};

    public holidays = [];
    public PROVINCES_STATES = {...CANADA_PROVINCE, ...USA_STATES};

    constructor(private companiesService: CompaniesService,
                private dashboardService: DashboardService,
                private auth: AuthService) {
        this.currentUser = this.auth.fnGetCurrentUser();
        this.selectedCompany = '';
        this.setDefaultDatesToAllFilters();
    }

    ngOnInit() {
        if (this.currentUser && this.currentUser.role === this.CONSTANT.ROLE_ADMIN) {
            this.getAdminData();
        }
        this.getHolidays();
    }

    getAdminData = () => {
        this.getCompanies();
        this.getPackageCounts();
        this.getFeedbackCounts();
        this.getErrorCounts();
    }

    getCompanies = async () => {
        try {
            this.companiesArr = <Array<Company>> await this.companiesService.fnGetCompanies({limitData: true});
            this.companiesArr.unshift({_id: '', name: 'Select Company'});
        } catch (error) {
            console.error(error);
        }
    }

    getDefaultDateRange() {
        return {startDate: moment().subtract(31, 'd').format('MM/DD/YYYY'), endDate: moment().format('MM/DD/YYYY')};
    }

    getPackageCounts = async () => {
        try {
            const res = await this.dashboardService.getPackageCounts(this.packageFilterObj);
            this.packageCounts = res[0];
        } catch (error) {
            console.error(error);
        }
    }

    getFeedbackCounts = async () => {
        try {
            const res = await this.dashboardService.getFeedbackCounts(this.feedbackFilterObj);
            this.feedbackCounts = res[0];
        } catch (error) {
            console.error(error);
        }
    }

    getErrorCounts = async () => {
        this.getJammedSlotsCounts();
        try {
            const res = await this.dashboardService.getErrorCounts(this.errorsFilterObj);
            this.errorCounts = res[0];
        } catch (error) {
            console.error(error);
        }
    }

    getJammedSlotsCounts = async () => {
        try {
            const res = await this.dashboardService.getJammedSlotsCounts(this.errorsFilterObj);
            this.slotCounts = res[0];
        } catch (error) {
            console.error(error);
        }
    }

    changeCompany = () => {
        this.packageFilterObj.companyId = this.selectedCompany;
        this.errorsFilterObj.companyId = this.selectedCompany;
        this.feedbackFilterObj.companyId = this.selectedCompany;
        this.getPackageCounts();
        this.getFeedbackCounts();
        this.getErrorCounts();
    }

    getData = (filterObj: any, type: string) => {
        if (type === 'packageDates') {
            this.packageDates = filterObj.startDate + ' - ' + filterObj.endDate;
            this.getPackageCounts();
        } else if (type === 'errorsDates') {
            this.errorsDates = filterObj.startDate + ' - ' + filterObj.endDate;
            this.getErrorCounts();
        } else if (type === 'feedbackDates') {
            this.feedbackDates = filterObj.startDate + ' - ' + filterObj.endDate;
            this.getFeedbackCounts();
        }
    }

    selectDate = (value: any, filterObj: any, type: string) => {
        if (value) {
            filterObj.startDate = value.picker.startDate.format('MM/DD/YYYY');
            filterObj.endDate = value.picker.endDate.format('MM/DD/YYYY');
        }
        this.getData(filterObj, type);
    }

    clearDate = async (filterObj: any, type: string) => {
        filterObj.startDate = this.getDefaultDateRange().startDate;
        filterObj.endDate = this.getDefaultDateRange().endDate;
        this.getData(filterObj, type);
    }

    setDefaultDatesToAllFilters = () => {
        const startDate = this.getDefaultDateRange().startDate;
        const endDate = this.getDefaultDateRange().endDate;

        this.packageDateRange.startDate = startDate;
        this.packageDateRange.endDate = endDate;

        this.errorDateRange.startDate = startDate;
        this.errorDateRange.endDate = endDate;

        this.feedbackDateRange.startDate = startDate;
        this.feedbackDateRange.endDate = endDate;

        this.packageFilterObj = {
            startDate: startDate,
            endDate: endDate
        };
        this.packageDates = this.packageFilterObj.startDate + ' - ' + this.packageFilterObj.endDate;
        this.feedbackFilterObj = {
            startDate: startDate,
            endDate: endDate
        };
        this.feedbackDates = this.feedbackFilterObj.startDate + ' - ' + this.feedbackFilterObj.endDate;
        this.errorsFilterObj = {
            startDate: startDate,
            endDate: endDate
        };
        this.errorsDates = this.errorsFilterObj.startDate + ' - ' + this.errorsFilterObj.endDate;
    }

    clearAdminFilters = async () => {
        this.selectedCompany = '';
        delete this.errorsFilterObj.companyId;
        delete this.packageFilterObj.companyId;
        delete this.feedbackFilterObj.companyId;
        this.setDefaultDatesToAllFilters();
        this.getPackageCounts();
        this.getFeedbackCounts();
        this.getErrorCounts();
    }

    async getHolidays() {
        const todayStartTime = moment().startOf('day');
        const todayDate = moment(todayStartTime).format(Constant.CURRENT_DATE_FORMAT);
        const params = {
            country: COUNTRY.CA,
            holidayDate: todayDate,
            limit: 5,
            page: 1
        };

        try {
            const holidayData: any = await this.dashboardService.getHolidays(params);
            this.holidays = [];
            const holidays = {};
            if (holidayData && holidayData.data.length > 0) {
                for (const holiday of holidayData.data) {
                    for (const day of holiday.list) {
                        const holidayDate = day.holidayDate;
                        if (!holidays[holidayDate]) {
                            holidays[holidayDate] = [];
                        }
                        const index = holidays[holidayDate].findIndex(data => data.description === day.description);
                        if (index === -1) {
                            const record: any = {};
                            record.date = holidayDate;
                            record.country = params.country;
                            record.provinces = [holiday.province];
                            record.description = day.description;
                            holidays[holidayDate].push(record);
                        } else {
                            holidays[holidayDate][index].provinces.push(holiday.province);
                        }
                    }
                }
            }
            this.holidays = _.values(holidays).slice(0, 5);
        } catch (err) {
            this.holidays = [];
            console.error('Error:', err);
        }
    }
}
