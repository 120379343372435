<div class="row page">
    <div class="col-lg-12">
        <h1 class="page-header">Locker Directory</h1>
    </div>
    <pp-spinner [isSpinnerLoad]="!isDataLoaded"></pp-spinner>
    <div class="col-lg-12">
        <div class="row">
            <div class="col-sm-2" *ngIf="currentUser.role === CONSTANT.ROLE_ADMIN">
                <div class="form-group">
                    <select id="company" class="form-control" name="company" [(ngModel)]="selectedCompany"
                            (change)="companyChanged()">
                        <option [ngValue]="item._id" *ngFor="let item of companiesArr">{{item.name}}</option>
                    </select>
                </div>
            </div>
            <div [ngClass]="{'col-sm-2': currentUser.role === CONSTANT.ROLE_ADMIN, 'col-sm-3': currentUser.role === CONSTANT.ROLE_COMPANY_ADMIN}">
                <div class="form-group">
                    <select id="locker" class="form-control" name="locker" [(ngModel)]="selectedLocker"
                            (change)="lockerChanged()" *ngIf="lockersArr.length">
                        <option [ngValue]="item._id" *ngFor="let item of lockersArr">{{item.name}}</option>
                    </select>
                    <div class="text-box-label" *ngIf="!lockersArr.length" >
                        <label class="text-danger">No Lockers Found.</label>
                    </div>
                </div>
            </div>
            <div [ngClass]="{'col-sm-3': currentUser.role === CONSTANT.ROLE_ADMIN, 'col-sm-4': currentUser.role === CONSTANT.ROLE_COMPANY_ADMIN}">
                <div class="form-group">
                    <input id="users" name="users" class="form-control" [(ngModel)]="selectedUser"
                           (blur)="onUserSearch()" placeholder="Search User" autocomplete="off">
                </div>
            </div>
            <div [ngClass]="{'col-sm-3': currentUser.role === CONSTANT.ROLE_ADMIN || currentUser.role === CONSTANT.ROLE_COMPANY_ADMIN}">
                <div class="form-group">
                    <input type="text" placeholder="Search By Unit Number #" class="form-control" [(ngModel)]="unitNumberSearch"
                           (blur)="fnOnUnitNumberInput()">
                </div>
            </div>
            <div class="col-sm-2">
                <button class="btn btn-primary btn-block" (click)="refreshData()">Refresh</button>
            </div>
        </div>
    </div>
    <div class="col-lg-12 m-t-10">
        <div class="table-responsive" *ngIf="userArray.length && lockersArr.length">
            <table>
                <thead>
                <tr>
                    <th class="bg-primary unit" (click)="sort('unit')">
                        Unit # <i [class]="setSortIcon('unit')"></i></th>
                    <th class="bg-primary full-name" (click)="sort('firstName')">
                        Full name <i [class]="setSortIcon('firstName')"></i></th>
                    <th class="bg-primary data-source" (click)="sort('dataSource')">
                        Data source <i [class]="setSortIcon('dataSource')"></i></th>
                    <th class="bg-primary created-at" (click)="sort('createdAt')">
                        Created At <i [class]="setSortIcon('createdAt')"></i>
                    </th>
                    <th class="bg-primary sign-up" (click)="sort('status')">
                        Signed up? <i [class]="setSortIcon('status')"></i></th>
                    <th class="bg-primary number-of-shipments cursor-auto">
                        Number of shipments  </th>
                    <th class="bg-primary email" (click)="sort('email')">
                        Email <i [class]="setSortIcon('email')"></i></th>
                    <th class="bg-primary cursor-auto">Phone #</th>
                    <th class="bg-primary cursor-auto" *ngIf="currentUser.role.endsWith(CONSTANT.ROLE_ADMIN)">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let user of userArray">
                    <td>{{user.unit? user.unit : ''}}</td>
                    <td>{{(user.firstName || '') + '  ' + (user.lastName || '')}}</td>
                    <td>{{user.dataSource}}</td>
                    <td>{{user.createdAt | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
                    <td>{{user.status === USER_STATUS.ACTIVE ? 'Yes' : 'No'}}</td>
                    <td>
                        <label>Total: </label> {{user.shipmentCounts ? user.shipmentCounts.totalShipments : 0}}
                        <label>Active: </label> {{user.shipmentCounts ? user.shipmentCounts.activeShipments : 0}}
                    </td>
                    <td>{{user.email}}</td>
                    <td>{{user.phone}}</td>
                    <td>
                        <div class="btn-group btn-group-xs">
                        <button type="button" class="btn btn-primary" [routerLink]="['/users/' + user._id]"
                                *ngIf="currentUser.role === CONSTANT.ROLE_ADMIN">
                            <!-- *ngIf="slot.status !== CONSTANT.SLOT_STATUS_UNAVAILABLE" -->
                            <i class="fa fa-edit"></i>
                        </button>
                        <button type="button" class="btn btn-danger" (click)="archiveUser(user._id)"
                                *ngIf="currentUser.role.endsWith(CONSTANT.ROLE_ADMIN) || currentUser.role.endsWith(CONSTANT.ROLE_COMPANY_ADMIN)">
                            <!-- *ngIf="slot.status !== CONSTANT.SLOT_STATUS_UNAVAILABLE" -->
                            <i class="fa fa-archive"></i>
                        </button>
                        </div>
                        <!--<div class="btn-group btn-group-xs">-->
                            <!--<label class="btn btn-sm btn-success" name="enableTrue" btnRadio="True">Enable</label>-->
                            <!--<label class="btn btn-sm btn-success" name="disableFalse" btnRadio="False">Disable</label>-->
                        <!--</div>-->
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <h3 *ngIf="isDataLoaded && (!userArray.length || !lockersArr.length)" class="text-center">No users found.</h3>
        <div class="col-lg-12 text-center" *ngIf="userArray.length && lockersArr.length">
            <pagination [totalItems]="totUsers" [(ngModel)]="filterObj.page" [itemsPerPage]="filterObj.limit" [boundaryLinks]="true"
                        [maxSize]="5" (pageChanged)="pageChanged($event)"></pagination>
        </div>
    </div>
</div>
