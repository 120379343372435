<div class="row page">
    <div class="col-lg-12">
        <h1 class="page-header">Outbound Shipments
            <button class="pull-right btn btn-success m-r-2" (click)="fnExportCSV()" *ngIf="shipmentArr.length && !invalidDateRange" [disabled]="isExporting">
                <i class="fa fa-file-excel-o m-r-4"></i> <span>Export to CSV</span>
            </button>
        </h1>
    </div>
    <pp-spinner [isSpinnerLoad]="!isDataLoaded"></pp-spinner>
    <div class="col-lg-12">
        <div class="row">
            <div class="col-sm-3" *ngIf="currentUser.role === CONSTANT.ROLE_ADMIN">
                <div>
                    <div class="form-group">
                        <select id="company" class="form-control" name="company" [(ngModel)]="filterObj.companyId"
                                (change)="filterObj.lockerId = '';filterObj.userId = '';filterObj.selectedUser = '';selectedUser = '';fnGetLockers({companyId: filterObj.companyId})">
                            <option [ngValue]="item._id" *ngFor="let item of companiesArr">{{item.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div [ngClass]="{'col-sm-3': currentUser.role === CONSTANT.ROLE_ADMIN, 'col-sm-4': currentUser.role === CONSTANT.ROLE_COMPANY_ADMIN}">
                <div class="form-group">
                    <select id="locker" class="form-control" name="locker" [(ngModel)]="filterObj.lockerId"
                            (change)="filterObj.userId = '';filterObj.selectedUser = '';selectedUser = '';fnChangeShipments(filterObj)">
                        <option [ngValue]="item._id" *ngFor="let item of lockersArr">{{item.name}}</option>
                    </select>
                </div>
            </div>

            <div [ngClass]="{'col-sm-3': currentUser.role === CONSTANT.ROLE_ADMIN, 'col-sm-4': currentUser.role === CONSTANT.ROLE_COMPANY_ADMIN}"
                 class="{{!selectedCreatedAt || invalidDateRange ? 'has-error' : ''}}">
                <div class="form-group">
                    <input type="text" name="daterangeInput" class="form-control" daterangepicker
                           placeholder="Select Date Range"
                           [(ngModel)]="selectedCreatedAt"
                           [options]="{autoUpdateInput: false, opens: 'left'}"
                           (change)="changeDateRange()"
                           (applyDaterangepicker)="fnSelectedDate($event)"
                           (cancelDaterangepicker)="fnClearDate()"/>
                    <small class="text-danger" *ngIf="invalidDateRange">
                        Maximum date range is {{maxDateRangeDays}} days.
                    </small>
                </div>
            </div>

            <div [ngClass]="{'col-sm-3': currentUser.role === CONSTANT.ROLE_ADMIN, 'col-sm-4': currentUser.role === CONSTANT.ROLE_COMPANY_ADMIN}">
                <div class="form-group">
                    <select id="status" class="form-control" name="status" [(ngModel)]="filterObj.status"
                            (change)="fnChangeShipments(filterObj)">
                        <option value="">All</option>
                        <option [ngValue]="item.value" *ngFor="let item of shipmentStatusArr">{{CONSTANT.READABLE_OUTBOUND_SHIPMENT_STATUS[item.key]}}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row">
            <div [ngClass]="'col-sm-5'">
                <div class="form-group">
                    <ng-template #customItemTemplate let-model="item" let-index="index">
                        {{model.firstName}} {{model.lastName}}{{model.email ? ' (' + model.email + ')' : ''}}
                    </ng-template>
                    <input id="users" name="users" class="form-control" autocomplete="off" [(ngModel)]="selectedUser" [typeahead]="dataSource"
                           (typeaheadOnSelect)="fnOnSelectUser($event)" [typeaheadItemTemplate]="customItemTemplate"
                           [typeaheadScrollable]="true" [typeaheadOptionsLimit]="1000" container="body" [typeaheadMinLength]="0"
                           [typeaheadOptionsInScrollableView]="10" (blur)="fnTypeAheadOnBlur($event)" placeholder="Select Sender" autocomplete="off">
                </div>
            </div>

            <div class="col-sm-5" *ngIf="currentUser.role === CONSTANT.ROLE_ADMIN || currentUser.role === CONSTANT.ROLE_COMPANY_ADMIN">
                <div class="form-group">
                    <input type="text" name="trackingNumber" class="form-control" placeholder="Search Label" [(ngModel)]="searchLabelTxt" (blur)="onLabelChange()" autocomplete="off">
                </div>
            </div>

            <div class="col-sm-2">
                <button class="btn btn-primary btn-block" (click)="fnClearFilters()">Clear Filters</button>
            </div>
        </div>
    </div>
    <div class="col-lg-12">
        <div *ngIf="shipmentArr.length">
            <table class="shipments-table" [ngStyle]="{verticalAlign : 'top'} ">
                <thead>
                <tr>
                    <th *ngIf="CONSTANT.ROLE_ADMIN === currentUser.role" class="bg-primary company-name" (click)="sortBy('companyId.name')">
                        Company <i [class]="setSortIcon('companyId.name')"></i></th>
                    <th class="bg-primary carrier-name" (click)="sortBy('carrierName')">
                        Carrier Name <i [class]="setSortIcon('carrierName')"></i></th>
                    <th class="bg-primary receiver" (click)="sortBy('userId.firstName')">
                        Sender <i [class]="setSortIcon('userId.firstName')"></i></th>
                    <th class="bg-primary receiver" (click)="sortBy('outboundShipment.outboundRecipient.firstName')">
                        Receiver <i [class]="setSortIcon('outboundShipment.outboundRecipient.firstName')"></i></th>
                    <th class="bg-primary shipment-status" (click)="sortBy('status')">
                        Status <i [class]="setSortIcon('status')"></i></th>
                    <th class="bg-primary created-at">Date Details </th>
                    <th class="bg-primary cursor-auto">Slot #</th>
                    <th class="bg-primary cursor-auto" (click)="sortBy('packages.label')">
                        Label <i [class]="setSortIcon('packages.label')"></i></th>
                    <th class="bg-primary cursor-auto" (click)="sortBy('packages.size')">
                        Slot Size <i [class]="setSortIcon('packages.size')"></i></th>
                    <th class="bg-primary locker-name" (click)="sortBy('lockerId.name')">
                        Locker Name <i [class]="setSortIcon('lockerId.name')"></i></th>
                    <th class="bg-primary cursor-auto">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let shipment of shipmentArr" [ngStyle]="showDates === shipment._id && openDatesDropDown && {verticalAlign : 'top'}">
                    <td *ngIf="CONSTANT.ROLE_ADMIN === currentUser.role">{{shipment.companyId && shipment.companyId.name}}</td>
                    <td>
                        <p class="m-b-0">
                            {{shipment.carrierName}}
                        </p>
                        <small *ngIf="shipment.status === CONSTANT.SHIPMENT_STATUS_OUTBOUND && shipment.carrier.length && shipment.carrier[0].outboundPickupCode">
                            <div class="btn-group btn-group-xs">
                                <button type="button" class="btn btn-secondary bg-info" (click)="openDropOffCodeModel(DropOffCodeDialogBox,shipment, true);">
                                    Pickup code
                                </button>
                            </div>
                        </small>
                    </td>
                    <td>
                        <div *ngIf="currentUser.role === CONSTANT.ROLE_ADMIN && shipment.userId">
                            <p class="m-b-0">
                                <a [routerLink]="['/users/'+shipment.userId._id]">
                                    {{shipment.userId['firstName'] + ' ' +
                                shipment.userId['lastName']}}
                                </a>
                            </p>
                            <small *ngIf="shipment.status === CONSTANT.SHIPMENT_STATUS_PREPARING">
                                <div class="btn-group btn-group-xs">
                                    <button type="button" class="btn btn-secondary bg-info" (click)="openDropOffCodeModel(DropOffCodeDialogBox,shipment);">
                                        Drop-off code
                                    </button>
                                </div>
                            </small>
                        </div>
                    </td>
                    <td>
                        <div *ngIf="currentUser.role === CONSTANT.ROLE_ADMIN && shipment.outboundShipment && shipment.outboundShipment.outboundRecipient">
                            <p class="m-b-0">
                                {{shipment.outboundShipment.outboundRecipient.firstName + ' ' + shipment.outboundShipment.outboundRecipient.lastName}}
                            </p>
                        </div>
                    </td>
                    <td>
                        <p class="m-b-0">
                            {{shipment.status ? ( CONSTANT.READABLE_OUTBOUND_SHIPMENT_STATUS[shipment.status] | RemoveUnderscore)
                            : '&nbsp;'}}
                        </p>
                        <small *ngIf="shipment.status === CONSTANT.SHIPMENT_STATUS_DELIVERED" style="white-space: pre-wrap;"><a href="https://www.dhl.com/ca-en/home/tracking.html?tracking-id={{shipment.packages[0].label}}" [target]="null">Track</a></small>
                    </td>
                    <td>
                        <p style="color: #001f89; cursor: pointer; margin: 0" (click)="toggleDatesDropDown(shipment._id)"><span class="fa" [ngClass]="showDates === shipment._id && openDatesDropDown ? 'fa-minus' : 'fa-plus'"></span> View dates</p>
                        <table *ngIf="showDates === shipment._id && openDatesDropDown" class="dates-table">
                            <div [ngStyle]="dateDetailsArray[0] === true ? {display: 'block'} : {display: 'none'}"  id="labelDate">
                                <thead>
                                <tr><th class="bg-info">Label Created</th></tr>
                                </thead>
                                <tbody>
                                <tr><td>{{ shipment.createdAt | LocalDate }}</td></tr>
                                </tbody>
                            </div>
                            <div [ngStyle]="dateDetailsArray[1] === true ? {display: 'block'} : {display: 'none'}"  id="deposit">
                                <thead>
                                <tr><th class="bg-info">Deposit Date</th></tr>
                                </thead>
                                <tbody>
                                <tr><td>{{ shipment.packages[0].droppedAt | LocalDate }}</td></tr>
                                </tbody>
                            </div>
                            <div [ngStyle]="dateDetailsArray[2] === true ? {display: 'block'} : {display: 'none'}"  id="pickup">
                                <thead>
                                <tr><th class="bg-info">Pickup Date</th></tr>
                                </thead>
                                <tbody>
                                <tr><td>{{ shipment.packages[0].pickupEndAt | LocalDate }}</td></tr>
                                </tbody>
                            </div>
                            <div *ngIf="shipment.packages && shipment.packages[0].cancelledAt" [ngStyle]="dateDetailsArray[3] === true ? {display: 'block'} : {display: 'none'}"  id="cancel">
                                <thead>
                                <tr><th class="bg-info">Cancel Date</th></tr>
                                </thead>
                                <tbody>
                                <tr><td>{{ shipment.packages[0].cancelledAt | LocalDate }}</td></tr>
                                </tbody>
                            </div>
                        </table>
                    </td>
                    <td>{{shipment.packages && shipment.packages[0] && shipment.packages[0].slotId
                    &&  shipment.packages[0].slotId.cabinetId
                    && shipment.packages[0].slotId.cabinetId.cabinetNumber + shipment.packages[0].slotId.slotNumber}}</td>

                    <td>
                        <p class="m-b-0">
                            {{shipment.packages && shipment.packages.length ? shipment.packages[0].label : '&nbsp;'}}
                        </p>
                        <small style="white-space: pre-wrap; cursor: pointer" (click)="getLabel(shipment.packages[0].label)"><a>View Label</a></small>
                    </td>
                    <td>{{shipment.packages && shipment.packages.length ? (shipment.packages[0].size | RemoveUnderscore) : '&nbsp;'}}</td>
                    <td>{{shipment.lockerId && shipment.lockerId.name}}</td>
                    <td>
                        <div class="btn-group btn-group-xs"
                             *ngIf="this.currentUser.role.endsWith(CONSTANT.ROLE_ADMIN)">
                            <button type="button" class="btn btn-primary"
                                    *ngIf="this.currentUser.role === CONSTANT.ROLE_ADMIN || (this.currentUser.role === CONSTANT.ROLE_COMPANY_ADMIN && shipment.status === SHIPMENT_STATUS.PREPARING)"
                                    [routerLink]="['/shipments/'+shipment._id]">
                                <i class="fa fa-edit"></i>
                            </button>
                        </div>
                    </td>

                    <ng-template #DropOffCodeDialogBox>
                        <div class="modal-header">
                            <h4 class="modal-title pull-left">
                                {{outboundPickupCode ? 'Pickup code' : 'Drop - off code'}}
                            </h4>
                            <button type="button" class="close pull-right" aria-label="Close"
                                    (click)="closeDropOffCodeModel()">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p>
                                Shipment <b>{{ shipmentInfo.label }}</b> <br> {{outboundPickupCode ? 'Pickup code' : 'Drop-off code'}}:
                            </p>

                            <div class="text-center"
                                 style="display: flex;justify-content: center;align-items: flex-end;">
                                <div>
                                    <h1 style="text-align:center;color:#001f89;margin-top: 5px;" #dropOffCodeText>
                                        {{ shipmentInfo.dropOffCode }}
                                    </h1>
                                </div>
                                <div>
                                    <button type="button" class="btn btn-lg btn-link" tooltip="Copy to clipboard"
                                            placement="right"
                                            (click)="copyDropOffCode(dropOffCodeText);" style="font-size: 1.4rem;color: black;margin-bottom: 4px;">
                                        <i class="fa fa-files-o" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="alert-success text-center" *ngIf="shipmentInfo.isCopied">Copied</div>
                        </div>
                    </ng-template>
                </tr>
                </tbody>
            </table>
        </div>
        <h3 *ngIf="isDataLoaded && !shipmentArr.length" class="text-center">No shipments found.</h3>
        <div class="col-lg-12 text-center" *ngIf="shipmentArr.length">
            <pagination [totalItems]="totShipments" [(ngModel)]="filterObj.page" [itemsPerPage]="filterObj.limit"
                        [boundaryLinks]="true"
                        [maxSize]="5" (pageChanged)="fnPageChanged($event)"></pagination>
        </div>
    </div>
</div>
