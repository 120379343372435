import {Injectable} from '@angular/core';
import {HttpClient, HttpParams } from '@angular/common/http';

import {Constant} from '../../common/constant';
import {Company} from './company';

@Injectable()
export class CompaniesService {

    constructor(private http: HttpClient) {
    }

    /**
     * Get list of companies
     * @param {object} params
     * */
    fnGetCompanies(params?: object) {
        let newParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                newParams = newParams.append(key, params[key]);
            }
        }
        const qString = newParams.toString();
        return new Promise((resolve, reject) => {
            this.http
                .get(Constant.API_URL + 'api/companies' + (qString ? '?' + qString : ''))
                .subscribe((response: Company) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Get company
     * @param {string} id
     * */
    fnGetCompany(id: string) {
        return new Promise((resolve, reject) => {
            this.http
                .get(Constant.API_URL + 'api/companies/' + id)
                .subscribe((response: Company) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Create new company
     * @param {object} companyObj
     * */
    fnCreateCompany(companyObj: Company) {
        return new Promise((resolve, reject) => {
            this.http
                .post(Constant.API_URL + 'api/companies', companyObj)
                .subscribe((response: Company) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Update company
     * @param {object} companyObj
     * */
    fnUpdateCompany(companyObj: Company) {
        return new Promise((resolve, reject) => {
            this.http
                .put(Constant.API_URL + 'api/companies/' + companyObj._id, companyObj)
                .subscribe((response: Company) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Delete company
     * @param {string} id
     **/
    fnDeleteCompany(id: string) {
        return new Promise((resolve, reject) => {
            this.http
                .delete(Constant.API_URL + 'api/companies/' + id)
                .subscribe((response) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

}
