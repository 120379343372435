import {Injectable, TemplateRef} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Constant} from '../../common/constant';
import {Plan} from './plans';
import { BsModalService} from 'ngx-bootstrap/modal';
import {ToastrService} from '../../common/toastr.service';

@Injectable()
export class PaymentPlansService {

    constructor(private _bsModalService: BsModalService,
                private toastr: ToastrService,
                private isActivePlanModalService: BsModalService,
                private http: HttpClient) {
    }

    /**
     * Create new plan
     * @param {object} planObj
     * */
    fnCreatePlan(planObj: Plan) {
        return new Promise((resolve, reject) => {
            this.http
                .post(Constant.API_URL + 'api/payment-plan', planObj)
                .subscribe((response: Plan) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /** Get plans
     * @param {object} params
     * */
    fnGetPlans(params?: object) {
        let urlSearchParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key) && params[key]) {
                urlSearchParams = urlSearchParams.append(key, params[key]);
            }
        }
        const qString = urlSearchParams.toString();
        return new Promise((resolve, reject) => {
            this.http
                .get(Constant.API_URL + 'api/payment-plans' + (qString ? '?' + qString : ''))
                .subscribe((response: any) => {
                    const plansRes = response.data[0];
                    resolve(plansRes);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Get plan
     * @param {string} id
     * */
    fnGetPlan(id: string) {
        return new Promise((resolve, reject) => {
            this.http
                .get(Constant.API_URL + 'api/payment-plan/' + id)
                .subscribe((response: Plan) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Update plan
     * @param {object} planObj
     * */
    fnUpdatePlan(planObj: Plan) {
        return new Promise((resolve, reject) => {
            this.http
                .put(Constant.API_URL + 'api/payment-plan/' + planObj._id, planObj)
                .subscribe((response: Plan) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /** Delete plan
     * @param {string} id
     * */
    fnDeletePlan(id: string) {
        return new Promise((resolve, reject) => {
            this.http
                .delete(Constant.API_URL + 'api/payment-plan/' + id)
                .subscribe((response) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    fnChangeUserPlans(plansObj?: any) {
        return new Promise((resolve, reject) => {
            this.http
                .put(Constant.API_URL + 'api/PaymentPlans/update-users-plan/' + plansObj.currentPlan._id, plansObj)
                .subscribe((response: any) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }
}
