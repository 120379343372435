import {Component, Input, OnInit} from '@angular/core';
import {SlotDetails} from '../lockers/locker'
import {ToastrService} from '../../common/toastr.service';
import {SLOT_SIZE} from '../../common/enums'
import {Constant} from "../../common/constant";

@Component({
    selector: 'app-bank',
    templateUrl: './bank.component.html',
    styleUrls: ['./bank.component.css']
})
export class BankComponent implements OnInit {

    @Input() public cabinet: Array<SlotDetails>
    @Input() public cabinetNumber: string;
    @Input() public cabinetType: string;

    public slotSizes = Object['values'](SLOT_SIZE);

    readonly CONSTANT = Constant;

    constructor(private toast: ToastrService) {
    }

    ngOnInit() {
    }

    addSlot = (): void => {
        const noOfExistingSlots = this.cabinet.length;
        (this.cabinetType === Constant.HOST_BANK && noOfExistingSlots <= 9) || (this.cabinetType === Constant.LOCKER_BANK && noOfExistingSlots <= 14) ?
            this.cabinet.push({
                slotType: 'SMALL',
                slotNumber: (noOfExistingSlots + 1 <= 9) ? '0' + (noOfExistingSlots + 1) : noOfExistingSlots + 1 + '',
                isUpperSlot: false
            }) : this.toast.fnError((this.cabinetType === Constant.HOST_BANK) ? 'Host bank can not contain more than 10 slots' : 'Locker bank can not contain more than 15 slots')
    }

    adjustSlots = (noOfSlots: number): void => {
        const noOfExistingSlots = this.cabinet.length;
        if (noOfSlots >= 1 && ((this.cabinetType === Constant.HOST_BANK && noOfSlots <= 10) || (this.cabinetType === Constant.LOCKER_BANK && noOfSlots <= 15))) {
            if (noOfExistingSlots < noOfSlots) {
                for (let index = noOfExistingSlots; index < noOfSlots; index++) {
                    this.addSlot();
                }
            } else {
                for (let index = noOfExistingSlots; index > noOfSlots; index--) {
                    this.cabinet.pop();
                }
            }
        } else {
            const bank = this.cabinetType === Constant.HOST_BANK ? 'Host Bank' : 'Locker Bank';
            const maxSlots = this.cabinetType === Constant.HOST_BANK ? 10 : 15;
            this.toast.fnError(noOfSlots < 1 ? `One slot is mandatory to create ${bank}` : `${bank} can not contain more than ${maxSlots} slots`);
        }
    }

    deleteSlot = (): void => {
        const noOfExistingSlots = this.cabinet.length;
        const bank = (this.cabinetType === Constant.HOST_BANK) ? 'Host Bank' : 'Locker Bank';
        noOfExistingSlots > 1 ? this.cabinet.pop() : this.toast.fnError(`One slot is mandatory to create ${bank}`);
    }

    setSlotSize = (size: string, slotIndex: number): void => {
        this.cabinet[slotIndex].slotType = size;
    }

    setUpperSlot = (checkedFlag: boolean, slotIndex: number): void => {
        this.cabinet[slotIndex].isUpperSlot = checkedFlag;
    }
}
