<div class="row page">
    <div class="col-lg-12">
        <h1 class="page-header ">Dashboard</h1>
    </div>
    <div class="col-lg-12" *ngIf="currentUser.role === CONSTANT.ROLE_ADMIN">
        <div class="row">
            <div class="col-sm-10">
                <div class="form-group">
                    <select id="company" class="form-control" name="company" (change)="changeCompany()"
                            [(ngModel)]="selectedCompany">
                        <option [ngValue]="item._id" *ngFor="let item of companiesArr">{{item.name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-sm-2">
                <button class="btn btn-primary btn-block" (click)="clearAdminFilters()">Clear Filters</button>
            </div>


            <div class="col-sm-12">
                <div class="panel panel-primary">
                    <div class="panel-heading">
                        <h3 class="panel-title">Parcels</h3>
                    </div>
                    <div class="panel-body">
                        <div class="col-sm-10">
                            <div class="form-group">
                                <input type="text" name="daterangeInput1" class="form-control"
                                       id="packageDates"
                                       daterangepicker
                                       placeholder="Select Date Range"
                                       [(ngModel)]="packageDates"
                                       [options]="{autoUpdateInput: false, opens: 'right', startDate: packageDateRange.startDate, endDate: packageDateRange.endDate}"
                                       (applyDaterangepicker)="selectDate($event, packageFilterObj, 'packageDates')"/>
                            </div>
                        </div>
                        <div class="col-sm-2">
                            <button class="btn btn-primary btn-block" (click)="clearDate(packageFilterObj, 'packageDates')">Clear Filter
                            </button>
                        </div>
                        <div class="col-sm-12 flexbox-container">

                            <div class="col-sm-6 panel panel-default">
                                <h4>Deposited</h4>
                                <div class="panel-body">
                                    <div class="col-sm-6">
                                        <div class="text-center">
                                            Total<h1><b>{{packageCounts && packageCounts.depositedCount ?
                                            packageCounts.depositedCount : 0}}</b></h1>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="text-center">
                                            Today<h1><b>{{packageCounts && packageCounts.droppedToday ?
                                            packageCounts.droppedToday : 0}}</b></h1>
                                        </div>
                                    </div>
                                    <div class="col-sm-2 col-sm-offset-1">
                                        <div class="text-center">
                                            UPS<h4><b>{{packageCounts && packageCounts.upsCount ? packageCounts.upsCount
                                            : 0}}</b></h4>
                                        </div>
                                    </div>
                                    <div class="col-sm-2">
                                        <div class="text-center">
                                            Purolator<h4><b>{{packageCounts && packageCounts.purolatorCount ?
                                            packageCounts.purolatorCount : 0}}</b></h4>
                                        </div>
                                    </div>
                                    <div class="col-sm-2">
                                        <div class="text-center">
                                            FedEx<h4><b>{{packageCounts && packageCounts.fedexCount ?
                                            packageCounts.fedexCount : 0}}</b></h4>
                                        </div>
                                    </div>
                                    <div class="col-sm-2">
                                        <div class="text-center">
                                            DHL<h4><b>{{packageCounts && packageCounts.dhlCount ? packageCounts.dhlCount
                                            : 0}}</b></h4>
                                        </div>
                                    </div>
                                    <div class="col-sm-2">
                                        <div class="text-center">
                                            Others<h4><b>{{packageCounts && packageCounts.otherCount ?
                                            packageCounts.otherCount : 0}}</b></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6 panel panel-default">
                                <h4>Picked Up</h4>
                                <div class="panel-body">
                                    <div class="col-sm-6">
                                        <div class="text-center">
                                            Total<h1><b>{{packageCounts && packageCounts.pickedCount ?
                                            packageCounts.pickedCount : 0}}</b></h1>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="text-center">
                                            Today<h1><b>{{packageCounts && packageCounts.pickedToday ?
                                            packageCounts.pickedToday : 0}}</b></h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 flexbox-container">
                <div class="col-sm-6 panel panel-primary p-0">
                    <div class="panel-heading">
                        <h3 class="panel-title">Errors</h3>
                    </div>
                    <div class="panel-body">
                        <div class="col-sm-8">
                            <div class="form-group">
                                <input type="text" name="daterangeInput2" class="form-control"
                                       id="errorsDates"
                                       daterangepicker
                                       placeholder="Select Date Range"
                                       [(ngModel)]="errorsDates"
                                       [options]="{autoUpdateInput: false, opens: 'right', startDate: errorDateRange.startDate, endDate: errorDateRange.endDate}"
                                       (applyDaterangepicker)="selectDate($event, errorsFilterObj, 'errorsDates')"/>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <button class="btn btn-primary btn-block" (click)="clearDate(errorsFilterObj, 'errorsDates')">Clear Filter
                            </button>
                        </div>
                        <div class="col-sm-6">
                            <div class="text-center">
                                Sendgrid<h1><b>{{errorCounts && errorCounts.sendGridErrorCount ?
                                errorCounts.sendGridErrorCount : 0}}</b></h1>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="text-center">
                                Twilio<h1><b>{{errorCounts && errorCounts.twilioErrorCount ?
                                errorCounts.twilioErrorCount : 0}}</b></h1>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="text-center">
                                Slot Jams<h1><b>{{slotCounts && slotCounts.jammedSlotCount ? slotCounts.jammedSlotCount
                                : 0}}</b></h1>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="text-center">
                                Exceptions<h1><b>{{packageCounts && packageCounts.exceptionCount ?
                                packageCounts.exceptionCount : 0}}</b></h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 panel panel-primary p-0">
                    <div class="panel-heading">
                        <h3 class="panel-title">Feedbacks</h3>
                    </div>
                    <div class="panel-body">
                        <div class="col-sm-8">
                            <div class="form-group">
                                <input type="text" name="daterangeInput3" class="form-control"
                                       id="feedbackDates"
                                       daterangepicker
                                       placeholder="Select Date Range"
                                       [(ngModel)]="feedbackDates"
                                       [options]="{autoUpdateInput: false, opens: 'right', startDate: feedbackDateRange.startDate, endDate: feedbackDateRange.endDate}"
                                       (applyDaterangepicker)="selectDate($event, feedbackFilterObj, 'feedbackDates')"/>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <button class="btn btn-primary btn-block" (click)="clearDate(feedbackFilterObj, 'feedbackDates')">Clear Filter
                            </button>
                        </div>
                        <div class="col-sm-6">
                            <div class="text-center">
                                Today<h1><b>{{feedbackCounts && feedbackCounts.todaysCount ? feedbackCounts.todaysCount
                                : 0}}</b></h1>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="text-center">
                                Total<h1><b>{{feedbackCounts && feedbackCounts.totalCount ? feedbackCounts.totalCount :
                                0}}</b></h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 flexbox-container">
                <div class="col-sm-6 panel panel-primary p-0">
                    <div class="panel-heading">
                        <h3 class="panel-title">Upcoming Holidays</h3>
                    </div>
                    <div class="panel-body col-sm-12">
                        <table *ngFor="let holiday of holidays" width="100%" style="margin-bottom: 10px;">
                            <thead>
                            <tr width="100%">
                                <th class="bg-primary" colspan="12">
                                    {{ holiday[0].date | date }}
                                </th>
                            </tr>
                            </thead>
                            <tbody width="100%">
                            <tr width="100%" *ngFor="let day of holiday">
                                <td width="30%" style="overflow-x: auto; overflow-y: hidden;">
                                    {{ day.description }}
                                </td>
                                <td width="70%" style="overflow-x: auto; overflow-y: hidden;">
                                    <span *ngFor="let state of day.provinces; let last = last">
                                        {{ PROVINCES_STATES[state] + (last ? '' : ', ')}}
                                    </span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
