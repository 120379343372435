import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Constant} from '../../common/constant';
import {ToastrService} from '../../common/toastr.service';
import {PaymentPlan} from './payment-plans';

@Injectable()
export class PaymentPlanService {

    constructor(private toastr: ToastrService,
                private http: HttpClient) {
    }

    /** Get plans
     * @param {object} params
     * */
    getPlans(params?: object) {
        let urlSearchParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key) && params[key]) {
                urlSearchParams = urlSearchParams.append(key, params[key]);
            }
        }
        const qString = urlSearchParams.toString();
        return new Promise((resolve, reject) => {
            this.http
                .get(Constant.API_URL + 'api/payment-plans' + (qString ? '?' + qString : ''))
                .subscribe((response: any) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Create new plan
     * @param {object} planObj
     * */
    createPlan(planObj: PaymentPlan) {
        return new Promise((resolve, reject) => {
            this.http
                .post(Constant.API_URL + 'api/payment-plan', planObj)
                .subscribe((response: PaymentPlan) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Get plan by id
     * @param {string} planId
     * */
    getPlan(planId: string) {
        return new Promise((resolve, reject) => {
            this.http
                .get(Constant.API_URL + 'api/payment-plan/' + planId)
                .subscribe((response: PaymentPlan) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Update plan
     * @param {PaymentPlan} planObj
     * @param {string} planId
     * */
    updatePlan(planObj: PaymentPlan, planId: string) {
        return new Promise((resolve, reject) => {
            this.http
                .put(Constant.API_URL + 'api/payment-plan/' + planId, planObj)
                .subscribe((response: PaymentPlan) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /** Delete plan
     * @param {string} id
     * */
    deletePlan(id: string) {
        return new Promise((resolve, reject) => {
            this.http
                .delete(Constant.API_URL + 'api/payment-plan/' + id)
                .subscribe((response) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }
}
