<div class="row page">
    <div class="col-lg-12">
        <h1 class="page-header">My Profile
            <a href="javascript:void(0);" class="btn btn-primary pull-right" (click)="isReadOnly ? isReadOnly = !isReadOnly : resetUserForm()">
                <i class="fa fa-fw" [ngClass]="{'fa-edit': isReadOnly, 'fa-close': !isReadOnly}"></i>
            </a>
        </h1>
    </div>
    <pp-spinner [isSpinnerLoad]="!isDataLoaded"></pp-spinner>

    <div class="col-lg-12" [hidden]="!isDataLoaded">
        <form #userForm="ngForm" (ngSubmit)="userForm.valid && updateUser(user)" novalidate *ngIf="user">
            <fieldset class="col-md-12 fieldset" [disabled]="isReadOnly">
                <legend class="fieldset-legend">Basic</legend>
                <div class="form-group" [ngClass]="{'has-error': (userForm.submitted || firstName.touched) && !firstName.valid && !isReadOnly}">
                    <label for="firstName">First Name<span class="text-danger">*</span></label>
                    <input id="firstName" type="text" class="form-control" name="firstName" placeholder="Enter a first name" [(ngModel)]="user.firstName"
                           #firstName="ngModel" required/>
                    <div *ngIf="(userForm.submitted || firstName.touched) && !firstName.valid && !isReadOnly">
                        <small class="text-danger" *ngIf="firstName.errors['required']">First name is required.</small>
                    </div>
                </div>
                <div class="form-group" [ngClass]="{'has-error': (userForm.submitted || lastName.touched) && !lastName.valid && !isReadOnly}">
                    <label for="lastName">Last Name<span class="text-danger">*</span></label>
                    <input id="lastName" type="text" class="form-control" name="lastName" placeholder="Enter a last name" [(ngModel)]="user.lastName"
                           #lastName="ngModel" required/>
                    <div *ngIf="(userForm.submitted || lastName.touched) && !lastName.valid && !isReadOnly">
                        <small class="text-danger" *ngIf="lastName.errors['required']">Last name is required.</small>
                    </div>
                </div>
                <div class="form-group">
                    <label for="unit">Unit</label>
                    <input id="unit" class="form-control" [(ngModel)]="user.unit" name="unit" [disabled]="true"
                         #unit="ngModel"/>
                </div>
                <div class="form-group">
                    <label for="email">Email</label>
                    <input id="email" class="form-control" [(ngModel)]="user.email" name="email" [disabled]="true"
                         #email="ngModel"/>
                </div>
                <div class="form-group" [ngClass]="{'has-error': (userForm.submitted || phone.touched) && !phone.valid && !isReadOnly}">
                    <label for="phone">Phone #<span class="text-danger">*</span></label>
                    <input id="phone" type="phone" class="form-control" name="phone" placeholder="Enter a phone #" [(ngModel)]="user.phone"
                           #phone="ngModel" required/>
                    <div *ngIf="(userForm.submitted || phone.touched) && !phone.valid && !isReadOnly">
                        <small class="text-danger" *ngIf="phone.errors['required']">Phone # is required.</small>
                    </div>
                </div>
            </fieldset>

            <div class="form-group" *ngIf="!isReadOnly">
                <button class="btn btn-primary" type="submit">Update</button>
                <button class="btn btn-warning" type="button" (click)="resetUserForm()">Cancel</button>
            </div>
        </form>
    </div>
</div>
