<script src="../../../../../../../../../Users/webosmotic/Desktop/local.ts"></script>
<div id="wrapper" (window:resize)="onResize()">
    <!-- Navigation -->
    <nav class="navbar navbar-default navbar-fixed-top" role="navigation" style="margin-bottom: 0">
        <div class="navbar-header pull-left">
            <button type="button" class="navbar-toggle pull-left m-r-0" (click)="fnToggleSideBar()">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <a class="navbar-brand p-0" href="javascript:void(0);">
                <img class="navbar-logo" src="assets/images/pp_logo.png" alt="Parcel Port"/>
            </a>
        </div>
        <!-- /.navbar-header -->

        <ul class="nav navbar-top-links navbar-right pull-right">
            <li class="dropdown">
                <a class="dropdown-toggle" data-toggle="dropdown" href="#">
                    <i class="fa fa-user fa-fw"></i> {{this.currentUser.fullName}} <i class="fa fa-caret-down"></i>
                </a>
                <ul class="dropdown-menu">
                    <li>
                        <a href="javascript:void(0);" [routerLink]="['/my-profile']">
                            <i class="fa fa-user fa-fw"></i> My Profile</a>
                    </li>
                    <li>
                        <a href="javascript:void(0);" (click)="fnSignOut()">
                            <i class="fa fa-sign-out fa-fw"></i> Sign out</a>
                    </li>
                </ul>
                <!-- /.dropdown-user -->
            </li>
            <!-- /.dropdown -->
        </ul>
        <!-- /.navbar-top-links -->
    </nav>
    <div class="sidebar" [ngClass]="isToggleSideBar ? 'open-sidebar' : ''">
        <ul>
            <li (click)="fnToggleSideBar()">
                <a [routerLink]="['/my-profile']" [routerLinkActive]="'active'">
                    <i class="fa fa-user fa-fw"></i> My Profile</a>
            </li>
            <li *ngIf="this.Permission.canSeeScreen(this.currentUser, SCREEN.DASHBOARD)" (click)="fnToggleSideBar()">
                <a [routerLink]="['/dashboard']" [routerLinkActive]="'active'">
                    <i class="fa fa-fw fa-tachometer"></i> Dashboard</a>
            </li>
            <li *ngIf="this.Permission.canSeeScreen(this.currentUser, SCREEN.SETTINGS)" (click)="fnToggleSideBar()">
                <a [routerLink]="['/settings']" [routerLinkActive]="'active'">
                    <i class="fa fa-fw fa-cog"></i> Settings</a>
            </li>
            <li *ngIf="this.Permission.canSeeScreen(this.currentUser, SCREEN.SUPPORT)" (click)="fnToggleSideBar()">
                <a [routerLink]="['/support']" [routerLinkActive]="'active'">
                    <i class="fa fa-fw fa-question-circle"></i> Support</a>
            </li>
            <li *ngIf="this.Permission.canSeeScreen(this.currentUser, SCREEN.COMPANIES)" (click)="fnToggleSideBar()">
                <a [routerLink]="['/companies']" [routerLinkActive]="'active'">
                    <i class="fa fa-fw fa-building-o"></i> Companies</a>
            </li>
            <li (click)="fnToggleSideBar()" *ngIf="this.Permission.canSeeScreen(this.currentUser, SCREEN.PAYMENT_PLANS)">
                <a [routerLink]="['/payment-plans']" [routerLinkActive]="'active'">
                    <i class="fa fa-fw fa-credit-card"></i> Payment Plans</a>
            </li>
            <li (click)="fnToggleSideBar()">
                <a [routerLink]="['/payment-details']" *ngIf="this.Permission.canSeeScreen(this.currentUser, SCREEN.PAYMENT_DETAILS)"
                   [routerLinkActive]="'active'">
                    <i class="fa fa-fw fa-credit-card"></i> Payment Details</a>
            </li>
            <li *ngIf="this.Permission.canSeeScreen(this.currentUser, SCREEN.LOCKERS)" (click)="fnToggleSideBar()">
                <a [routerLink]="['/lockers']" [routerLinkActive]="'active'">
                    <i class="fa fa-fw fa-lock"></i> Lockers</a>
            </li>
            <li *ngIf="this.Permission.canSeeScreen(this.currentUser, SCREEN.COMPANY_LOCKERS)" (click)="fnToggleSideBar()">
                <a [routerLink]="['/company-lockers']" [routerLinkActive]="'active'">
                    <i class="fa fa-fw fa-lock"></i> Lockers</a>
            </li>
            <li *ngIf="this.Permission.canSeeScreen(this.currentUser, SCREEN.CABINETS)" (click)="fnToggleSideBar()">
                <a [routerLink]="['/cabinets']" [routerLinkActive]="'active'">
                    <i class="fa fa-fw fa-th"></i> Cabinets</a>
            </li>
            <li *ngIf="this.Permission.canSeeScreen(this.currentUser, SCREEN.SLOTS)" (click)="fnToggleSideBar()">
                <a [routerLink]="['/slots']" [routerLinkActive]="'active'">
                    <i class="fa fa-fw fa-archive"></i> Slots</a>
            </li>
            <!--<li (click)="fnToggleSideBar()"
                *ngIf="this.currentUser.role === CONSTANT.ROLE_ADMIN || this.currentUser.role.includes(CONSTANT.CARRIER)">
                <a [routerLink]="['/purolator']" [routerLinkActive]="'active'">
                    <i class="fa fa-fw fa-product-hunt"></i> Purolator</a>
            </li>-->
            <li (click)="fnToggleSideBar()" *ngIf="this.Permission.canSeeScreen(this.currentUser, SCREEN.SHIPMENTS)">
                <a [routerLink]="['/shipments']" [routerLinkActive]="'active'">
                    <i class="fa fa-fw fa-truck"></i> Shipments</a>
            </li>
            <li (click)="fnToggleSideBar()" *ngIf="this.Permission.canSeeScreen(this.currentUser, SCREEN.OUTBOUND_SHIPMENTS)">
                <a [routerLink]="['/outbound-shipments']" [routerLinkActive]="'active'">
                    <i class="fa fa-fw fa-truck"></i> Outbound Shipments</a>
            </li>
            <li (click)="fnToggleSideBar()" *ngIf="this.Permission.canSeeScreen(this.currentUser, SCREEN.USER_SHIPMENTS)">
                <a [routerLink]="['/shipment-history']" [routerLinkActive]="'active'">
                    <i class="fa fa-fw fa-truck"></i> Shipments</a>
            </li>
            <li (click)="fnToggleSideBar()" *ngIf="this.Permission.canSeeScreen(this.currentUser, SCREEN.PENDING_SHIPMENTS)">
                <a [routerLink]="['/pending-shipments']" [routerLinkActive]="'active'">
                    <i class="fa fa-fw fa-truck"></i> Pending Shipments</a>
            </li>
            <li *ngIf="this.Permission.canSeeScreen(this.currentUser, SCREEN.USERS)"
                (click)="fnToggleSideBar()">
                <a [routerLink]="['/users']" [routerLinkActive]="'active'">
                    <i class="fa fa-fw fa-users"></i> Users</a>
            </li>
            <li *ngIf="this.Permission.canSeeScreen(this.currentUser, SCREEN.LOCKER_DIRECTORY)"
                (click)="fnToggleSideBar()">
                <a [routerLink]="['/locker-directory']" [routerLinkActive]="'active'">
                    <i class="fa fa-fw fa-users"></i> Locker Directory</a>
            </li>
            <li *ngIf="this.Permission.canSeeScreen(this.currentUser, SCREEN.ENROLLMENT_USERS)"
                (click)="fnToggleSideBar()">
                <a [routerLink]="['/enrollment-user']" [routerLinkActive]="'active'">
                    <i class="fa fa-fw fa-address-book-o"></i> New User</a>
            </li>
            <li *ngIf="this.Permission.canSeeScreen(this.currentUser, SCREEN.TRANSACTIONS)" (click)="fnToggleSideBar()">
                <a [routerLink]="['/transactions']" [routerLinkActive]="'active'">
                    <i class="fa fa-fw fa-exchange"></i> Transactions</a>
            </li>
            <li *ngIf="this.Permission.canSeeScreen(this.currentUser, SCREEN.ANALYTICS)"
                (click)="fnToggleSideBar()">
                <a [routerLink]="['/analytics']" [routerLinkActive]="'active'">
                    <i class="fa fa-fw fa-bar-chart-o"></i> Analytics</a>
            </li>
            <li *ngIf="this.Permission.canSeeScreen(this.currentUser, SCREEN.NOTIFICATIONS)" (click)="fnToggleSideBar()">
                <a [routerLink]="['/notifications']" [routerLinkActive]="'active'">
                    <i class="fa fa-fw fa-bell"></i> Notifications</a>
            </li>
            <li *ngIf="this.Permission.canSeeScreen(this.currentUser, SCREEN.FEEDBACK)" (click)="fnToggleSideBar()">
                <a [routerLink]="['/feedback']" [routerLinkActive]="'active'">
                    <i class="fa fa-fw fa-comment"></i> Feedback</a>
            </li>
            <li *ngIf="this.Permission.canSeeScreen(this.currentUser, SCREEN.BUILDING_DATA)" (click)="fnToggleSideBar()">
                <a [routerLink]="['/building-data']" [routerLinkActive]="'active'">
                    <i class="fa fa-fw fa-building"></i> Building Data</a>
            </li>
            <li *ngIf="this.Permission.canSeeScreen(this.currentUser, SCREEN.MANAGE_APK)"
                (click)="fnToggleSideBar()">
                <a [routerLink]="['/manage-apk']" [routerLinkActive]="'active'">
                    <i class="fa fa-fw fa-android"></i> Manage APK</a>
            </li>
        </ul>
        <!-- /.sidebar-collapse -->
    </div>
    <!-- /.navbar-static-side -->
    <div id="page-wrapper">
        <router-outlet></router-outlet>
    </div>
</div>
