<div class="row page">
    <div class="col-lg-12">
        <h1 class="page-header">Manage APK
            <button class="pull-right btn btn-primary" [routerLink]="['/manage-apk/upload']">
                <i class="fa fa-plus m-r-4"></i> <span class="hidden-xs">Upload APK</span>
            </button>
        </h1>
    </div>
    <pp-spinner [isSpinnerLoad]="!isDataLoaded"></pp-spinner>
    <div class="col-lg-12 m-t-10">
        <div class="table-responsive" *ngIf="apkArr.length">
            <table>
                <thead>
                <tr>
                    <th class="bg-primary createdAt" (click)="sortBy('createdAt')">
                        Created At <i [class]="setSortIcon('createdAt')"></i></th>
                    <th class="bg-primary version cursor-auto">Version</th>
                    <th class="bg-primary cursor-auto">Latest</th>
                    <th class="bg-primary apk-name" (click)="sortBy('name')">
                        Name <i [class]="setSortIcon('name')"></i></th>
                    <th class="bg-primary cursor-auto">Description</th>
                    <th class="bg-primary cursor-auto">Download APK</th>
                    <th class="bg-primary cursor-auto" *ngIf="currentUser.role === CONSTANT.ROLE_ADMIN">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let apk of apkArr">
                    <td *ngIf="apk.createdAt">{{apk.createdAt | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
                    <td *ngIf="!apk.createdAt"></td>
                    <td>{{apk.version ? apk.version : '&nbsp;'}}</td>
                    <td><i class="fa fa-check" *ngIf="apk.latest"></i>{{apk.latest ? '' : '&nbsp;'}}</td>
                    <td>{{apk.name ? apk.name : '&nbsp;'}}</td>
                    <td>{{apk.description ? apk.description : '&nbsp;'}}</td>
                    <td><a class="btn btn-xs btn-info" href="{{apk.url}}">Download APK</a></td>
                    <td *ngIf="currentUser.role === CONSTANT.ROLE_ADMIN">
                        <div class="btn-group btn-group-xs">
                            <button type="button" class="btn btn-primary" [routerLink]="['/manage-apk/'+apk._id]">
                                <i class="fa fa-edit"></i>
                            </button>
                            <button type="button" class="btn btn-danger" *ngIf="!apk.latest"
                                    (click)="fnDeleteAPK(apk._id)">
                                <i class="fa fa-trash-o"></i>
                            </button>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <h3 *ngIf="isDataLoaded && !apkArr.length" class="text-center">No apk found.</h3>
        <div class="col-lg-12 text-center" *ngIf="apkArr.length">
            <pagination [totalItems]="totalApk" [(ngModel)]="filterObj.page" [itemsPerPage]="filterObj.limit"
                        [boundaryLinks]="true" [maxSize]="5" (pageChanged)="fnPageChanged($event)"></pagination>
        </div>
    </div>
</div>
