import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Constant} from './constant';
import {Cookie} from './cookie';
import {User} from '../authenticate/users/user';
import {UsersService} from '../authenticate/users/users.service';
import {StorageService} from './storage.service';
import jwt_decode from 'jwt-decode';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class AuthService {

    private currentUser: User;

    constructor(private http: HttpClient, private cookie: Cookie, private usersService: UsersService, private storageService: StorageService) {
        if (this.cookie.get('AUTH_TOKEN')) {
            this.currentUser = jwt_decode(this.cookie.get('AUTH_TOKEN'));
        }
    }

    /**
     * User sign in.
     * @param {object} obj
     * */
    fnSignIn(signInObj: any) {
        const obj = {...signInObj, password: this.encryptPassword(signInObj.password)};
        return new Promise((resolve, reject) => {
            this.http
                .post(Constant.API_URL + 'api/auth/login', obj)
                .subscribe((response: any) => {
                    this.currentUser = jwt_decode(response.token);
                    this.cookie.set('AUTH_TOKEN', response.token);
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * User sign up.
     * @param {object} obj
     * */
    fnSignUp(obj: any) {
        return new Promise((resolve, reject) => {
            this.http
                .post(Constant.API_URL + 'api/sign-up', obj)
                .subscribe((response: any) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * User sign out.
     * */
    fnSignOut() {
        return new Promise((resolve, reject) => {
            this.http
                .get(Constant.API_URL + 'api/auth/logout')
                .subscribe((response) => {
                    this.currentUser = {};
                    this.fnRemoveTokenCookie();
                    this.storageService.removeAllData();
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Verify OTP.
     * @param {object} obj
     * */
    fnVerifyOTP(obj: any) {
        return new Promise((resolve, reject) => {
            this.http
                .post(Constant.API_URL + 'api/auth/verify-otp', obj)
                .subscribe((response: any) => {
                    this.currentUser = jwt_decode(response.token);
                    this.cookie.set('AUTH_TOKEN', response.token);
                    resolve(this.currentUser);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Resend Verifaction code.
     * @param {object} obj
     * */
    fnResendCode(obj: any) {
        return new Promise((resolve, reject) => {
            this.http
                .post(Constant.API_URL + 'api/auth/resend-code', obj)
                .subscribe((response: any) => {
                    resolve(response);
                }, (error) => {
                    reject(error);
                });
        });
    }

    /**
     * Confirm Verification Code.
     * @param {string} code
     * */
    fnConfirmCode(code: string) {
        return new Promise((resolve, reject) => {
            this.http
                .get(Constant.API_URL + 'api/auth/confirm/' + code)
                .subscribe((response: any) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Accept terms and Conditions.
     * @param {string} code
     * */
    fnAcceptTerms(id: string) {
        return new Promise((resolve, reject) => {
            this.http
                .post(Constant.API_URL + 'api/auth/accept-terms', {userId: id})
                .subscribe((response: any) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Set User Password.
     * @param {string} userId
     * @param {string} password
     * @param {string} token
     * */
    async setPassword(userId: string, password: string, token: string) {
        password = this.encryptPassword(password);
        return await this.usersService.resetPassword(userId, password, token)
            .then((response) => {
                this.currentUser = jwt_decode(token);
                this.cookie.set('AUTH_TOKEN', token);
                return Promise.resolve(response);
            })
            .catch((error) => {
                return Promise.reject(error.error);
            });
    }

    /**
     * Change User Password.
     * @param {string} userId
     * @param {object} passwordObj
     * @param {string} token
     * */
    changePassword(userId: string, passwordObj: any, token: string) {
        const headers = new HttpHeaders({'Authorization': `Bearer ${token}`});
        return new Promise((resolve, reject) => {
            this.http
                .put(Constant.API_URL + 'api/user/' + userId + '/change-password',
                    {
                        oldPassword: this.encryptPassword(passwordObj.oldPassword),
                        newPassword: this.encryptPassword(passwordObj.newPassword)
                    },
                    {headers: headers})
                .subscribe((response: any) => {
                    resolve(response);
                }, (error: any) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Verify OTP.
     * @param {object} obj
     * */
    fnResendOTP() {
        return new Promise((resolve, reject) => {
            this.http
                .get(Constant.API_URL + 'api/auth/resend-otp')
                .subscribe((response: any) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Get Current user.
     * */
    fnGetCurrentUser() {
        return this.currentUser;
    }

    /**
     * Get API access token.
     * */
    fnGetToken() {
        return this.cookie.get('AUTH_TOKEN');
    }

    /**
     * Remove access token.
     * */
    fnRemoveTokenCookie() {
        this.cookie.delete('AUTH_TOKEN');
    }

    /**
     * Send Reset password link
     *
     * @param params
     * @returns {Promise}
     */
    fnSendResetPasswordLink(params: any) {
        return new Promise((resolve, reject) => {
            this.http.post(Constant.API_URL + 'api/user/reset-password-link', params)
                .subscribe((response: any) => {
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Verify the reset link token
     *
     * @param {string} resetToken
     * @returns {Promise}
     */
    fnVerifyLink(resetToken: string) {
        return new Promise((resolve, reject) => {
            this.http.get(Constant.API_URL + 'api/user/verify-reset-code/' + resetToken)
                .subscribe((response: any) => {
                    // resolve(response);
                    resolve(response);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Update the new password for the user.
     *
     * @param {string} resetToken
     * @param {string} password
     * @returns {Promise}
     */
    fnResetPassword(resetToken: string, password: string) {
        password = this.encryptPassword(password);
        return new Promise((resolve, reject) => {
            this.http.post(Constant.API_URL + 'api/user/change-password', {newPassword: password, resetCode: resetToken})
                .subscribe((response: any) => {
                    resolve(response);
                }, (error: any) => {
                    reject(error);
                });
        });
    }

    private encryptPassword(password: string): string {
        return CryptoJS.AES.encrypt(password, 'ParcelPortSecretSession').toString();
    }
}
