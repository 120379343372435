import {Component, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
    selector: 'pp-rp-confirm-modal',
    templateUrl: './rp-confirm-modal.component.html',
    styleUrls: ['./rp-confirm-modal.component.css']
})
export class RpConfirmModalComponent implements OnInit {

    public active: boolean;
    public onClose: Subject<boolean>;

    constructor(private _bsModalRef: BsModalRef) {
        this.active = false;
    }

    ngOnInit() {
        this.onClose = new Subject();
    }

    public showConfirmationModal(): void {
        this.active = true;
    }

    public onConfirm(): void {
        this.active = false;
        this.onClose.next(true);
        this._bsModalRef.hide();
    }

    public onCancel(): void {
        this.active = false;
        this.onClose.next(false);
        this._bsModalRef.hide();
    }

    public hideConfirmationModal(): void {
        this.active = false;
        this.onClose.next(null);
        this._bsModalRef.hide();
    }

}
