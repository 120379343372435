import {Component, OnInit} from '@angular/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import * as _ from 'lodash';

import {DeleteModalComponent} from '../../delete-modal/delete-modal.component';
import {AuthService} from '../../common/auth.service';
import {ApkService} from './apk.service';

import {APK} from './apk';
import {User} from '../users/user';
import {Constant} from '../../common/constant';
import {SearchResults} from '../../common/search-results';

@Component({
    selector: 'pp-manage-apk',
    templateUrl: './manage-apk.component.html',
    styleUrls: ['./manage-apk.component.css']
})

export class ManageAPKComponent implements OnInit {

    public isDataLoaded;
    public filterObj: any;
    public currentUser: User;
    public totalApk: number;
    public apkArr: Array<APK> = [];
    readonly CONSTANT = Constant;

    constructor(private _bsModalService: BsModalService, private auth: AuthService, private apkService: ApkService) {
        this.isDataLoaded = false;
        this.totalApk = 0;
        this.filterObj = {sortBy: 'createdAt', orderBy: 'desc', page: 1, limit: 10 };
        this.currentUser = this.auth.fnGetCurrentUser();
    }

    ngOnInit() {
        this.fnGetAllAPK(this.filterObj);
    }

    /**
     * Set column sort icon
     * @param {string} sortBy
     * */
    setSortIcon(sortBy: string) {
        if (this.filterObj.sortBy === sortBy) {
            return this.filterObj.orderBy === 'desc' ? 'fa fa-sort-desc' : 'fa fa-sort-asc';
        }
        return 'fa fa-sort';
    }

    /**
     * Sort by column name
     * @param {string} sortBy
     * */
    sortBy(sortBy: string) {
        if (this.filterObj.sortBy === sortBy) {
            this.filterObj.sortBy = sortBy;
            if (this.filterObj.orderBy === 'asc') {
                this.filterObj.orderBy = 'desc';
            } else if (this.filterObj.orderBy === 'desc') {
                this.filterObj.sortBy = 'createdAt';
                this.filterObj.orderBy = 'asc';
            } else {
                this.filterObj.orderBy = 'asc';
            }
        } else {
            this.filterObj.orderBy = 'asc';
            this.filterObj.sortBy = sortBy;
        }
        this.fnGetAllAPK(this.filterObj);
    }

    /**
     * Get list of Apks
     * @param {object} params
     * */
    fnGetAllAPK(params?: object) {
        this.isDataLoaded = false;
        this.apkService.fnGetAllAPK(params)
            .then((response: SearchResults<APK>) => {
                this.isDataLoaded = true;
                this.apkArr = response.data;
                this.totalApk = response.total;
            });
    }

    /**
     * Change pagination
     * @param {any} event
     * */
    fnPageChanged(event: any) {
        this.filterObj.page = event.page;
        this.fnGetAllAPK(this.filterObj);
    }

    /**
     * Delete APK file and details
     * @param {string} id
     * */
    fnDeleteAPK(id: string) {
        const modal = this._bsModalService.show(DeleteModalComponent, {'class': 'modal-sm'});
        (<DeleteModalComponent>modal.content).showConfirmationModal(
            'Delete',
            'Are you sure you want to delete this apk information?'
        );

        (<DeleteModalComponent>modal.content).onClose.subscribe(result => {
            if (result) {
                this.apkService.fnDeleteAPK(id)
                    .then(() => {
                        _.remove(this.apkArr, function (apkObj) {
                            return apkObj._id === id;
                        });
                    });
            }
        });
    }
}
