import {Component, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {FeedbackService} from './feedback.service';
import {ExportService} from '../../common/export.service';

@Component({
    selector: 'pp-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

    public isDataLoaded;
    public isExporting: boolean;
    public filterObj: any;
    public feedbackArr: Array<any> = [];
    public totFeedbacks: number;

    constructor(private feedbackService: FeedbackService, private exportService: ExportService) {
        this.totFeedbacks = 0;
        this.filterObj = { sortBy: '', orderBy: '', page: 0, limit: 10 };
    }

    ngOnInit() {
        this.fnGetFeedbackList(this.filterObj);
    }

    /**
     * Get list of feedback
     * @param {object} params
     * */
    fnGetFeedbackList(params?: object) {
        this.isDataLoaded = false;
        this.isExporting = false;
        this.feedbackService.fnGetAllFeedback(params)
            .then((res: any) => {
                this.isDataLoaded = true;
                this.totFeedbacks = res.total;
                this.feedbackArr = res.data;
            });
    }

    fnPageChanged(event: any) {
        this.filterObj.page = event.page;
        this.fnGetFeedbackList(this.filterObj);
    }

    fnExportCSV() {
        this.isExporting = true;
        this.feedbackService.fnGetAllFeedback({ page: 1, limit: 10000 })
            .then((res: any) => {
                const feedbacks = res.data;
                const csvArr = [];
                _.forEach(feedbacks, element => {
                    if (element.userId && element.userId.firstName) {
                        if (element.userId.lastName) {
                            element.fullName = element.userId.firstName + ' ' + element.userId.lastName;
                        } else {
                            element.fullName = element.userId.firstName;
                        }
                    } else {
                        element.fullName = ' ';
                    }
                    if (element.preferredLockerId && element.preferredLockerId.name) {
                        element.lockerName = element.preferredLockerId.name;
                    } else {
                        element.lockerName = '';
                    }
                    if (element.companyId && element.companyId.name) {
                        element.companyName = element.companyId.name;
                    } else {
                        element.companyName = '';
                    }
                    csvArr.push(element);
                });
                const headers = ['companyName' , 'lockerName', 'fullName', 'feedback', 'createdAt'];
                this.exportService.fnDownloadCSV('Feedback', csvArr, headers);
                this.isExporting = false;
            });
    }

}
