import {Injectable} from '@angular/core';
import {CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

import {AuthService} from './auth.service';
import {Constant} from './constant';

@Injectable()
export class AuthenticateGuard implements CanActivate {

    constructor(private router: Router, private auth: AuthService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const url: string = state.url;
        return this.fnCheckAuthenticate(url);
    }

    fnCheckAuthenticate(url: string): boolean {
        const currentUser: any = this.auth.fnGetCurrentUser();
        if (this.auth.fnGetToken() && currentUser) {
            if (currentUser.role) {
                if (url.indexOf('/dashboard') > -1) {
                    if (currentUser.role === Constant.ROLE_ADMIN) {
                        return true;
                    } else if (currentUser.role === Constant.ROLE_COMPANY_ADMIN) {
                        this.router.navigate(['/company-lockers']);
                        return false;
                    } else if (currentUser.role === Constant.ROLE_USER) {
                        this.router.navigate(['/settings']);
                        return false;
                    }
                }
                if (url.indexOf('/companies') > -1) {
                    if (currentUser.role === Constant.ROLE_ADMIN) {
                        return true;
                    } else {
                        this.router.navigate(['/dashboard']);
                        return false;
                    }
                }
                if (url.indexOf('/lockers') > -1) {
                    if (currentUser.role === Constant.ROLE_ADMIN) {
                        return true;
                    } else {
                        this.router.navigate(['/dashboard']);
                        return false;
                    }
                }
                if (url.indexOf('/company-lockers') > -1) {
                    if (currentUser.role === Constant.ROLE_COMPANY_ADMIN) {
                        return true;
                    } else {
                        this.router.navigate(['/dashboard']);
                        return false;
                    }
                }
                if (url.indexOf('/cabinets/') > -1) {
                    if (currentUser.role.endsWith(Constant.ROLE_ADMIN)) {
                        return true;
                    } else {
                        this.router.navigate(['/dashboard']);
                        return false;
                    }
                }
                if (url.indexOf('/slots') > -1) {
                    if (currentUser.role.endsWith(Constant.ROLE_ADMIN)) {
                        return true;
                    } else {
                        this.router.navigate(['/dashboard']);
                        return false;
                    }
                }
                if (url.indexOf('/users') > -1) {
                    if (currentUser.role === Constant.ROLE_ADMIN) {
                        return true;
                    } else {
                        this.router.navigate(['/dashboard']);
                        return false;
                    }
                }
                if (url.indexOf('/locker-directory') > -1) {
                    if (currentUser.role.endsWith(Constant.ROLE_ADMIN) || currentUser.role.endsWith(Constant.ROLE_COMPANY_ADMIN)) {
                        return true;
                    } else {
                        this.router.navigate(['/dashboard']);
                        return false;
                    }
                }
                if (url.indexOf('/enrollment-user') > -1) {
                    if (currentUser.role.endsWith(Constant.ROLE_COMPANY_ADMIN)) {
                        return true;
                    } else {
                        this.router.navigate(['/dashboard']);
                        return false;
                    }
                }
                if (url.indexOf('/shipments') > -1) {
                    if (currentUser.role.endsWith(Constant.ROLE_ADMIN)) {
                        return true;
                    } else {
                        this.router.navigate(['/dashboard']);
                        return false;
                    }
                }
                if (url.indexOf('/verify-otp') > -1) {
                    if (currentUser.uuid && currentUser.role === Constant.ROLE_ADMIN) {
                        return true;
                    } else {
                        this.router.navigate(['/dashboard']);
                        return false;
                    }
                }
                if (url.indexOf('/settings') > -1) {
                    return true;
                }
                if (url.indexOf('/support') > -1) {
                    if (currentUser.role === Constant.ROLE_USER) {
                        return true;
                    } else {
                        this.router.navigate(['/dashboard']);
                        return false;
                    }
                }
                if (url.indexOf('/payment-plans') > -1) {
                    if (currentUser.role === Constant.ROLE_ADMIN) {
                        return true;
                    } else {
                        this.router.navigate(['/dashboard']);
                        return false;
                    }
                }
                if (url.indexOf('/payment-details') > -1) {
                    if (currentUser.role === Constant.ROLE_USER) {
                        return true;
                    } else {
                        this.router.navigate(['/dashboard']);
                        return false;
                    }
                }
                if (url.indexOf('/shipment-history') > -1) {
                    if (currentUser.role === Constant.ROLE_USER) {
                        return true;
                    } else {
                        this.router.navigate(['/dashboard']);
                   }
                }
                if (url.indexOf('/pending-shipments') > -1) {
                    if (currentUser.role.endsWith(Constant.ROLE_ADMIN)) {
                        return true;
                    } else {
                        this.router.navigate(['/settings']);
                        return false;
                    }
                }
                return true;
            }
            if (url.indexOf('/verify-otp') > -1 && currentUser.uuid) {
                return true;
            } else {
                this.router.navigate(['/verify-otp']);
                return false;
            }
        } else {
            this.auth.fnRemoveTokenCookie();
            this.router.navigate(['/sign-in']);
        }
        return false;
    }

}
