/** Status for User */
export enum USER_STATUS_ENUM {
    INACTIVE = 'INACTIVE',
    ACTIVE = 'ACTIVE',
    PENDING = 'PENDING',
    BLOCKED = 'BLOCKED',
    ARCHIVED = 'ARCHIVED'
}

export enum TIME_ZONES {
    PST = 'PST', // -8.00
    PDT = 'PDT', // -7.00
    MST = 'MST', // -7.00
    MDT = 'MDT', // -6.00
    CST = 'CST', // -6.00
    CDT = 'CDT', // -5.00
    EST = 'EST', // -5.00
    EDT = 'EDT', // -4.00
    AST = 'AST', // -4.00
    ADT = 'ADT', // -3.00
    NST = 'NST', // -3.30
    NDT = 'NDT', // -2.30
    UTC = 'UTC', //  0.00
}
