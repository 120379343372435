import {AfterViewInit, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import * as moment from 'moment';
import { DaterangePickerComponent } from 'ng2-daterangepicker';
import {DeleteModalComponent} from '../../delete-modal/delete-modal.component';
import {AuthService} from '../../common/auth.service';
import {ShipmentsService} from './shipments.service';
import {Shipment} from './shipment';
import {User} from '../users/user';
import {Constant} from '../../common/constant';
import { ExportService } from '../../common/export.service';
import {Observable, mergeMap} from 'rxjs';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead';
import {UsersService} from '../users/users.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Locker} from '../lockers/locker';
import {Company} from '../companies/company';
import {LockersService} from '../lockers/lockers.service';
import {CompaniesService} from '../companies/companies.service';
import {ToastrService} from '../../common/toastr.service';
import {USER_ROLE} from '../../../../server/model/user';
import {StorageService} from '../../common/storage.service';
import {SHIPMENT_STATUS} from '../../common/enums';
import {debounceTime, filter} from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
    selector: 'pp-shipments',
    templateUrl: './shipments.component.html',
    styleUrls: ['./shipments.component.css']
})

export class ShipmentsComponent implements OnInit, AfterViewInit {
    @ViewChild(DaterangePickerComponent, {static: false})
    private picker: DaterangePickerComponent;
    expireShipmentDateModalRef: BsModalRef;
    public todayDate: Date = new Date();
    public currentUser: User;
    public selectedUser: any;
    public selectedCreatedAt: any;
    public selectedExpireDate: any;
    public filterObj: any;
    public isDataLoaded;
    public isExporting: boolean;
    public invalidDateRange: boolean;
    public isDevURL: boolean;
    public isAllShipmentChecked = false;
    public lockersArr: Array<Locker> = [{_id: '', name: 'Select Locker'}];
    public companiesArr: Array<Company> = [{_id: '', name: 'Select Company'}];
    public shipmentArr: Array<Shipment> = [];
    readonly CONSTANT = Constant;
    public totShipments: number;
    dataSource: Observable<any>;
    defaultDateRangeDays: number;
    maxDateRangeDays: number;
    private SHIPMENTS_FILTERS = 'shipmentsFilter';
    public SHIPMENT_STATUS = SHIPMENT_STATUS;
    public shipmentStatusArr: Array<{key: string, value: string}> = [];
    public previousToken = '';
    public previousInputValue = '';
    searchLabelTxt = '';

    public shipmentInfo: { label: string, accessCode: string, isCopied: boolean };
    public pickupCodeModelRef: BsModalRef;

    constructor(private _bsModalService: BsModalService, private activatedRoute: ActivatedRoute,
                private shipmentService: ShipmentsService, private router: Router,
                private auth: AuthService, private exportService: ExportService, private usersService: UsersService,
                private lockersService: LockersService, private companiesService: CompaniesService,
                private toastr: ToastrService, private storage: StorageService) {

        this.currentUser = this.auth.fnGetCurrentUser();
        this.isDataLoaded = false;
        this.isExporting = false;
        this.invalidDateRange = false;
        this.isDevURL = false;
        this.totShipments = 0;
        if (this.currentUser.role === Constant.ROLE_ADMIN) {
            this.defaultDateRangeDays =  Constant.DEFAULT_DATE_RANGE_DAYS_ADMIN;
            this.maxDateRangeDays = Constant.MAX_DATE_RANGE_DAYS_ADMIN;
        } else {
            this.defaultDateRangeDays = Constant.DEFAULT_DATE_RANGE_DAYS;
            this.maxDateRangeDays = Constant.MAX_DATE_RANGE_DAYS;
        }
        this.getShipmentsFilters();
        this.selectedCreatedAt = this.filterObj.startDate && this.filterObj.endDate ?
            this.filterObj.startDate + ' - ' + this.filterObj.endDate : '';

        for (const key in SHIPMENT_STATUS) {
            if (SHIPMENT_STATUS.hasOwnProperty(key) && SHIPMENT_STATUS[key]) {
                this.shipmentStatusArr.push({
                    key: key,
                    value: SHIPMENT_STATUS[key]
                });
            }
        }
    }

    ngOnInit() {
        this.getInitialData();
    }

    ngAfterViewInit(): void {
        this.picker.datePicker.setStartDate(this.filterObj.startDate);
        this.picker.datePicker.setEndDate(this.filterObj.endDate);
    }

    async getShipmentsFilters() {
        let SHIPMENTS_FILTERS: any = this.storage.fnGetData(this.SHIPMENTS_FILTERS);
        const twoHoursDuration = 2 * 60 * 60 * 1000;
        if (SHIPMENTS_FILTERS && SHIPMENTS_FILTERS.lastUpdatedAt && (Date.now() - SHIPMENTS_FILTERS.lastUpdatedAt) >= twoHoursDuration) {
            SHIPMENTS_FILTERS = null;
        }
        this.filterObj = _.isEmpty(SHIPMENTS_FILTERS) ? {
            sortBy: 'createdAt',
            orderBy: 'desc',
            startDate: this.getDefaultDateRange().startDate,
            endDate: this.getDefaultDateRange().endDate,
            userId: '',
            companyId: '',
            lockerId: '',
            page: 1,
            limit: 10,
            selectedUser: '',
            label: '',
            status : SHIPMENT_STATUS.IN_TRANSIT,
            customerDropOff: '',
        } : SHIPMENTS_FILTERS;
        if (SHIPMENTS_FILTERS) {
            if (SHIPMENTS_FILTERS['invalidDateRange']) {
                this.filterObj.startDate = this.getDefaultDateRange().startDate;
                this.filterObj.endDate = this.getDefaultDateRange().endDate;
            }
        }

        this.selectedUser = (!_.isEmpty(SHIPMENTS_FILTERS) && SHIPMENTS_FILTERS.selectedUser) ? SHIPMENTS_FILTERS.selectedUser : '';
        this.searchLabelTxt = (!_.isEmpty(SHIPMENTS_FILTERS) && SHIPMENTS_FILTERS.label) ? SHIPMENTS_FILTERS.label : '';
        this.previousInputValue = this.selectedUser;
    }

    getDefaultDateRange() {
        const dateRange = {startDate: null, endDate: null};
        dateRange.startDate = moment().subtract(this.defaultDateRangeDays, 'd').format('MM/DD/YYYY');
        dateRange.endDate = moment().format('MM/DD/YYYY');
        return dateRange;
    }

    getInitialData() {
        this.fnCheckDevURL();
        this.fnGetCompanies();
        if (this.currentUser.role === Constant.ROLE_USER) {
            this.filterObj.userId = this.currentUser._id;
        }
        this.dataSource = new Observable((observer: any) => {
            observer.next(this.selectedUser);
        }).pipe(
            debounceTime(500),
            filter((data: string) => this.previousToken !== data.trim()),
            mergeMap((token: string) => {
                this.previousToken = token && token.trim();
                if (!this.filterObj.companyId) {
                    this.toastr.fnWarning('Please select company to search the users');
                    return of([]);
                }
                return this.previousToken ? this.filterResults(this.previousToken) : of([]);
            }));
    }

    filterResults(token: string) {
        const params:any = {companyId: this.filterObj.companyId, name: token};
        if(this.filterObj.lockerId) {
            params.preferredLockerId = this.filterObj.lockerId;
        }
        return this.usersService.fnGetUsers(params);
    }

    fnOnSelectUser(event: TypeaheadMatch): void {
        this.previousToken = '';
        this.filterObj.receiver = event.item._id;
        this.selectedUser = event.item.firstName + ' ' + event.item.lastName;
        this.filterObj.selectedUser = this.selectedUser;
        this.fnChangeShipments(this.filterObj);
    }

    changeDateRange() {
        this.invalidDateRange = false;
        if (!this.selectedCreatedAt) {
            this.toastr.fnError('Please select the date range to load shipment history');
        }
    }

    fnSelectedDate(value: any) {
        if (value) {
            this.filterObj.startDate = value.picker.startDate.format('MM/DD/YYYY');
            this.filterObj.endDate = value.picker.endDate.format('MM/DD/YYYY');
            this.filterObj.lastUpdatedAt = Date.now();
            const dayDiff = moment(this.filterObj.endDate).diff(moment(this.filterObj.startDate), 'days');
            this.invalidDateRange = dayDiff > this.maxDateRangeDays;
        }
        this.selectedCreatedAt = this.filterObj.startDate + ' - ' + this.filterObj.endDate;
        this.fnChangeShipments(this.filterObj);
    }

    fnClearDate() {
        this.selectedCreatedAt = this.filterObj.startDate + ' - ' + this.filterObj.endDate;
        this.invalidDateRange = false;
        this.picker.datePicker.setStartDate(this.filterObj.startDate);
        this.picker.datePicker.setEndDate(this.filterObj.endDate);
        this.fnChangeShipments(this.filterObj);
    }

    fnChangeShipments(filterObj: any) {
        this.filterObj.page = 1;
        this.filterObj.invalidDateRange = this.invalidDateRange;
        this.storage.fnStoreData(this.SHIPMENTS_FILTERS, filterObj);
        this.fnGetShipments(filterObj);
    }

    fnTypeAheadOnBlur(event) {
        this.previousToken = '';
        const selectedUser = this.selectedUser && this.selectedUser.trim();
        const inputValue = event.target.value && event.target.value.trim();
        if (!selectedUser && !inputValue && this.previousInputValue !== inputValue) {
            delete this.filterObj.receiver;
            this.filterObj.selectedUser = '';
            this.fnChangeShipments(this.filterObj);
        }
        this.previousInputValue = inputValue;
    }

    /**
     * Get companies
     * */
    async fnGetCompanies() {
        try {
            if (this.currentUser.role === Constant.ROLE_ADMIN) {
                this.companiesArr = [{_id: '', name: 'Select Company'}];
                this.companiesArr = <Array<Company>> await this.companiesService.fnGetCompanies({limitData: true});
                this.companiesArr.unshift({_id: '', name: 'Select Company'});
                if (this.filterObj.companyId) {
                    this.fnGetLockers({companyId: this.filterObj.companyId});
                } else {
                    this.fnGetShipments(this.filterObj);
                }
            } else {
                this.filterObj.companyId = this.currentUser.companyId;
                this.fnGetLockers({companyId: this.filterObj.companyId});
            }
        } catch (error) {
            this.toastr.fnError(error.message);
        }
    }

    /**
     * Get list of lockers
     * @param {object} params
     * */
    async fnGetLockers(params?: object) {
        this.lockersArr = [{_id: '', name: 'Select Locker'}];
        if (params['companyId']) {
            this.lockersArr = <Array<Locker>> await this.lockersService.getLockersNameAndNumber(params['companyId']);
            this.lockersArr.unshift({_id: '', name: 'Select Locker'});
            this.fnChangeShipments(this.filterObj);
        } else {
            this.fnChangeShipments(this.filterObj);
        }
    }

    fnClearFilters() {
        this.storage.fnRemove(this.SHIPMENTS_FILTERS);
        this.getShipmentsFilters();
        if (this.currentUser.role === Constant.ROLE_ADMIN) {
            this.lockersArr = [{_id: '', name: 'Select Locker'}];
        }
        this.fnClearDate();
        this.selectedUser = '';
        this.filterObj.customerDropOff = '';
    }

    /**
     * Set column sort icon
     * @param {string} sortBy
     * */
    setSortIcon(sortBy: string) {
        if (this.filterObj.sortBy === sortBy) {
            return this.filterObj.orderBy === 'desc' ? 'fa fa-sort-desc' : 'fa fa-sort-asc';
        }
        return 'fa fa-sort';
    }

    /**
     * Sort by column name
     * @param {string} sortBy
     * */
    sortBy(sortBy: string) {
        if (this.filterObj.sortBy === sortBy) {
            this.filterObj.sortBy = sortBy;
            if (this.filterObj.orderBy === 'asc') {
                this.filterObj.orderBy = 'desc';
            } else if (this.filterObj.orderBy === 'desc') {
                /*this.filterObj.sortBy = 'companyId.name';*/
                this.filterObj.orderBy = 'asc';
            } else {
                this.filterObj.orderBy = 'asc';
            }
        } else {
            this.filterObj.orderBy = 'asc';
            this.filterObj.sortBy = sortBy;
        }

        if (this.currentUser.role === Constant.ROLE_COMPANY_ADMIN) {
            const sortArr = this.filterObj.sortBy.split('.');
            if (sortArr.length === 2) {
                this.shipmentArr = _.orderBy(this.shipmentArr, (e) => e[sortArr[0]][sortArr[1]], [this.filterObj.orderBy]);
            } else if (sortArr.length === 1) {
                this.shipmentArr = _.orderBy(this.shipmentArr, (e) => e[sortArr[0]], [this.filterObj.orderBy]);
            }
        } else {
            this.fnGetShipments(this.filterObj);
        }
    }

    fnCheckDevURL() {
        if (window.location.host === Constant.DEV_API_URL) {
            this.isDevURL = true;
        } else {
            this.isDevURL = false;
        }
    }


    /**
     * Get Shipments
     * @param {object} params
     * */
    async fnGetShipments(params?: object) {
        if (this.invalidDateRange) {
           return;
        }
        this.isDataLoaded = false;
        let res;

        const requestParam: any = {...params};
        requestParam.isOutbound = 'false';
        delete requestParam.selectedUser;
        delete requestParam.lastUpdatedAt;
        try {
            if (this.currentUser.role === USER_ROLE.USER) {
                if (requestParam && requestParam.label) {
                    delete requestParam.label;
                }
                res = await this.shipmentService.getUserShipments(this.currentUser._id, requestParam);
            } else {
                res = await this.shipmentService.fnGetShipments(requestParam);
            }
            this.isDataLoaded = true;
            this.totShipments = res.total;
            this.shipmentArr = res.data.map((o) => {
                o.isChecked = this.isAllShipmentChecked;
                return o;
            });
        } catch (err) {
            this.isDataLoaded = true;
            this.toastr.fnError(err.message);
        }
    }

    /**
     * DELETE Shipments
     * @param {string} id
     * */
    fnDeleteShipment(id: string) {
        const modal = this._bsModalService.show(DeleteModalComponent, {'class': 'modal-sm'});
        (<DeleteModalComponent>modal.content).showConfirmationModal(
            'Delete',
            'Are you sure you want to delete this shipment?'
        );

        (<DeleteModalComponent>modal.content).onClose.subscribe(result => {
            if (result) {
                this.shipmentService.fnDeleteShipment(id, {userId: this.currentUser._id})
                    .then(() => {
                        this.filterObj.page = 1;
                        this.filterObj.limit = 10;
                        this.fnGetShipments(this.filterObj);
                        this.toastr.fnSuccess('Shipment deleted successfully.');
                    })
                    .catch((error) => {
                        this.toastr.fnError(error.message);
                    });
            }
        });
    }

    /**
     * Page changed event
     * @param {any} event
     * */
    fnPageChanged(event: any) {
        this.filterObj.page = event.page;
        this.fnGetShipments(this.filterObj);
    }

    /**
     * All shipment select event
     * @param {boolean} isAllShipmentChecked
     * */
    fnOnAllShipmentChanged(isAllShipmentChecked: boolean) {
        _.forEach(this.shipmentArr, (shipObj: any) => {
            shipObj.isChecked = isAllShipmentChecked;
        });
    }

    /**
     * Select single shipment change event.
     * */
    fnOnShipmentChanged() {
        // Check if all true
        this.isAllShipmentChecked = !!this.shipmentArr.map((obj: any) => obj.isChecked)
            .reduce((a: any, b: any) => {
                return (a === b) ? a : NaN;
            });
    }

    /**
     * Remove All shipment
     * @param {any} shipmentArr
     * */
    fnRemoveAllShipment(shipmentArr: any) {
        const ids = shipmentArr.filter((o) => {
            return o.isChecked;
        }).map((o) => {
            return o._id;
        });
        if (ids.length > 0) {
            const modal = this._bsModalService.show(DeleteModalComponent, {'class': 'modal-sm'});
            (<DeleteModalComponent>modal.content).showConfirmationModal(
                'Delete',
                'Are you sure you want to delete selected all shipments?'
            );

            (<DeleteModalComponent>modal.content).onClose.subscribe(result => {
                if (result) {
                    this.shipmentService.fnDeleteMultipleShipment({ids: ids, userId: this.currentUser._id})
                        .then(() => {
                            this.filterObj.page = 1;
                            this.filterObj.limit = 10;
                            this.fnGetShipments(this.filterObj);
                        });
                }
            });
        }
    }

    fnExportCSV() {
        this.isExporting = true;
        const filterObj: any = {...this.filterObj};
        filterObj.page = 1;
        filterObj.limit = 20000;
        filterObj.isOutbound = 'false';
        delete filterObj.lastUpdatedAt;
        delete filterObj.selectedUser;
        this.shipmentService.fnGetShipments(filterObj)
            .then((res: any) => {
                const shipments = res.data;
                const csvArr = [];
                _.forEach(shipments, element => {
                    if (element.receiver && element.receiver.firstName) {
                        element.receiver = element.receiver.lastName
                            ? element.receiver.firstName + ' ' + element.receiver.lastName : element.receiver.firstName;
                    } else {
                        element.recevier = '';
                    }

                    if (element.packages.length && element.packages[0].slotId && element.packages[0].slotId.cabinetId
                        && element.packages[0].slotId.slotNumber && element.packages[0].slotId.cabinetId.cabinetNumber) {
                        element.slot = element.packages[0].slotId.cabinetId.cabinetNumber + element.packages[0].slotId.slotNumber;
                    } else {
                        element.slot = '';
                    }

                    if (!element.userId) {
                        element.createdBy = element.lockerId ? element.lockerId.name : '';
                    } else {
                        element.createdBy = element.userId.firstName ?
                            element.userId.firstName + ' ' + element.userId.lastName : element.userId;
                    }
                    csvArr.push(element);
                });
                const headers = ['companyId.name', 'carrierName', 'receiver', 'status', 'slot', 'packages.label', 'packages.status',
                    'packages.size', 'packages.droppedAt', 'packages.pickedAt', 'createdBy',
                    'lockerId.name', 'updatedAt', 'createdAt'];
                this.exportService.fnDownloadCSV('Shipments', csvArr, headers);
                this.isExporting = false;
            });
    }

    /**
     * Check Permission and Shipment Status for Changing Shipment Expiration Date
     * @param {string} shipmentStatus
     * */
    checkShipmentStatusAndPermission(shipmentStatus: string) {
        if ((this.currentUser.role === Constant.ROLE_ADMIN || this.currentUser.role === Constant.ROLE_COMPANY_ADMIN) &&
            (shipmentStatus === Constant.SHIPMENT_STATUS_IN_TRANSIT || shipmentStatus === Constant.SHIPMENT_STATUS_EXPIRED)) {
            return true;
        }
        return false;
    }

    /**
     * Open Shipment Expiration Date change Model.
     * @param {object} template.
     * */
    openModal(template: TemplateRef<any>) {
        this.expireShipmentDateModalRef = this._bsModalService.show(template);
    }

    /**
     * Getting selected shipment Expiration Date.
     * @param {object} value.
     * */
    onSelectedExpireDate(value) {
        if (value) {
            this.selectedExpireDate = moment(value).format('MM-DD-YYYY');
        }
    }

    /**
     * Set shipment new expiration Date.
     * @param {string} shipmentId
     * */
    async onSubmitShipmentExpireDate(shipmentId: string) {
        try{
            const res: any = await this.shipmentService.setShipmentExpirationDate(shipmentId, moment(this.selectedExpireDate).format('MM-DD-YYYY'));
            if (res) {
                this.toastr.fnSuccess('Shipment Expiration Date set successfully');
                this.selectedExpireDate = null;
                this.expireShipmentDateModalRef.hide();
                this.getInitialData();
            }
        } catch (error) {
            this.toastr.fnError(error.message);
        }
    }

    closeExpirationDateModal() {
        this.selectedExpireDate = null;
        this.expireShipmentDateModalRef.hide();
    }

    public onLabelChange() {
        this.filterObj.label = this.searchLabelTxt;
        this.fnChangeShipments(this.filterObj);
    }

    public openPickupCodeModel(template: TemplateRef<any>, shipment: any) {
        this.shipmentInfo = {label: shipment.packages[0].label, accessCode: shipment.customerDropOff ? shipment.receiver.dropOffCode : shipment.receiver.accessCode, isCopied: false };
        this.pickupCodeModelRef = this._bsModalService.show(template, {'class': 'modal-sm'});
    }

    public closePickupCodeModel() {
        this.shipmentInfo = {label: '', accessCode: '', isCopied: false};
        this.pickupCodeModelRef.hide();
    }

    public copyPickupCode(inputElement: any) {
        const tempElem = document.createElement('input');
        this.shipmentInfo.isCopied = true;
        document.body.appendChild(tempElem);
        tempElem.value = inputElement.innerHTML.trim();
        tempElem.select();
        document.execCommand('copy', false);
        tempElem.remove();
        setTimeout(() => {
            this.shipmentInfo.isCopied = false;
        }, 1000);
    }

    getPackageStatus = (status) => {
        return status === Constant.PACKAGE_STATUS_EXCEPTION ? Constant.PACKAGE_STATUS_DOOR_JAMMED : status;
    }
}
