import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {AuthService} from '../common/auth.service';
import {Constant} from '../common/constant';
import {PermissionUtils, SCREEN} from '../utils/PermissionUtils';

@Component({
    selector: 'pp-authenticate',
    templateUrl: './authenticate.component.html',
    styleUrls: ['./authenticate.component.css']
})
export class AuthenticateComponent implements OnInit {

    public currentUser: any;
    public isToggleSideBar = false;
    readonly CONSTANT = Constant;
    readonly Permission = PermissionUtils;
    readonly SCREEN = SCREEN;

    constructor(private router: Router, private auth: AuthService) {
        this.currentUser = this.auth.fnGetCurrentUser();
        this.isToggleSideBar = false;
    }

    ngOnInit() {
    }

    /**
     * Sign out
     * */
    fnSignOut() {
        this.auth.fnSignOut()
            .then(() => {
                this.router.navigate(['/sign-in']);
            });
    }

    /**
     * Toggle sidebar using variable
     * */
    fnToggleSideBar() {
        this.isToggleSideBar = !this.isToggleSideBar;
    }

    /**
     * App component resize event
     * */
    onResize() {
        this.isToggleSideBar = false;
    }
}
