import {Pipe, PipeTransform} from '@angular/core';

/**
 * Remove underscore from string
 */
@Pipe({name: 'RemoveUnderscore'})
export class RemoveUnderscore implements PipeTransform {
    transform(value: string): string {
        return value && value.replace(/_/g, ' ');
    }
}
