import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {AuthService} from '../common/auth.service';
import {ToastrService} from '../common/toastr.service';
import {Constant} from '../common/constant';

interface SignIn {
    email: string;
    password: string;
}

@Component({
    selector: 'pp-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.css']
})

export class SignInComponent implements OnInit {

    signInObj: SignIn;
    public emailRegEx: any = Constant.EMAIL_REG_EX;
    public isLogin = true;
    public resendEmail: any;
    constructor(private router: Router, private auth: AuthService, private toastr: ToastrService) {
        this.signInObj = {
            email: '',
            password: ''
        };
    }

    ngOnInit() {
    }

    fnSignIn(signInObj: SignIn) {
        this.auth.fnSignIn(signInObj)
            .then(() => {
                this.toastr.fnSuccess('Welcome to Parcel Port!');
                if (this.auth.fnGetCurrentUser().role === 'ADMIN') {
                    this.router.navigate(['/verify-otp']);
                } else {
                    this.router.navigate(['/dashboard']);
                }
                /*if (response['user']['role'] === 'CARRIER_USER') {
                 this.router.navigate(['/purolator']);
                 } else {
                 this.router.navigate(['/shipments']);
                 }*/
            })
            .catch((error) => {
                this.toastr.fnError((error && error.message) || error);
            });
    }

    fnResendCode() {
        if (this.resendEmail) {
            this.auth.fnResendCode({email : this.resendEmail})
                .then((res: any) => {
                    if ((res.message === 'No such user found.') || (res.message === 'You are already an active user.')) {
                        this.toastr.fnError(res.message);
                    } else {
                        this.toastr.fnSuccess('We have resend your verification code to your email. Please confirm your Email address.');
                    }
                });
        } else {
            this.toastr.fnError('Please enter any email address.');
        }
    }

}
